import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../service/common.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { DashboardService } from '../dashboard/dashboard.service';
import { EnrollService } from './enroll-assosiate.service';
declare var $: any;
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { environment } from 'src/environments/environment';
import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Router } from '@angular/router';

@Component({
    selector: 'app-enroll-assosiate',
    templateUrl: './enroll-assosiate.component.html',
    styleUrls: ['./enroll-assosiate.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class EnrollAssosiateComponent implements OnInit {

    public user: any;
    public spinId = "";
    usersForm: FormGroup;
    usersForm1: FormGroup;
    spinIdClicked: Boolean = false;
    fileImage: any;
    filteredUsers: any[] = [];
    userList: any[];
    isLoading: Boolean = false;
    pageTitle = "START ASSOCIATE ENROLLMENT";
    enrollmentAccordianBoolean = true;
    status: any;
    progressValue = 0;
    uploadAccordian = true;
    enrollmentId = '';
    twoTimesEnrolled = false;
    showFormImage = true;
    emailButton = 'E-SIGN';
    printButton = 'PRINT';
    manualButton = 'MANUAL SIGN';
    emailMessage = 'Enrollment form will be emailed for signature to : '
    pdfLoading = true;
    loading: boolean = true;
    countValue = 0;
    enrolformNotFound: boolean = false;
    expandFormNotFound: boolean = false;

    // VENKATESH DECLARATION FOR ENROLLMENT
    browseDisable: boolean = true;
    principalCheckbox: boolean = false;
    assocaiteCheckbox: boolean = false;
    uploadFormButton: boolean = false;
    checkbox: boolean = false;
    fileToUpload: File = null;
    fileName: string;
    successMessage: boolean = false;
    createEnrollmentClick = false;
    sentToAssociate = false;
    message = "";
    onlyViewForm = false;
    invalidUserEmail = [];
    deleteCount: any;
    dataContent: any;
    pdfUrl = '';
    envelopeId = '';
    // VENKATESH DECLARATION FOR ENROLLMENT
    pdfError: boolean = false;
    errorMsg: string = '';
    pdfHref: string = '';
    checkChrome: boolean;
    config: any;
    fileUrl: any;
    fileBlob: any;
    showSpinner: Boolean;
    disableSendEmail: Boolean;
    disableCancelEmail: Boolean;
    dasboardTab: boolean = false;
    selectPrincipal: boolean = true;
    selectGM: boolean = false;
    selectedOfficialEmail: any = '';
    hidePrincipal: boolean = true;
    hideGm: boolean = true;
    hideThumbnail: boolean = false;
    certificationResponse: any = '';
    principalDetailResponse: any = '';
    GMDetailResponse: any = '';
    disablePrint: boolean = true;
    sasTokenResponse: any = '';
    expandFormReady: Boolean = false;
    errorUploadForm: boolean = false;
    enrollmentform: string = '';
    draftData: any = '';
    expandButtonName: string = 'EXPAND';
    enrolError: boolean = false;

    constructor(private blob: BlobService, private dashboardService: DashboardService, private router: Router,
        private enrollService: EnrollService, private fb: FormBuilder, private appService: CommonService
    ) { }

    async ngAfterViewInit() {
        this.expandFormReady = false;
        this.pdfLoading = true;
        this.appService.enrollmentCurrentStatus = this.status;
        let containerurl = environment.azureStorage.split('/');
        let container = containerurl[containerurl.length - 2];
        await this.SasToken(container);
        if (!this.checkChrome) { // FOR IE
            console.log(' IE -> '+this.status);
            if (this.status == 'ENROLLED') {
                if (this.enrollmentId.startsWith('ME-')) {
                    this.hideThumbnail = true;
                } else {
                    console.log(' IE -> ENROLLED -> not ME');
                    this.expandButtonName = 'EXPAND';
                    this.hideThumbnail = false;
                    (<HTMLIFrameElement>document.getElementById('expandImage')).src = environment.azureStorage + this.enrollmentform + '?' + this.sasTokenResponse;
                    this.expandFormReady = true;
                    this.pdfLoading = false;
                    // this.cdref.detectChanges();
                }
            } else if (this.status == 'DRAFT' || this.status == 'PENDING UPLOAD') {
                console.log(' IE -> DRAFT ');
                await this.makePdf(this.user.firstName + " " + this.user.lastName + " ");
                this.expandButtonName = 'DOWNLOAD';
            }
            else {
                if (this.enrollmentId != "") {
                    console.log('IE -> ENROLL ID NOT EMPTY ');
                    this.expandButtonName = 'EXPAND';
                    (<HTMLIFrameElement>document.getElementById('expandImage')).src = environment.azureStorage + this.enrollmentform + '?' + this.sasTokenResponse;
                    this.pdfLoading = false;
                    this.expandFormReady = true;
                    // this.cdref.detectChanges();
                }
            }
        }
    }

    async ngOnInit() {
        console.log('pc enroll',this.appService.role);
        if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || 
        this.appService.role == 'Area Admin' || this.appService.role == 'Billing' || this.appService.role == 'Admin') {
            this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
            this.router.navigate(['unauthorized']);
        }
        this.enrolError = false;

        this.expandFormReady = false;
        var ua = window.navigator.userAgent;
        var isIE = /MSIE|Trident/.test(ua);

        if (isIE) { // IE Browser
            this.checkChrome = false;
            this.printButton = 'DOWNLOAD';
            this.manualButton = 'MANUAL SIGN';
            this.expandButtonName = 'DOWNLOAD';
        }
        else {
            this.checkChrome = true;
            this.expandButtonName = 'EXPAND';
        }

        this.usersForm = this.fb.group({
            userInput: null
        });

        this.appService.getEnrollUserData() != undefined ? this.user = this.appService.getEnrollUserData() : this.showSpinner = false;

        console.log('in enroll page', this.user);
        if (this.user != undefined) {
            this.showSpinner = true;
            if (environment.pcMailId) {
                this.user.pcEmail = this.appService.userEmail
            }
            else {
                this.user.pcEmail = "LexusMasterLease@lexus.com";
            }
            console.log('pc email', this.user.pcEmail);

            await this.enrollService.getGMDetails(this.appService.dc).toPromise().then(async response => {
                // this.showSpinner = true;
                console.log('GM : ', response);
                let gmList = [];
                this.GMDetailResponse = response.GeneralManagerDetails.Result;
                if (response.GeneralManagerDetails.Result.length != 0) {
                    for (let i = 0; i < this.GMDetailResponse.length; i++) {
                        let name = '';
                        if (this.GMDetailResponse[i].FULL_NAME != '' || this.GMDetailResponse[i].FULL_NAME != null) {
                            name = this.GMDetailResponse[i].FULL_NAME.toUpperCase();
                        }
                        let Obj = {
                            'name': name,
                            'spinId': this.GMDetailResponse[i].SPIN_ID,
                            'email': this.GMDetailResponse[i].USER_EMAIL
                        }
                        gmList.push(Obj);
                    }
                    this.user.GmList = gmList;
                    console.log('GM List :: ', this.user.GmList);

                    console.log('GM EMAIL:: ', this.user.GMEmail);
                    if (this.user.GMEmail != 'null' && this.user.GMEmail != '' && this.user.GMEmail != undefined && this.user.GMEmail != "undefined" && this.user.GMEmail != null) {
                        this.user.GmList.forEach(elt => {
                            if (elt.name == this.user.GMFullName) {
                                this.user.GMFullName = elt.name;
                                this.user.GMEmail = elt.email;
                                this.user.GMSpinId = elt.spinId;
                            }
                        });
                    } else {
                        this.user.GMFullName = '';
                        this.user.GMSpinId = '';
                        this.user.GMEmail = '';
                    }

                } else {
                    if (this.user.GMEmail == '' || this.user.GMEmail == null) {
                        this.user.GMEmail = '';
                    }
                    this.user.GMSpinId = '';
                    this.user.GMFullName = '';
                    this.user.GmList = [];
                }
                // this.showSpinner = false;
            }, err => {
                // this.showSpinner = false;
                console.log('Error while trying to get GM details');
            });

            await this.enrollService.getPrincipalDetails(this.appService.dc).toPromise().then(response => {
                // this.showSpinner = true;
                console.log('PL : ', response);
                let pdList = [];
                this.principalDetailResponse = response.PrincipalDetails.Result;
                if (this.principalDetailResponse.length != 0) {
                    for (let i = 0; i < this.principalDetailResponse.length; i++) {
                        let name = '';
                        if (this.principalDetailResponse[i].FULL_NAME != '' || this.principalDetailResponse[i].FULL_NAME != null) {
                            name = this.principalDetailResponse[i].FULL_NAME.toUpperCase();
                        }
                        let Obj = {
                            'name': name,
                            'spinId': this.principalDetailResponse[i].SPIN_ID,
                            'email': this.principalDetailResponse[i].USER_EMAIL
                        }
                        pdList.push(Obj);
                    }

                    this.user.PdList = pdList;
                    console.log('PD List :: ', this.user.PdList);

                    console.log('PD EMAIL:: ', this.user.principalEmail);
                    if (this.user.principalEmail != 'null' && this.user.principalEmail != '' && this.user.principalEmail != undefined && this.user.principalEmail != 'undefined' && this.user.principalEmail != null) {
                        this.user.PdList.forEach(elt => {
                            if (elt.name == this.user.principalFullName) {
                                this.user.principalFullName = elt.name;
                                this.user.principalEmail = elt.email;
                                this.user.principalSpinId = elt.spinId;
                            }
                        });
                    } else {
                        this.user.principalFullName = '';
                        this.user.principalEmail = '';
                        this.user.principalSpinId = '';
                    }

                    if (this.status === 'PENDING WITH PRINCIPAL') {

                        this.emailButton = 'E-SIGN (RESEND)';
                        this.emailMessage = 'Enrollment form will be resent for signature to : ';
                        if (this.user.sentTo == 'Principal') {
                            this.message = 'Enrollment form is currently with ' + this.user.principalFullName + ' (Principal) for signature. '
                        }
                        else if (this.user.sentTo == 'GM') {
                            this.message = 'Enrollment form is currently with ' + this.user.GMFullName + ' (GM) for signature. '
                        }

                        this.enrollmentId = this.user.enrollmentNumber;
                        this.enrollmentform = this.user.enrollmentForm;
                        console.log('Pending with principal', this.user);
                        this.envelopeId = this.user.envelopeId;
                    }
                } else {
                    if (this.user.principalEmail == '' || this.user.principalEmail == null) {
                        this.user.principalEmail = '';
                    }
                    this.user.principalSpinId = '';
                    this.user.principalFullName = '';
                }
                // this.showSpinner = false;
            }, err => {
                // this.showSpinner = false;
                console.log('Error while trying to get Principal details');
            });

        } else {
            this.showSpinner = false;
        }

        setTimeout(function () {
            if (this.status === 'PENDING WITH PRINCIPAL') {
                this.emailButton = 'E-SIGN (RESEND)';
                this.emailMessage = 'Enrollment form will be resent for signature to : ';
                if (this.user.sentTo == 'Principal') {
                    this.message = 'Enrollment form is currently with ' + this.user.principalFullName + ' (Principal) for signature. '
                }
                else if (this.user.sentTo == 'GM') {
                    this.message = 'Enrollment form is currently with ' + this.user.GMFullName + ' (GM) for signature. '
                }
                this.enrollmentId = this.user.enrollmentNumber;
                this.enrollmentform = this.user.enrollmentForm;
                console.log(this.user);
                this.envelopeId = this.user.envelopeId;
                this.appService.navigationFromDashboard = false;
                this.showSpinner = this.appService.navigationFromDashboard;
            }
        }.bind(this), 1500);

        if (this.user != undefined && this.user.length != 0) {
            this.spinId = this.user.firstName + " " + this.user.lastName;
            this.pageTitle = "ASSOCIATE CURRENT INFORMATION";
            this.status = "REVIEW INFO";
            this.user.fullName = this.user.firstName + " " + this.user.lastName;
            this.user.enrollmentForm = this.appService.dashtoenrollEnrollmentForm.enrollmentForm;
            this.user.email = this.user.user_email;
            if (this.user.enrollmentForm != null) {
                this.disablePrint = false;
                this.expandFormNotFound = false;
            } else {
                this.disablePrint = true;
                this.expandFormNotFound = true;
            }

            if (this.user.viewEnrollment != undefined) {
                this.onlyViewForm = this.user.viewEnrollment;
            }
            if (this.onlyViewForm) {
                console.log(this.user);
                this.enrollmentId = this.user.enrollment_No;
                this.enrollmentform = this.user.enrollmentForm;
                this.status = this.user.enrollmentStatus;
                this.createEnrollmentClick = true;
                if (this.status === 'DRAFT') {
                    this.message = "Draft enrollment has been created for " + this.user.firstName + " " + this.user.lastName + " ";
                }
                else if (this.status === 'PENDING') {
                    this.message = "Draft enrollment has been created for " + this.user.firstName + " " + this.user.lastName + " ";
                    this.sentToAssociate = true;
                    this.enrollmentAccordianBoolean = false;
                    this.status = "PENDING SIGNATURE";
                }

                else if (this.status === 'PENDING WITH ASSOCIATE') {

                    this.emailButton = 'E-SIGN (RESEND)';
                    this.emailMessage = 'Enrollment form will be resent for signatures to : ';
                    this.message = 'Enrollment form is currently with ' + this.user.first_name + ' ' + this.user.lastName + ' (Associate) for signature '
                }

                else if (this.status === 'PENDING WITH PRINCIPAL') {

                    this.emailButton = 'E-SIGN (RESEND)';
                    this.emailMessage = 'Enrollment form will be resent for signature to : ';
                }

                else if (this.status === 'PENDING UPLOAD') {
                    this.message = 'Enrollment form is currently Pending Upload from ' + this.user.first_name + ' ' + this.user.lastName
                    this.sentToAssociate = true;
                    this.enrollmentAccordianBoolean = false;
                }

                else if (this.status === 'ENROLLED') {
                    this.status = "ENROLLED";
                    this.successMessage = true;
                    this.message = "Congratulations! " + this.user.firstName + " " + this.user.lastName + " has been successfully enrolled";
                    this.enrollmentAccordianBoolean = true;
                    this.uploadAccordian = false;
                    this.sentToAssociate = false;
                }

                else if (this.status === 'DELETED') {
                    this.showFormImage = false;
                    this.message = 'Enrollment for ' + this.user.first_name + ' ' + this.user.Last_Name + ' is successfully deleted.';
                    this.status = 'DELETED';
                    this.enrollmentId = '';
                }

                else {
                    this.createEnrollmentClick = false;
                }

                if (this.status != 'PENDING WITH PRINCIPAL') {
                    this.appService.navigationFromDashboard = false;
                    // this.showSpinner = this.appService.navigationFromDashboard;
                }
                this.appService.enrollmentCurrentStatus = this.status;
                this.enrollmentId = this.user.enrollmentNumber;
                this.enrollmentform = this.user.enrollmentForm;
                this.envelopeId = this.user.envelopeId;
                console.log('ENVELOPE ID', this.envelopeId);
                let containerurl = environment.azureStorage.split('/');
                let container = containerurl[containerurl.length - 2];
                await this.SasToken(container);
                if (this.checkChrome) {
                    console.log('NOT IE ');
                    this.expandButtonName = 'EXPAND';
                    if (this.status == 'ENROLLED') {
                        console.log('NOT IE -> ENROLLED ');
                        if (this.enrollmentId.startsWith('ME-')) {
                            this.hideThumbnail = true;
                            this.showSpinner = false;
                        } else {
                            this.hideThumbnail = false;
                            await this.enrollService.azureFile(this.enrollmentform + '?' + this.sasTokenResponse).toPromise().then(async (response: any) => {
                                var file = new Blob([response.content._body], { type: 'application/pdf' });
                                var fileURL = URL.createObjectURL(file);
                                this.pdfLoading = false;
                                this.showSpinner = false;
                                this.fileUrl = fileURL;
                                this.fileBlob = file;
                                (<HTMLIFrameElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                                this.expandFormReady = true;
                                // this.cdref.detectChanges();
                            }, err => {
                                this.pdfLoading = false;
                                this.showSpinner = false;
                                this.expandFormNotFound = true;
                                this.expandFormReady = true;
                                // this.cdref.detectChanges();
                            });
                        }

                    }
                    else if (this.status == 'DRAFT' || this.status == 'PENDING UPLOAD') {
                        console.log('NOT IE -> DRAFT ');
                        await this.makePdf(this.user.firstName + " " + this.user.lastName + " ");
                    }
                    else {
                        console.log('NOT IE -> PENDING ');
                        this.pdfLoading = true;
                        await this.enrollService.azureFile(this.enrollmentform + '?' + this.sasTokenResponse).toPromise().then(async (response: any) => {
                            var file = new Blob([response.content._body], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            this.pdfLoading = false;
                            this.showSpinner = false;
                            this.fileUrl = fileURL;
                            this.fileBlob = file;
                            (<HTMLIFrameElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                            this.expandFormReady = true;
                            // this.cdref.detectChanges();
                        }, err => {
                            this.pdfLoading = false;
                            this.showSpinner = false;
                            this.expandFormNotFound = true;
                            this.expandFormReady = true;
                            // this.cdref.detectChanges();
                        });
                    }
                } else {
                    if (this.status == 'ENROLLED') {
                        if (this.enrollmentId.startsWith('ME-')) {
                            this.hideThumbnail = true;
                            this.showSpinner = false;
                        } else {
                            console.log(' IE -> ENROLLED -> not ME');
                            this.expandButtonName = 'EXPAND';
                            this.hideThumbnail = false;
                            (<HTMLIFrameElement>document.getElementById('expandImage')).src = environment.azureStorage + this.enrollmentform + '?' + this.sasTokenResponse;
                            this.expandFormReady = true;
                            this.pdfLoading = false;
                            this.showSpinner = false;
                            // this.cdref.detectChanges();
                        }
                        console.log('this.expandFormReady', this.expandFormReady)
                    } else if (this.status == 'DRAFT' || this.status == 'PENDING UPLOAD') {
                        console.log(' IE -> DRAFT ');
                        await this.makePdf(this.user.firstName + " " + this.user.lastName + " ");
                        this.expandButtonName = 'DOWNLOAD';
                    }
                    else {
                        if (this.enrollmentId != "") {
                            console.log('IE -> ENROLE ID NOT EMPTY ');
                            this.expandButtonName = 'EXPAND';
                            (<HTMLIFrameElement>document.getElementById('expandImage')).src = environment.azureStorage + this.enrollmentform + '?' + this.sasTokenResponse;
                            this.pdfLoading = false;
                            this.showSpinner = false;
                            this.expandFormReady = true;
                            // this.cdref.detectChanges();
                        }
                    }
                }
            } else {
                this.appService.navigationFromDashboard = false;
                this.showSpinner = this.appService.navigationFromDashboard;
            }
        } else {
            this.showSpinner = false;
        }
    }

    changePDDetails(pd) {
        this.invalidUserEmail = [];
        if (pd == '') {
            this.user.principalEmail = '';
            this.user.principalSpinId = '';
            this.user.principalFullName = '';
        } else {
            this.user.PdList.forEach(elt => {
                if (elt.name == pd) {
                    this.user.principalEmail = elt.email;
                    this.user.principalSpinId = elt.spinId;
                    this.user.principalFullName = pd;
                }
            });
        }
        console.log('PD Email :: ', this.user.principalEmail);
        console.log('PD List :: ', this.user.PdList);
        console.log('User Principal change :: ', this.user);
    }

    changeGMDetails(gm) {
        this.invalidUserEmail = [];
        if (gm == '') {
            this.user.GMEmail = '';
            this.user.GMSpinId = '';
            this.user.GMFullName = '';
        } else {
            this.user.GmList.forEach(elt => {
                if (elt.name == gm) {
                    this.user.GMEmail = elt.email;
                    this.user.GMSpinId = elt.spinId;
                    this.user.GMFullName = gm;
                }
            });
        }
        console.log('GM Email :: ', this.user.GMEmail);
        console.log('GM LIST :: ', this.user.GmList);
        console.log('User GM Change :: ', this.user);
    }

    // function to search for associates
    filterId() {
        this.spinId = this.spinId.replace(/[^a-zA-Z0-9 ]/g, "");
        if (this.spinId.length >= 2) {
            this.filteredUsers = [];
            this.isLoading = true;
            this.dashboardService.autoSearch(this.appService.dc, this.spinId).subscribe((result) => {
                this.filteredUsers = result.EnrolmentMaster.EnrolmentDtl;
                for (let i = 0; i < this.filteredUsers.length; i++) {
                    this.filteredUsers[i].firstName = this.filteredUsers[i].FIRST_NAME;
                    this.filteredUsers[i].lastName = this.filteredUsers[i].LAST_NAME;
                    this.filteredUsers[i].spinId = this.filteredUsers[i].SPIN_ID;
                    this.filteredUsers[i].fullName = this.filteredUsers[i].firstName.toUpperCase() + ' ' + this.filteredUsers[i].lastName.toUpperCase();
                }
                this.isLoading = false;

            });
        }
    }

    // function to get the certification, martiz and lfs details for the selected user from auto-complete
    selectUser(user: any) {
        this.showSpinner = true; this.enrolError = false;
        this.dashboardService.getStaffDetails(user.spinId, user.DEALER_CODE).subscribe((result: any) => {
            var dc = user.DEALER_CODE;
            user = result.EnrollmentDetail.EnrollDetail;
            var eligibleUser = false;
            loop1:
            for (let i = 0; i < user.length; i++) {
                loop2:
                for (let j = 0; j < this.appService.jobCodeList.length; j++) {
                    if (Number(user[i].JOB_CODE) == Number(this.appService.jobCodeList[j])) {
                        eligibleUser = true;
                        console.log('staff master error');
                        user = user[i];
                        break loop1;
                    }
                }
            }

            user.eligibleUser = eligibleUser;
            if (!eligibleUser) {
                user = result.EnrollmentDetail.EnrollDetail[0];
                user.employmentStatus = 'Ineligible Job Code';
            }
            if (eligibleUser) {
                user.employmentStatus = user.employee_status.toLowerCase();
            }
            if (user.firstName == null || user.firstName == undefined || user.firstName.toLowerCase() == 'null') {
                user.firstName = '';
            }
            user.firstName = user.first_name.toUpperCase();

            if (user.middleName == null || user.middleName == undefined || user.middleName.toLowerCase() == 'null') {
                user.middleName = '';
            }
            user.middleName = user.Middle_Name.toUpperCase();

            if (user.lastName == null || user.lastName == undefined || user.lastName.toLowerCase() == 'null') {
                user.lastName = '';
            }
            user.lastName = user.Last_Name.toUpperCase();

            if (user.spinId == null || user.spinId == undefined || user.spinId.toLowerCase() == 'null') {
                user.spinId = '';
            }
            user.spinId = user.spin_id;

            if (user.employmentStatus == null || user.employmentStatus == undefined || user.employmentStatus.toLowerCase() == 'null') {
                user.employmentStatus = '';
            }

            if (user.contact == null || user.contact == undefined || user.contact.toLowerCase() == 'null') {
                user.contact = '';
            }
            user.contact = user.contact_phone_no;

            if (user.user_email == null || user.user_email == undefined || user.user_email.toLowerCase() == 'null') {
                user.email = '';
            }
            user.email = user.user_email.toLowerCase();

            if (user.jobTitle == null || user.jobTitle == undefined || user.jobTitle.toLowerCase() == 'null') {
                user.jobTitle = '';
            }
            user.jobTitle = user.job_description.toLowerCase();
            user.tenure = user.tenure + ' Months';
            if (user.tenure == null || user.tenure == undefined || user.tenure.toLowerCase() == 'null') {
                user.tenure = '';
            }
            // debugger;
            this.user = user;
            console.log('User', this.user);
            this.pageTitle = "ASSOCIATE CURRENT INFORMATION";
            this.status = "REVIEW INFO";
            this.onlyViewForm = false;
            this.spinId = user.firstName + ' ' + user.lastName;
            this.dashboardService.getCertificationDetails(user.spinId, dc).subscribe(async (result1: any) => {
                if (result1.EnrollmentMaritzDetail.MaritzDetail.length != 0) {
                    this.certificationResponse = result1.EnrollmentMaritzDetail.MaritzDetail[result1.EnrollmentMaritzDetail.MaritzDetail.length - 1];
                } else {
                    this.certificationResponse = undefined;
                }
                if (this.certificationResponse != undefined && result1.EnrollmentMaritzDetail.MaritzDetail.length != 0) {
                    // if (this.certificationResponse.cert_level == 'C') {
                    //     user.certificationLevel = 'Certified';
                    // }
                    // else if (this.certificationResponse.cert_level == 'S') {
                        // new check here!!
                        // this.enrollService.seniorEligibleCheck(user.spinId).subscribe((seniorEligibleResponse: any) => {
                        //     console.log('Senior Eligible', seniorEligibleResponse);
                        //     this.user.certificationLevel = this.appService.getSeniorEligibileCheck(seniorEligibleResponse.SeniorEligibleCheck.Result)
                        // }, err => {
                        //     console.log('Error in senior Eligible Check');
                        // });
                    //     this.user.certificationLevel = await this.seniorEligibleCheck(user.spinId);
                    // }
                    // else if (this.certificationResponse.cert_level == 'M') {
                    //     user.certificationLevel = 'Master';
                    // }
                    // else if (this.certificationResponse.cert_level == 'N') {
                    //     user.certificationLevel = 'NA';
                    // } else {
                        // user.certificationLevel = 'NA';
                        this.user.certificationLevel = this.certificationResponse.cert_level;
                        this.user.certEligibility = this.certificationResponse.eligibility;
                        this.user.totalCreds = this.certificationResponse.total_credits;
                        this.user.marCert = this.certificationResponse.mar_master;
                        this.user.tenureMonths = this.certificationResponse.tenure_months;
                        this.user.lexusEssentials = this.certificationResponse.lexus_essentials;
                        console.log('certs man: ', this.user.certificationLevel, this.user.certEligibility,'cred', this.user.totalCreds,', c level',this.user.marCert)
                    // }
                    user.result = this.certificationResponse.result;
                    if (user.result != 'none') {
                        this.twoTimesEnrolled = true;
                    }
                    if (user.result == null || user.result == undefined || user.result.toLowerCase() == 'null') {
                        user.result = '';
                    }
                    // user.certification_months = this.certificationResponse.certification_months;
                    // if (user.certification_months == null || user.certification_months == undefined || String(user.certification_months).toLowerCase() == 'null') {
                    //     user.certification_months = '';
                    // }
                    user.totalCreds = this.certificationResponse.total_credits;
                    if (user.totalCreds == null || user.totalCreds == undefined || String(user.totalCreds).toLowerCase() == 'null') {
                        user.totalCreds = '';
                    }
                    user.certifiedYear = this.certificationResponse.certified_year;
                    if (user.certifiedYear == null || user.certifiedYear == undefined || String(user.certifiedYear).toLowerCase() == 'null') {
                        user.certifiedYear = '';
                    }
                } else {
                    user.certificationLevel = 'NA';
                }
                this.dashboardService.getLFSDetails(user.spinId.substring(3)).subscribe((result2: any) => {
                    // this.showSpinner = false;
                    if (result2.EnrollmentLFSDetail.LFSDetail[0] != undefined && result2.EnrollmentLFSDetail.LFSDetail[0].length != 0) {
                        let dateFrom = result2.EnrollmentLFSDetail.LFSDetail[0].veh_acquisition_date.substring(0, 10);
                        let dateTo = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_maturity_date.substring(0, 10);
                        let today: any = new Date();
                        let dd: any = today.getDate();
                        let mm: any = today.getMonth() + 1;
                        let yyyy: any = today.getFullYear();
                        if (dd < 10) {
                            dd = '0' + dd
                        }

                        if (mm < 10) {
                            mm = '0' + mm
                        }

                        today = yyyy + '-' + mm + '-' + dd;
                        console.log(today);
                        console.log(dateFrom);
                        console.log(dateTo);
                        let d1 = dateFrom.split("-");
                        let d2 = dateTo.split("-");
                        let c = today.split("-");

                        let from = new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
                        let to = new Date(d2[0], parseInt(d2[1]) - 1, d2[2]);
                        let check = new Date(c[0], parseInt(c[1]) - 1, c[2]);

                        if (check > from && check < to) {
                            user.leasedVehicle = 'Yes'
                        }
                        else {
                            user.leasedVehicle = 'No';
                        }
                        user.dealerCode = result2.EnrollmentLFSDetail.LFSDetail[0].dlr_no
                        if (user.dealerCode == null || user.dealerCode == undefined || user.dealerCode.toLowerCase() == 'null') {
                            user.dealerCode = '';
                        }

                        user.dealerName = result2.EnrollmentLFSDetail.LFSDetail[0].dlr_name;
                        if (user.dealerName == null || user.dealerName == undefined || user.dealerName.toLowerCase() == 'null') {
                            user.dealerName = '';
                        } else {
                            user.dealerName = result2.EnrollmentLFSDetail.LFSDetail[0].dlr_name.toLowerCase();
                        }

                        user.lfsNo = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_no;
                        if (user.lfsNo == null || user.lfsNo == undefined || user.lfsNo.toLowerCase() == 'null') {
                            user.lfsNo = '';
                        }

                        user.lfsActivationDate = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_activation_date.substring(0, 10);
                        if (user.lfsActivationDate == null || user.lfsActivationDate == undefined || user.lfsActivationDate.toLowerCase() == 'null') {
                            user.lfsActivationDate = '';
                        }

                        user.lfs_maturity_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_maturity_date.substring(0, 10);
                        if (user.lfs_maturity_date == null || user.lfs_maturity_date == undefined || user.lfs_maturity_date.toLowerCase() == 'null') {
                            user.lfs_maturity_date = '';
                        }

                        user.lfs_termination_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_termination_date.substring(0, 10);
                        if (user.lfs_termination_date == null || user.lfs_termination_date == undefined || user.lfs_termination_date.toLowerCase() == 'null') {
                            user.lfs_termination_date = '';
                        }

                        user.lfs_extension_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_extension_date.substring(0, 10);
                        if (user.lfs_extension_date == null || user.lfs_extension_date == undefined || user.lfs_extension_date.toLowerCase() == 'null') {
                            user.lfs_extension_date = '';
                        }

                        let lfsDate = user.lfsActivationDate.split("-");
                        user.lfsActivationDate = lfsDate[1] + "/" + lfsDate[2] + "/" + lfsDate[0];
                        let maturityDate = user.lfs_maturity_date.split("-");
                        user.lfs_maturity_date = maturityDate[1] + "/" + maturityDate[2] + "/" + maturityDate[0];
                        let terminationDate = user.lfs_termination_date.split("-");
                        user.lfs_termination_date = terminationDate[1] + "/" + terminationDate[2] + "/" + terminationDate[0];
                        let extensionDate = user.lfs_extension_date.split("-");
                        user.lfs_extension_date = extensionDate[1] + "/" + extensionDate[2] + "/" + extensionDate[0];
                        if(user.lfs_extension_date == "01/01/1990"){user.lfs_extension_date="NA"}
                        user.lfs_extension_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_extension_date.substring(0, 10);

                        if(user.lfs_termination_date == "01/01/2100"){user.lfs_termination_date="NA"}
                        user.lfs_termination_date = result2.EnrollmentLFSDetail.LFSDetail[0].lfs_termination_date.substring(0, 10);

                        if (user.veh_acquisition_date == null || user.veh_acquisition_date == undefined || user.veh_acquisition_date.toLowerCase() == 'null') {
                            user.veh_acquisition_date = '';
                        }
                        user.veh_acquisition_date =  result2.EnrollmentLFSDetail.LFSDetail[0].veh_acquisition_date.substring(0, 10);

                        let acqDate = user.veh_acquisition_date.split("-");
                        user.veh_acquisition_date = acqDate[1] + "/" + acqDate[2] + "/" + acqDate[0];

                        user.vehicleBrand = result2.EnrollmentLFSDetail.LFSDetail[0].Vehicle_Brand;
                        if (user.vehicleBrand == null || user.vehicleBrand == undefined || user.vehicleBrand.toLowerCase() == 'null') {
                            user.vehicleBrand = '';
                        } else {
                            user.vehicleBrand = result2.EnrollmentLFSDetail.LFSDetail[0].Vehicle_Brand.toLowerCase();
                        }

                        user.customerType = result2.EnrollmentLFSDetail.LFSDetail[0].customerType;
                        if (user.customerType == null || user.customerType == undefined || user.customerType.toLowerCase() == 'null') {
                            user.customerType = '';
                        } else {
                            user.customerType = result2.EnrollmentLFSDetail.LFSDetail[0].customerType.toLowerCase();
                        }

                        user.vehicleModel = result2.EnrollmentLFSDetail.LFSDetail[0].veh_model;
                        if (user.vehicleModel == null || user.vehicleModel == undefined || user.vehicleModel.toLowerCase() == 'null') {
                            user.vehicleModel = '';
                        } else {
                            user.vehicleModel = result2.EnrollmentLFSDetail.LFSDetail[0].veh_model.toLowerCase();
                        }

                        user.vin = result2.EnrollmentLFSDetail.LFSDetail[0].veh_vin;
                        if (user.vin == null || user.vin == undefined || user.vin.toLowerCase() == 'null') {
                            user.vin = '';
                        }
                    } else {
                        user.leasedVehicle = 'No';
                        user.lfsActivationDate = '';
                        user.veh_acquisition_date = '';
                        user.lfs_maturity_date = '';
                        user.lfs_termination_date = '';
                        user.lfs_extension_date = '';
                        //new changes
                        user.customerType = '';
                        user.vehicleBrand = '';
                        user.vehicleModel = '';
                        user.vin = '';
                        user.dealerName = '';
                        user.lfsNo = '';
                    }
                    this.showSpinner = false;
                }, err => {
                    this.showSpinner = false;
                });
            });
            // }
        });
    }

    async seniorEligibleCheck(row) {
        console.log('in the async fn enrollAssocComp','row',row);
        return new Promise((resolve, reject) => {
            this.enrollService.seniorEligibleCheck(row).subscribe(async (seniorEligibleResponse: any) => {
                console.log('Senior Eligible', seniorEligibleResponse);
                var certificationLevel = await this.appService.getSeniorEligibileCheck(seniorEligibleResponse.SeniorEligibleCheck.Result)
                console.log('certificationLevel', certificationLevel);
                resolve(certificationLevel);
            })
        });
    }

    // function to clear the spin-id and remove close icon after close is clicked in search-bar
    closeSpinSearch() {
        this.spinId = "";
        this.spinIdClicked = true;
    }

    // function to add close icon when search bar is on focus
    focusFunction() {
        this.spinIdClicked = true;
    }

    // function to remove close icon when search bar is on focus
    focusOutFunction() {
        if (this.spinId == "") {
            this.spinIdClicked = false;
        }
        else if (this.filteredUsers.length > 0) {
        }
        else {
            this.closeSpinSearch();
        }
    }

    // function to create a new 'Draft' enrollment
    createEnrollment() {
        this.enrolError = false;
        this.showSpinner = true;
        this.pdfLoading = true;
        this.enrollService.enrollUser(this.user).subscribe((result: any) => {
            if (result.enrollment_No.length != 0) {
                this.enrolError = false;
                console.log(this.user);
                this.enrollmentId = result.enrollment_No;
                this.createEnrollmentClick = true;
                this.status = "DRAFT";
                this.message = "Draft enrollment has been created for " + this.user.firstName + " " + this.user.lastName + " ";
                this.makePdf(this.user.firstName + " " + this.user.lastName + " ")
                this.expandFormNotFound = false;
                this.expandFormReady = true;
                // this.cdref.detectChanges();
                this.checkChrome ? this.expandButtonName = 'EXPAND' : this.expandButtonName = 'DOWNLOAD';
            }
            // this.showSpinner = false;
        }, err => {
            console.log('Error in enroll User');
            this.expandFormReady = true;
            // this.cdref.detectChanges();
	    this.enrolError = true;
            this.showSpinner = false;
        });
    }

    // function to check if both the checkboxes(I have signed the...) are checked before manual upload
    principal() {
        var principal = (<HTMLInputElement>document.getElementById("principalCheckBox")).checked
        var assocaite = (<HTMLInputElement>document.getElementById("associateCheckBox")).checked
        if (principal && assocaite) {
            this.browseDisable = false;
        }
        else {
            this.browseDisable = true;
        }
    }

    // function to handle the selected file and make the necessary validations on the selected files
    handleFileInput(files: FileList) {
        let maxFileSize = 3 * 1024 * 1024;
        if (files.item(0).type == 'application/pdf') {
            if (files.item(0).size <= maxFileSize) {
                this.pdfError = false;
                console.log('BrowsedFile', files);
                this.fileToUpload = files.item(0);
                this.checkbox = true;
                this.browseDisable = true;
                $('#associateCheckBox').prop('checked', false);
                $('#principalCheckBox').prop('checked', false);
                console.log('Browsed', this.fileToUpload);
                this.fileName = this.fileToUpload.name;
            } else {
                this.pdfError = true;
                this.errorMsg = "Please upload a PDF document of size less than 3MB";
            }
        } else {
            this.pdfError = true;
            this.errorMsg = "Please upload a valid PDF document";
            this.fileToUpload = files.item(0);
        }
    }

    // function to allow dragging files over the 'div' for manual uploar.
    allowDrop(ev) {
        ev.preventDefault();
    }

    // function to handle the dropped file in the 'drop' region.
    dropHandler(ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault()

        if (ev.dataTransfer.items && ev.dataTransfer.items.length == 1) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {

                    var files = ev.dataTransfer.items[i].getAsFile();
                    var ext = files.name.split('.').pop();
                    if (ext == "pdf") {
                        this.pdfError = false;
                        console.log(files);
                        console.log('... files[' + i + '].name = ' + files.name);
                        this.fileToUpload = files;
                        this.checkbox = true;
                        $('#associateCheckBox').prop('checked', false);
                        $('#principalCheckBox').prop('checked', false);
                        this.browseDisable = true;
                        console.log('Dropped', this.fileToUpload);
                        this.fileName = this.fileToUpload.name;

                        this.pdfHref = '';
                        let temppath = URL.createObjectURL(this.fileToUpload);
                        this.pdfHref = temppath;
                        $("#openPDF").attr("href", this.pdfHref);
                    } else {
                        this.pdfError = true;
                        this.errorMsg = "Please upload a valid PDF document";
                        setTimeout(function () {
                            this.pdfError = false;
                        }.bind(this), 15000);
                    }
                } else {
                    this.pdfError = true;
                    this.errorMsg = "Please upload a PDF document of size less than 3MB";
                    setTimeout(function () {
                        this.pdfError = false;
                    }.bind(this), 15000);
                }
            }
        }
        else {
            this.fileToUpload = null;
            this.checkbox = false;
            this.browseDisable = false;
            this.fileName = undefined;
        }
    }

    //function to move the selected file to Azure blob storage
    async uploadFile() {
        console.log('insideUpload');
        console.log('environment.baseUrl', environment.baseUrl);
        let Config: UploadParams;
        if (environment.baseUrl.indexOf('lmlqafunction') != -1) {
            await this.SasToken('docusign');
            Config = {
                sas: '?' + this.sasTokenResponse,
                storageAccount: 'lmlqastorage',
                containerName: 'docusign'
            };
        }
        else if (environment.baseUrl.indexOf('lmlprodfunction') != -1) {
            await this.SasToken('docusign');
            Config = {
                sas: '?' + this.sasTokenResponse,
                storageAccount: 'lmlprodstorage',
                containerName: 'docusign'
            };
        }
        else {
            await this.SasToken('dealer');
            Config = {
                sas: '?' + this.sasTokenResponse,
                storageAccount: 'lmlqastorage',
                containerName: 'dealer'
            };
        }

        if (this.fileToUpload !== null) {
            console.log('File to upload is not null', this.fileToUpload);
            var name = this.fileToUpload.type.split('/');

            // let blob = BlobService;
            var baseUrl = this.blob.generateBlobUrl(Config, this.enrollmentId + '.' + name[1]);
            console.log('baseUrl', baseUrl, ' ::: \n  sas   ' + Config.sas);
            this.config = {
                baseUrl: baseUrl,
                sasToken: Config.sas,
                blockSize: 1024 * 64, // OPTIONAL, default value is 1024 * 32
                file: this.fileToUpload,
                complete: () => {
                    console.log('Transfer completed !');
                    var myDate = new Date();
                    this.enrollService.statusUpdate(this.enrollmentId, 'ENROLLED', this.envelopeId, 'ENROLLED', myDate, 'F',
                        this.enrollmentId + '.' + name[1]).subscribe((response: any) => {
                            var rowCount = response.RowUpdated.rowCount;
                            if (rowCount) {
                                this.status = "ENROLLED";
                                this.fileName = this.enrollmentId;
                                this.successMessage = true;
                                this.message = "Congratulations! " + this.user.fullName + " has been successfully enrolled";
                                $('#requestModal').hide();
                                $('body').removeClass('modal-open');
                                $('.modal-backdrop').remove();
                                this.enrollmentAccordianBoolean = true;
                                this.uploadAccordian = false;
                                this.sentToAssociate = false;
                                this.pdfLoading = true;
                                this.expandButtonName = 'EXPAND';
                                // this.cdref.detectChanges();
                                console.log('this.enrollmentId', this.enrollmentId);
                                if (this.checkChrome) {
                                    this.enrollService.azureFile(this.enrollmentId + '.pdf?' + this.sasTokenResponse).subscribe((result: any) => {
                                        console.log('Status updated after upload', result);
                                        var file = new Blob([result.content._body], { type: 'application/pdf' });
                                        var fileURL = URL.createObjectURL(file);
                                        this.fileUrl = fileURL;
                                        this.fileBlob = file;
                                        this.disablePrint = false;
                                        this.pdfLoading = false;
                                        // this.cdref.detectChanges();
                                        this.expandFormNotFound = false;
                                    }, err => {
                                        console.log('error while trying to download the file from url after upload');
                                    });
                                } else {
                                    setTimeout(() => {
                                        (<HTMLIFrameElement>document.getElementById('expandImage')).src = environment.azureStorage + this.enrollmentId + '.pdf?' + this.sasTokenResponse;
                                    }, 2500);
                                }
                            } else {
                                // this.showFormImage = false;
                                // this.message = 'Sorry Please Try Again.';
                            }
                        }, err => {
                            console.log('Error while trying to update the status after upload');
                        });


                },
                error: (err) => {
                    console.log('Error in uploading file :::::', err);
                    this.errorUploadForm = true;
                },
                progress: (percent) => {
                    this.progressValue = percent;
                }
            };
            this.blob.upload(this.config);
        }
    }

    async SasToken(container) {
        this.sasTokenResponse = '';
        await this.dashboardService.getSasToken(container).toPromise().then(async (response: any) => {
            this.sasTokenResponse = response.SasToken.Result;
        }, err => {
            console.log('Error while trying to get Sas Token ::' + err);
        });
    }

    // function to hide the 'Send to Associate, Principal / GM' modal popup after successfully sending mail
    okButton() {
        this.emailMessage = 'Enrollment form will be emailed for signature to : '
        $('#sendToAssosiateModal').hide();
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }

    // function to email the enrollment form for Pending with Associate, Pending with Principal/GM and Resend email.
    sendToAssociate() {
        this.showSpinner = true;
        // console.log('spinner in fn', this.showSpinner);
        var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        this.invalidUserEmail = [];
        console.log('User in sendToAssociate', this.user);
        this.emailMessage = 'Enrollment form will be emailed for signature to : '
        //Check for GM or Principal
        if (this.emailButton == 'E-SIGN') {
            $('#principalRadio').is(':checked') ? this.selectPrincipal = true : this.selectPrincipal = false;
            $('#gmRadio').is(':checked') ? this.selectGM = true : this.selectGM = false;
        } else {
            if (this.user.sentTo == 'Principal') {
                this.selectPrincipal = true;
                this.selectGM = false;
            } else {
                this.selectGM = true;
                this.selectPrincipal = false
            }
        }
        if (this.selectPrincipal) {
            this.selectedOfficialEmail = this.user.principalEmail;
            if (!email_regex.test(this.selectedOfficialEmail)) {
                this.invalidUserEmail.push('Please provide proper email format for Principal');
            }
        } else if (this.selectGM) {
            this.selectedOfficialEmail = this.user.GMEmail;
            if (!email_regex.test(this.selectedOfficialEmail)) {
                this.invalidUserEmail.push('Please provide proper email format for General Manager');
            }
        }
        // Validation of emails
        console.log('this.user.email :: ->', this.user.email);
        if (!email_regex.test(this.user.email)) {
            this.invalidUserEmail.push('Please provide proper email format for Associate');
        }

        // server calls based on send / resend.
        var emailTest = environment.checkDocusignMailId;
        if (emailTest) {
            if (this.selectedOfficialEmail.indexOf('lexuslearn') != -1) {
                emailTest = true;
            }
            else {
                // debugger;
                emailTest = false;
                this.selectedOfficialEmail = 'LexusMasterLease@lexus.com';
            }
        }
        // if (email_regex.test(this.selectedOfficialEmail) && email_regex.test(this.user.email)) {
        if (this.invalidUserEmail.length == 0) {
            if (this.emailButton != 'E-SIGN (RESEND)') {
                console.log('First time send');
                this.disableCancelEmail = true;
                this.disableSendEmail = true;
                console.log('USer Docusign', this.user);
                this.enrollService.docuSignApi(this.user).subscribe((response: any) => {
                    this.showSpinner = false;
                    // console.log('spinner not resend resp', this.showSpinner);
                    console.log(response);
                    this.envelopeId = response.envelopeId;
                    this.enrollService.statusUpdate(this.enrollmentId, 'PENDING WITH ASSOCIATE', response.envelopeId, response.status, response.statusDateTime, 'T', this.enrollmentId + '.pdf').subscribe((result: any) => {
                        this.pdfLoading = true;
                        // this.cdref.detectChanges();
                        this.emailButton = 'E-SIGN (RESEND)';
                        this.emailMessage = 'Enrollment form has been sent for signatures to : ';
                        this.status = "PENDING WITH ASSOCIATE";
                        this.message = 'Enrollment form is currently with ' + this.user.first_name + ' ' + this.user.lastName + ' (Associate) for signature. '
                        this.disableCancelEmail = false;
                        this.disableSendEmail = false;
                        this.enrollService.updateEmailAddress(this.user, this.enrollmentId).subscribe((emailUpdateresponse: any) => {
                            if (emailUpdateresponse.RowUpdated.rowCount == 1) {
                                console.log('successfully Updated the email address');
                            } else {
                                console.log('unable to  Update the email address');
                            }
                        }, err => {
                            console.log('Error unable to  Update the email address');
                        });
                        this.enrollService.docusignFile(this.envelopeId).subscribe((response1: any) => {
                            var file = new Blob([response1.content._body], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            this.pdfLoading = false;
                            // this.cdref.detectChanges();
                            this.fileUrl = fileURL;
                            this.fileBlob = file;
                            setTimeout(function () {
                                (<HTMLIFrameElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                                this.expandFormReady = true;
                                // this.cdref.detectChanges();
                            }.bind(this), 2500);
                        }, err => {
                            this.expandFormReady = true;
                            // this.cdref.detectChanges();
                        });
                    });
                }, err => {
                    this.showSpinner = false;
                    // console.log('spinner not resend err', this.showSpinner);
                    this.disableCancelEmail = false;
                    this.disableSendEmail = false;
                    this.invalidUserEmail.push('Some Error Occured');
                });
            }
            else {
                console.log('Resend');
                this.showSpinner = false;
                // console.log('spinner resend', this.showSpinner);
                if (this.status == 'PENDING WITH ASSOCIATE') {
                    this.disableCancelEmail = true;
                    this.disableSendEmail = true;
                    this.enrollService.resendSignApiAssociate(this.user, this.envelopeId).subscribe((response: any) => {
                        console.log(response);
                        this.envelopeId = response.envelopeId;
                        this.pdfLoading = true;
                        this.emailButton = 'E-SIGN (RESEND)';
                        this.emailMessage = 'Enrollment form has been resent for signatures to : ';
                        this.status = "PENDING WITH ASSOCIATE";
                        this.message = 'Enrollment form is currently with ' + this.user.first_name + ' ' + this.user.lastName + ' (Associate) for signature. '
                        this.disableCancelEmail = false;
                        this.disableSendEmail = false;
                        this.enrollService.updateEmailAddress(this.user, this.enrollmentId).subscribe((emailUpdateresponse: any) => {
                            if (emailUpdateresponse.RowUpdated.rowCount == 1) {
                                console.log('successfully Updated the email address');
                            } else {
                                console.log('unable to  Update the email address');
                            }
                        }, err => {
                            console.log('Error unable to  Update the email address');
                        });
                        this.enrollService.docusignFile(this.envelopeId).subscribe((response1: any) => {
                            var file = new Blob([response1.content._body], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            this.pdfLoading = false;
                            // this.cdref.detectChanges();
                            this.fileUrl = fileURL;
                            this.fileBlob = file;
                            setTimeout(function () {
                                (<HTMLIFrameElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                                this.expandFormReady = true;
                                // this.cdref.detectChanges();
                            }.bind(this), 2500);
                        }, err => {
                            this.expandFormReady = true;
                            // this.cdref.detectChanges();
                        });
                    });
                }

                if (this.status == 'PENDING WITH PRINCIPAL') {
                    this.disableCancelEmail = true;
                    this.disableSendEmail = true;
                    this.enrollService.resendSignApiPrincipal(this.user, this.envelopeId).subscribe((response: any) => {
                        console.log(response);
                        this.envelopeId = response.envelopeId;
                        this.pdfLoading = true;
                        this.emailButton = 'E-SIGN (RESEND)';
                        this.emailMessage = 'Enrollment form has been resent for signatures to : ';

                        this.status = "PENDING WITH PRINCIPAL";
                        if (this.selectPrincipal) {
                            this.message = 'Enrollment form is currently with ' + this.user.principalFullName + ' (Principal) for signature. '
                        }
                        else if (this.selectGM) {
                            this.message = 'Enrollment form is currently with ' + this.user.GMFullName + ' (GM) for signature. '
                        }
                        this.disableCancelEmail = false;
                        this.disableSendEmail = false;
                        this.enrollService.updateEmailAddress(this.user, this.enrollmentId).subscribe((emailUpdateresponse: any) => {
                            if (emailUpdateresponse.RowUpdated.rowCount == 1) {
                                console.log('successfully Updated the email address');
                            } else {
                                console.log('unable to  Update the email address');
                            }
                        }, err => {
                            console.log('Error unable to  Update the email address');
                        });
                        this.enrollService.docusignFile(this.envelopeId).subscribe((response1: any) => {
                            var file = new Blob([response1.content._body], { type: 'application/pdf' });
                            var fileURL = URL.createObjectURL(file);
                            this.pdfLoading = false;
                            // this.cdref.detectChanges();
                            this.fileUrl = fileURL;
                            this.fileBlob = file;
                            setTimeout(function () {
                                (<HTMLIFrameElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                                this.expandFormReady = true;
                                // this.cdref.detectChanges();
                            }.bind(this), 2500);
                        }, err => {
                            this.expandFormReady = true;
                            // this.cdref.detectChanges();
                        });
                    });
                }
            }
        }
    }

    // function to print the enrollemnt form (for enrolled status)
    print() {
        this.sentToAssociate = false;
        if (this.checkChrome) {
            (<HTMLIFrameElement>document.getElementById("expandImage")).contentWindow.print();
        } else {
            let type = this.fileBlob.type.split('/');
            var name = "" + this.enrollmentId + '.' + type[1];
            window.navigator.msSaveOrOpenBlob(this.fileBlob, name);
        }
    }

    async downloadMEFile() {
        console.log(this.enrollmentform);
        this.pdfLoading = true;
        let containerurl = environment.azureStorage.split('/');
        let container = containerurl[containerurl.length - 2];
        await this.SasToken(container);
        await this.enrollService.azureFile(this.enrollmentform + '?' + this.sasTokenResponse).toPromise().then(async (response: any) => {
            console.log(response.content._body.type);
            var file = new Blob([response.content._body], { type: response.content._body.type });
            var fileURL = URL.createObjectURL(file);
            this.pdfLoading = false;
            // this.cdref.detectChanges();
            this.fileUrl = fileURL;
            this.fileBlob = file;
            if (this.checkChrome) {
                var downloadUrl = URL.createObjectURL(this.fileBlob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = "" + this.enrollmentId + response.content._body.type;
                document.body.appendChild(a);
                a.click();
            } else {
                let type = this.fileBlob.type.split('/');
                var name = "" + this.enrollmentId + type[1];
                window.navigator.msSaveOrOpenBlob(this.fileBlob, name);
            }
        }, err => {
            this.enrolformNotFound = true;
            this.pdfLoading = false;
        });
    }

    // function to print the enrollemnt form (for pending upload status ) or update the status to pending upload (for Draft status)
    manualSign() {
        this.showSpinner = true;
        if (this.status == 'DRAFT') {
            const myDate = new Date();
            this.enrollService.statusUpdate(this.enrollmentId, 'PENDING UPLOAD', this.envelopeId, 'sent', myDate, 'F',
                this.enrollmentId + '.pdf').subscribe((response: any) => {
                    var rowCount = response.RowUpdated.rowCount;
                    this.showSpinner = false;
                    if (rowCount) {
                        if (this.checkChrome) {
                            (<HTMLIFrameElement>document.getElementById("expandImage")).contentWindow.print();
                        } else {
                            window.navigator.msSaveOrOpenBlob(this.draftData, "EnrollmentForm.pdf");
                        }
                        this.sentToAssociate = true;
                        this.status = 'PENDING UPLOAD';
                        this.enrollmentAccordianBoolean = false;
                    } else {
                        // this.showFormImage = false;
                        // this.message = 'Sorry Please Try Again.';
                    }
                }, err => {
                    console.log('Error while updating status as Pending upload');
                    this.showSpinner = false;
                });
        } else {
            this.showSpinner = false;
            this.sentToAssociate = true;
            if (this.checkChrome) {
                (<HTMLIFrameElement>document.getElementById("expandImage")).contentWindow.print();
            }
            else {
                window.navigator.msSaveOrOpenBlob(this.draftData, "EnrollmentForm.pdf");
            }
        }
    }

    // function to remove the manually uploaded file
    clearFile() {
        this.fileToUpload = null;
        this.fileName = undefined;
    }

    // function to delete the enrollment (not available for 'Enrolled' status)
    async deleteEnrollment() {
        this.showSpinner = true;
        if (this.envelopeId != null && this.status != 'DRAFT' && this.status != 'PENDING UPLOAD') {
            await this.enrollService.deleteEnvelope(this.envelopeId).toPromise().then(async (response: any) => {
                console.log(response);
                if (response.envelopeId == this.envelopeId) {
                    console.log('Successfully deleted the Envelope in Docusign');
                    await this.deleteEnrollmentFromDb();
                }
            }, err => {
                console.log('Error in deleting the DocuSign Envelope');
                this.showSpinner = false;
            });
        } else {
            await this.deleteEnrollmentFromDb();
        }
    }

    async deleteEnrollmentFromDb(){
        await this.enrollService.deleteEnrollment(this.enrollmentId).toPromise().then(async (response: any) => {
            this.deleteCount = response.RowUpdated.rowCount;
            if (this.deleteCount == 1) {
                this.showFormImage = false;
                this.message = 'Enrollment for ' + this.user.first_name + ' ' + this.user.Last_Name + ' is successfully deleted.';
                this.status = 'DELETED';
                this.enrollmentId = '';
            } else {
                this.showFormImage = false;
                this.message = 'Contact Lease My Lexus Support Team.';
            }
            this.showSpinner = false;
        }, err => this.showSpinner = false);
    }

    // function to select the radio button (Principal or GM) by default based on earlier selection
    emailForm() {
        this.sentToAssociate = false;
        if (this.emailButton == 'E-SIGN') {
            if (this.principalDetailResponse.length != 0) {
                (<HTMLInputElement>document.getElementById("principalRadio")).checked = true;
                $('#PD').addClass('fontBold');
                $('#GM').removeClass('fontBold');
                $('#GMEmailSelect').prop('disabled', 'disabled');
                $('#GMEmail').attr('disabled', true);
                $('#principalEmailSelect').attr('disabled', false);
                $('#principalEmail').attr('disabled', true);
                this.selectPrincipal = true;
                this.selectGM = false;
                this.user.sentTo = 'Principal';
            } else {
                (<HTMLInputElement>document.getElementById("gmRadio")).checked = true;
                $('#GM').addClass('fontBold');
                $('#PD').removeClass('fontBold');
                $('#principalEmailSelect').prop('disabled', 'disabled');
                $('#principalEmail').attr('disabled', true);
                $('#GMEmailSelect').attr('disabled', false);
                $('#GMEmail').attr('disabled', true);
                this.selectPrincipal = false;
                this.selectGM = true;
                this.user.sentTo = 'GM';
            }
        } else {
            if (this.user.principalEmail == 'null' || this.user.principalEmail == null || this.user.principalEmail == undefined || this.user.principalEmail == 'undefined') {
                this.user.principalEmail = '';
            }
            if (this.user.GMEmail == 'null' || this.user.GMEmail == null || this.user.GMEmail == undefined || this.user.GMEmail == 'undefined') {
                this.user.GMEmail = '';
            }
            if (this.user.sentTo == 'Principal') {
                this.selectPrincipal = true;
                this.selectGM = false;
                (<HTMLInputElement>document.getElementById("principalRadio")).checked = true;
                // $("#gmRadio").attr("disabled", true);
                $('#GMEmailSelect').prop('disabled', 'disabled');
                $('#GMEmail').attr('disabled', true);
                // $('#principalEmailSelect').prop('disabled', 'disabled');
            } else {
                this.selectGM = true;
                this.selectPrincipal = false;
                (<HTMLInputElement>document.getElementById("gmRadio")).checked = true;
                // $("#principalRadio").attr('disabled', true);
                $('#principalEmailSelect').prop('disabled', 'disabled');
                $('#principalEmail').attr('disabled', true);
                // $('#GMEmailSelect').prop('disabled', 'disabled');
            }
        }
    }

    // function to view the file that was manually uploaded now.
    viewUploadedFile(e) {
        this.pdfHref = '';
        var input = e.target
        var tmppath = URL.createObjectURL(input.files[0]);
        this.pdfHref = tmppath;
        $("#openPDF").attr("href", this.pdfHref);
    }

    // function to open the file in a new tab that was manually uploaded now.
    openPdf() {
        var blob = new Blob([this.fileToUpload], { type: '.pdf' });
        var url = window.URL.createObjectURL(blob);
        $('#openPDF').attr('href', url);
        navigator.msSaveOrOpenBlob(blob, 'enrollmentForm.pdf');
    }

    // function to update when principal radio button is clicked
    principalSelectChange() {
        if ($('#principalRadio').is(':checked')) {
            this.selectGM = false;
            this.selectPrincipal = true;
            this.user.principalFullName = '';
            this.user.principalEmail = '';
            this.user.principalSpinId = '';
            $('#PD').addClass('fontBold');
            $('#GM').removeClass('fontBold');
            $('#GMEmailSelect').prop('disabled', 'disabled');
            $('#GMEmail').attr('disabled', true);
            $('#principalEmailSelect').attr('disabled', false);
            $('#principalEmail').attr('disabled', true);
            this.disableSendEmail = true;
            this.user.sentTo = 'Principal';
            this.enrollService.sentToUpdate(this.enrollmentId, 'Principal').subscribe((res: any) => {
                console.log('Updated to principal', res);
                if (res.RowUpdated.rowCount == 1) {
                    this.user.sentTo = 'Principal';
                }
                this.disableSendEmail = false;
            }, err => {
                this.disableSendEmail = false;
            })
        }
    }

    // function to update when principal radio button is clicked
    GMSelectValidation() {
        if ($('#gmRadio').is(':checked')) {
            this.selectGM = true;
            this.selectPrincipal = false;
            this.user.GMFullName = '';
            this.user.GMEmail = '';
            this.user.GMSpinId = '';
            $('#PD').removeClass('fontBold');
            $('#GM').addClass('fontBold');
            $('#GMEmailSelect').attr('disabled', false);
            $('#GMEmail').attr('disabled', true);
            $('#principalEmailSelect').prop('disabled', 'disabled');
            $('#principalEmail').attr('disabled', true);
            this.disableSendEmail = true;
            this.user.sentTo = 'GM';
            this.enrollService.sentToUpdate(this.enrollmentId, 'GM').subscribe((res: any) => {
                console.log('Updated to gm', res);
                if (res.RowUpdated.rowCount == 1) {
                    this.user.sentTo = 'GM';
                }
                this.disableSendEmail = false;
            }, err => {
                this.disableSendEmail = false;
            });
        }
    }

    // function to make pdf files (for creating pdf files instead of fetching from docusign)
    makePdf(name): Promise<string> {
        return new Promise(resolve => {
            this.pdfLoading = true;
            this.expandFormReady = false;
            pdfmake.vfs = pdfFonts.pdfMake.vfs;
            var docDefinition;
            this.makeBase64('1').then(bgImage1 => {

                this.makeBase64('2').then(bgImage2 => {

                    this.makeBase64('3').then(bgImage3 => {

                        this.makeBase64('4').then(bgImage4 => {

                            this.makeBase64('5').then(bgImage5 => {

                                this.makeBase64('6').then(bgImage6 => {

                                    this.makeBase64('7').then(bgImage7 => {

                                        this.makeBase64('8').then(bgImage8 => {

                                            this.makeBase64('9').then(bgImage9 => {

                                                docDefinition = {
                                                    background: function (currentPage, pageSize) {
                                                        if (currentPage == 1) {
                                                            return {
                                                                image: bgImage1,
                                                                width: pageSize.width,
                                                                height: pageSize.height
                                                            }
                                                        }
                                                        else {
                                                            return ''
                                                        }
                                                    },
                                                    pageMargins: [0, 0, 0, 0],
                                                    content: [
                                                        {
                                                            text: name, bold: true, fontSize: 12,
                                                            margin: [195, 240, 0, 0], pageBreak: 'after'
                                                        },
                                                        {
                                                            image: bgImage2,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage3,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage4,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage5,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage6,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage7,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage8,
                                                            width: 595.28,
                                                            height: 841.89,
                                                            pageBreak: 'after',
                                                        },
                                                        {
                                                            image: bgImage9,
                                                            width: 595.28,
                                                            height: 841.89,
                                                        },
                                                    ]
                                                }
                                                const pdfDocGenerator = pdfmake.createPdf(docDefinition);
                                                pdfDocGenerator.getBlob((blob) => {
                                                    var file = new Blob([blob], { type: 'application/pdf' });
                                                    this.draftData = file;
                                                    var fileURL = URL.createObjectURL(file);
                                                    this.fileUrl = fileURL;
                                                    resolve('Success');
                                                    setTimeout(function () {
                                                        (<HTMLIFrameElement>document.getElementById('expandImage')).src = fileURL + '#zoom=100';
                                                        this.pdfLoading = false;
                                                        this.showSpinner = false;
                                                        this.expandFormNotFound = false;
                                                        this.expandFormReady = true;
                                                        // this.cdref.detectChanges();
                                                    }.bind(this), 2500);
                                                    if (this.principalDetailResponse.length != 0) {
                                                        (<HTMLInputElement>document.getElementById("principalRadio")).checked = true;
                                                    } else {
                                                        (<HTMLInputElement>document.getElementById("gmRadio")).checked = true;
                                                    }
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    }

    // function to make base 64 files (for creating pdf files instead of fetching from docusign)
    makeBase64(fileName): Promise<string> {
        return new Promise(resolve => {
            //console.log(new Date());
            var xhr = new XMLHttpRequest();
            // xhr.open('GET', 'assets/enrollment_form/Enrollment_Form_' + fileName + '.jpg', true);
            xhr.open('GET', 'assets/enrollment_form/AE_' + fileName + '.jpg', true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function (e: any) {
                var binary = '';
                var arr = new Uint8Array(e.currentTarget.response);
                var len = arr.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(arr[i]);
                }
                var b64 = btoa(binary);
                var dataURL = "data:image/jpeg;base64," + b64;
                resolve(dataURL);
            };
            xhr.onerror = function () {
                resolve('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAIBAQIBAQICAgICAgICAwUDAwMDAwYEBAMFBwYHBwcGBwcICQsJCAgKCAcHCg0KCgsMDAwMBwkODw0MDgsMDAz/2wBDAQICAgMDAwYDAwYMCAcIDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD9/KKKKAP/2Q==');
            }
            xhr.send();
        });
    }

    expandForm() {
        console.log('expandFormReady ', this.expandFormReady)
        if (this.expandFormReady) {
            if (this.status == 'DRAFT' || this.status == 'PENDING UPLOAD') {
                if (!this.checkChrome) {
                    window.navigator.msSaveOrOpenBlob(this.draftData, 'Enrollment_Form.pdf')
                } else {
                    $('#myModal2').modal('show');
                }
                this.expandFormNotFound = false;
            } else {
                $('#myModal2').modal('show');

                this.user.enrollmentForm == null ? this.expandFormNotFound = true : this.expandFormNotFound = false;
            }
        }
    }
}
