import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { CorpDashboardService } from '../corp-dashboard/corp-dashboard.service';
import { ExcelService } from '../service/excel.service';
import { CorpHoldResumeService } from '../corp-hold-resume/corp-hold-resume.service';
import { PaymentHistoryService } from '../payment-history/payment-history.service';
import { PlatformLocation, Location } from '@angular/common';
import { CommonService } from '../service/common.service';
import { DebitPaymentService } from './debit-payment.service';
import { numberFormat } from 'highcharts';
import { Router } from '@angular/router';
declare var $: any;


export interface DebitData {
  status: string;
  name: string;
  spinId: string;
  dealerCode: string;
  paymentPeriod: string;
  debitDate: Date;
  remarks: string;
  check: boolean;
  isChecked: number;
  toBeSaved: boolean;
  debit_history_id: number;
  Remarks: string;
}

@Component({
  selector: 'app-debit-payment',
  templateUrl: './debit-payment.component.html',
  styleUrls: ['./debit-payment.component.css']
})

export class DebitPaymentComponent implements OnInit {



  // ------------------------------------------Table Source----------------------------------------

  debitDataSourceNotExp: MatTableDataSource<DebitData>;
  debitDataSourceExp: MatTableDataSource<DebitData>;
  debitDataSourceApprove: MatTableDataSource<DebitData>;
  // debitColumns = ['Checkbox', 'name', 'spinId', 'dealerCode', 'paymentPeriod', 'debitDate', 'status', 'remarks'];
  debitColumns = ['Checkbox', 'name', 'spinId', 'dealerCode', 'paymentPeriod', 'debitDate', 'reasonId','otherReason', 'amount'];
  debitColumnsExported = ['name', 'spinId', 'dealerCode', 'paymentPeriod', 'debitDate', 'reasonId','otherReason', 'amount', 'exportedBy', 'exportedOn'];
  debitColumnsApproved = ['name', 'spinId', 'dealerCode', 'paymentPeriod', 'debitDate', 'reasonId', 'otherReason','amount', 'invoiceNumber', 'approvedDate'];

  //------------------------------------- Paginator and Sort @ViewChild------------------------------

  @ViewChild('debitHistorySort', { read: MatSort }) debitHistorySort: MatSort;
  @ViewChild('debitHistorySortExp', { read: MatSort }) debitHistorySortExp: MatSort;
  @ViewChild('debitHistorySortInv', { read: MatSort }) debitHistorySortInv: MatSort;
  @ViewChild('debitHistoryPaginator', { read: MatPaginator }) debitHistoryPaginator: MatPaginator;
  @ViewChild('debitHistoryPaginatorExp', { read: MatPaginator }) debitHistoryPaginatorExp: MatPaginator;
  @ViewChild('debitHistoryPaginatorInv', { read: MatPaginator }) debitHistoryPaginatorInv: MatPaginator;

  //------------------------------------- Table Variables--------------------------------------------

  //Debit Variables
  debitTableNotExpLoading: boolean = true;
  debitTableExpLoading: boolean = true;
  debitNoDataNotExp: boolean = false;
  debitNoDataExp: boolean = false;
  debitNoDataNotInv: boolean = false;
  debitNoDataInv: boolean = false;
  debitTableInvLoading: boolean = true;
  debitHistoryResponse: any = '';
  DEBIT_DATA: any = [];
  DEBIT_DATA_NOTEXP: any = [];
  DEBIT_DATA_EXP: any = [];
  DEBIT_DATA_INV: any = [];
  DEBITDATA: DebitData[] = [];
  debitTotalData: number = 0;
  timeStamp: any = '';
  DebitCountNotExp: number = 0;
  DebitCountExp: number = 0;
  DebitCountInv: number = 0;
  tableDisplay: boolean = true;

  //Tab Variables
  selectedDebitView: number = 1;
  selectedTab: number = 1;

  //statusFilter Variable
  errorData: any = [];
  acknowledgedData: any = [];
  postedData: any = [];
  awaitingData: any = [];

  //Excel Variables
  noExcelData: boolean;
  tabName: string = '';
  exportApproval: boolean = true;
  exportApproveTab: boolean = false;

  //Debits Approval Variables
  totalChecked: number = 0;
  totalCost: number
  debitReviewNotExpChecked: number = 0;
  checkAllDebitForReview = false;
  statusFilterboolen: boolean = false;
  checkedArray = [];
  selectOverRideCheck: any;
  totalCheckedArrayforApproval = [];
  dateMismatchArray = [];
  d = new Date();
  totalCheckedSaved: number = 0;
  selection = [];
  frozenDisabled = 0;
  checkedRow: any = [];
  successMsg: string;

  // approve variables
  showSpinner = false;
  debitCheckCount = 0;
  paymentDataResponse: any = [];
  DEBIT_DATA_Unchanged: DebitData[] = [];
  debitRecordCount = 0;
  overflow: boolean = false;
  freezeMonth = '';
  excelApproval: any[] = [];
  acCnt = 0;
  bcCnt = 0;

  spinId: any;
  filteredUsers: any[];
  isLoading: boolean;
  spinIdClicked: boolean;
  PayHistoryForm: FormGroup;
  user: any;
  panelOpenState = false;
  showTable = 'notExport';
  holdTable: boolean = true;
  spinSearched: boolean = false;
  userDebitHistory: any = [];
  userPaymentHistory: any = [];
  userDebitHistoryTableLoading: boolean = true;
  noUserDebitHistory: boolean = false;
  userPaymentHistoryTableLoading: boolean = true;
  noUserPaymentHistory: boolean = false;
  months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  paymentMonth: any = '';
  paymentYear: any = '';
  reasonId = '';
  otherReason = '';
  reasons = [
    {
      "value": "Lease Termed",
      "name": "Lease Termed"
    },
    {
      "value": "Assoc Termed",
      "name": "Assoc Termed"
    },
    {
      "value": "Duplicate Pmt",
      "name": "Duplicate Pmt"
    },
    {
      "value": "Dealer Request",
      "name": "Dealer Request"
    },
    {
      "value": "New Enroll",
      "name": "New Enroll"
    },
    {
      "value": "Incorrect PayCd",
      "name": "Incorrect PayCd"
    },
    {
      "value": "Others",
      "name": "Others"
    }
  ];
  remarks: any = '';
  searchSec: boolean = true;
  succesMessage: string;
  errorMessage: string;
  Success: boolean;
  Error: boolean;
  allCheckBoxChecked: boolean = false;
  paymentListSelected = [];
  paymentListSelectedStr = '';
  exportDebitErrMsg = '';
  constructor(private excelService: ExcelService, private appService: CommonService, private fb: FormBuilder,
    private debitService: DebitPaymentService, private router: Router, private paymentService: PaymentHistoryService) {
    // this.selectDebitView(1);
    this.getDebitsForReviewData();
    this.holdTable = true;

    this.checkedRow = this.appService.getCheckedRows();
    for (let i = 1; i < this.checkedRow.length; i++) {
      if (this.checkedRow.indexOf(i) !== -1) {
        $('input[name="paymentCheckbox"]').attr('checked', true);
      }
    }
  }
  ngOnInit() {
    console.log('clg admin debit', this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }

    this.PayHistoryForm = this.fb.group({
      userInput: null
    });
  }

  // -----------------------------------------Tab Navigation Method----------------------------------

  // selectDebitView(tabIndex) {
  //     if (tabIndex == 1) {
  //       this.selectedDebitView = 1;  
  //     }     
  //   }

  // -----------------------------------------Table Filter Method----------------------------------

  debitapplyFilterNotExp(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.debitDataSourceNotExp.filter = filterValue;
    this.debitDataSourceNotExp.sort = this.debitHistorySort;
    this.debitDataSourceNotExp.paginator = this.debitHistoryPaginator;
  }
  debitapplyFilterExp(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.debitDataSourceExp.filter = filterValue;
    this.debitDataSourceExp.sort = this.debitHistorySortExp;
    this.debitDataSourceExp.paginator = this.debitHistoryPaginatorExp;
  }
  debitapplyFilterApprove(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.debitDataSourceApprove.filter = filterValue;
    this.debitDataSourceApprove.sort = this.debitHistorySortInv;
    this.debitDataSourceApprove.paginator = this.debitHistoryPaginatorInv;
  }

  // ======================================DEALER CODE SEARCH==========================================

  fadeDiv() { // make the table section is opacity to 0.5
    this.spinIdClicked = true;
  }

  fadeOut() { // make the table section is opacity to 1
    this.spinIdClicked = false;
  }

  closeSpinSearch() { // make the table section is opacity to 0.5 and close the search
    this.spinId = "";
    this.spinIdClicked = true;
  }

  // navigateBack() {
  //   if (window.location.href.indexOf("localhost") != -1) {
  //     var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '#/debitPayment';
  //     window.history.pushState({ path: newurl }, '', newurl);
  //   } else {
  //     // URL will change to back to debit Payment
  //     this._location.go('debitPayment');
  //   }
  //   this.tableDisplay = true;
  //   this.spinId = '';
  // }

  // -----------------------------------------Service Method----------------------------------

  filterUser() {
    if (this.spinId != undefined) { this.spinId = this.spinId.replace(/[^a-zA-Z0-9]/g, ""); }
    if (this.spinId != undefined && this.spinId.length > 1) {
      this.filteredUsers = [];
      this.isLoading = true;
      this.debitService.searchPaymentHistory(this.spinId).subscribe((result: any) => {
        this.filteredUsers = result.PaymentHistorySearch.Result;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.filteredUsers = [];
        console.log('Error', err);
        console.log('Error while fetching the user list');
      });
    }
  }

  navigateBack() {
    this.getDebitsForReviewData();
    this.holdTable = true;
  }

  selectView(index: number) {
    this.selectedDebitView = index;
    if (this.selectedDebitView == 1) {
    }
  }

  selectUser(user) {
    console.log('Selected User', user);
    this.user = user;
    this.tableDisplay = true;
    this.spinSearched = true;
    this.getDebitHistoryDetail(user.spinId);
    this.getPaymentHistoryDetail(user.spinId);
  }
  getDebitHistoryDetail(spinId: any) {
    this.paymentMonth = ''; this.paymentYear = ''; this.reasonId = ''; this.remarks = ''; this.otherReason = '';
    this.Error = false;
    this.userDebitHistory = [];
    this.userDebitHistoryTableLoading = true;
    this.noUserDebitHistory = false;
    this.debitService.getDebitPayments(spinId).subscribe((response: any) => {
      this.userDebitHistory = [];
      [].push.apply(this.userDebitHistory, response.DebitPayments.Result);
      if (this.userDebitHistory.length != 0) {
        this.noUserDebitHistory = false;
        this.userDebitHistoryTableLoading = false;
        // for (let a=0; a<this.userDebitHistory.length; a++) {
        //   this.userDebitHistory[a].debitDate = new Date(this.userDebitHistory[a].debitDate.substring(0, 10).replace(/-/g, '\/')).toLocaleDateString();
        //   if (this.userDebitHistory[a].isExport == 'Y') {this.userDebitHistory[a].isExport = 'Yes'}
        //   else if (this.userDebitHistory[a].isExport == 'N') {this.userDebitHistory[a].isExport = 'No'}
        // }
      } else {
        this.userDebitHistoryTableLoading = false;
        this.noUserDebitHistory = true;
        this.userDebitHistory = [];
      }
    }, err => {
      this.userDebitHistoryTableLoading = false;
      this.noUserDebitHistory = true;
      this.userDebitHistory = [];
    })
  }
  getPaymentHistoryDetail(spinId: any) {
    this.Error = false;
    this.userPaymentHistory = [];
    this.userPaymentHistoryTableLoading = true;
    this.noUserPaymentHistory = false;
    let finalPaymentHistory = [];
    let paymentHostoryArr = [];
    this.paymentService.getPayHistoryDataSpinId(spinId).subscribe((response: any) => {
      this.userPaymentHistory = [];
      [].push.apply(paymentHostoryArr, response.PaymentHistory.Result);
      for (let i = 0; i < paymentHostoryArr.length; i++) {
        for (let j = 0; j < this.userDebitHistory.length; j++) {
          if (paymentHostoryArr[i].payDueCd == this.userDebitHistory[j].paymentPeriod) {
            paymentHostoryArr[i].nocheck = true;
            break;
          }
        }
      }
      this.userPaymentHistory = paymentHostoryArr;
      if (this.userPaymentHistory.length != 0) {
        this.noUserPaymentHistory = false;
        this.userPaymentHistoryTableLoading = false;
      } else {
        this.userPaymentHistoryTableLoading = false;
        this.noUserPaymentHistory = true;
        this.userPaymentHistory = [];
      }
    }, err => {
      this.userPaymentHistoryTableLoading = false;
      this.noUserPaymentHistory = true;
      this.userPaymentHistory = [];
    })
  }
  async getDebitsForReviewData() {
    // this.checkAllDebitForReview = false;
    this.debitReviewNotExpChecked = 0;
    this.totalChecked = 0;
    this.spinSearched = false;

    this.DEBIT_DATA = []; this.DEBIT_DATA_NOTEXP = []; this.DEBIT_DATA_EXP = []; this.DEBIT_DATA_INV = [];
    this.debitTableNotExpLoading = true; this.debitTableExpLoading = true; this.debitTableInvLoading = true;
    if (this.appService.baseUrl != '') {
      this.debitService.getDebitPayments('').subscribe((response: any) => {
        this.DEBIT_DATA = []; this.DEBIT_DATA_NOTEXP = []; this.DEBIT_DATA_EXP = []; this.DEBIT_DATA_INV = [];
        [].push.apply(this.DEBIT_DATA, response.DebitPayments.Result);
        console.log(this.DEBIT_DATA);
        if (this.DEBIT_DATA.length != 0) {
          this.timeStamp = response.DebitPayments.Result[0].runDate;
          for (let i = 0; i < this.DEBIT_DATA.length; i++) {
            this.DEBIT_DATA[i].debitDate = new Date(this.DEBIT_DATA[i].debitDate.substring(0,10).replace(/-/g,'\/')).toLocaleDateString();
            if (this.DEBIT_DATA[i].exportedOn) {this.DEBIT_DATA[i].exportedOn = new Date(this.DEBIT_DATA[i].exportedOn.substring(0,10).replace(/-/g,'\/')).toLocaleDateString()}
            if (this.DEBIT_DATA[i].approvedDate) {this.DEBIT_DATA[i].approvedDate = new Date(this.DEBIT_DATA[i].approvedDate.substring(0,10).replace(/-/g,'\/')).toLocaleDateString()}
            if (this.DEBIT_DATA[i].created_on) {this.DEBIT_DATA[i].created_on = new Date(this.DEBIT_DATA[i].created_on.substring(0,10).replace(/-/g,'\/')).toLocaleDateString()}
            if (this.DEBIT_DATA[i].isChecked == 1) {
              this.DEBIT_DATA[i].check = true;
              this.debitReviewNotExpChecked += 1;
              this.totalChecked = this.debitReviewNotExpChecked;
            } else { this.DEBIT_DATA[i].check = false; }
            if (this.DEBIT_DATA[i].isExport == 'N' && this.DEBIT_DATA[i].invoiceNumber == null) {
              this.debitNoDataNotExp = false;
              this.DEBIT_DATA_NOTEXP.push(this.DEBIT_DATA[i]);
            } else if (this.DEBIT_DATA[i].isExport == 'Y' && this.DEBIT_DATA[i].exportedBy != '' && this.DEBIT_DATA[i].invoiceNumber == null) {
              this.DEBIT_DATA_EXP.push(this.DEBIT_DATA[i]);
              this.debitNoDataExp = false;
            } else if (this.DEBIT_DATA[i].invoiceNumber != null && this.DEBIT_DATA[i].invoiceNumber != '') {
              this.DEBIT_DATA_INV.push(this.DEBIT_DATA[i]);
              this.debitNoDataInv = false;
            }
          }
          console.log('DEBIT_DATA_NOTEXP', this.DEBIT_DATA_NOTEXP)
          this.DebitCountNotExp = this.DEBIT_DATA_NOTEXP.length;
          this.DebitCountExp = this.DEBIT_DATA_EXP.length;
          this.DebitCountInv = this.DEBIT_DATA_INV.length
          if (this.DebitCountNotExp == 0) { this.debitNoDataNotExp = true }
          if (this.DebitCountExp == 0) { this.debitNoDataExp = true }
          if (this.DebitCountInv == 0) { this.debitNoDataInv = true }
          this.debitDataSourceNotExp = new MatTableDataSource(this.DEBIT_DATA_NOTEXP);
          this.debitDataSourceExp = new MatTableDataSource(this.DEBIT_DATA_EXP);
          this.debitDataSourceApprove = new MatTableDataSource(this.DEBIT_DATA_INV);
          this.debitDataSourceNotExp.sort = this.debitHistorySort;
          this.debitDataSourceNotExp.paginator = this.debitHistoryPaginator;
          this.debitDataSourceExp.sort = this.debitHistorySortExp;
          this.debitDataSourceExp.paginator = this.debitHistoryPaginatorExp;
          this.debitDataSourceApprove.sort = this.debitHistorySortInv;
          this.debitDataSourceApprove.paginator = this.debitHistoryPaginatorInv;
        } else {
          var element = <HTMLInputElement>document.getElementById("allCheckBox");
          element.checked = false;
          this.debitNoDataNotExp = true; this.debitNoDataExp = true; this.debitNoDataExp = true;
          this.DEBIT_DATA = []; this.DEBIT_DATA_NOTEXP = []; this.DEBIT_DATA_EXP = [];
          this.debitDataSourceNotExp = new MatTableDataSource(this.DEBIT_DATA_NOTEXP);
          this.debitDataSourceExp = new MatTableDataSource(this.DEBIT_DATA_EXP);
          this.debitDataSourceApprove = new MatTableDataSource(this.DEBIT_DATA_INV);
          this.debitReviewNotExpChecked = 0;
          this.totalChecked = 0;
          this.DebitCountNotExp = 0;
          this.DebitCountExp = 0;
          this.DebitCountInv = 0;

        }
        this.debitTableNotExpLoading = false; this.debitTableExpLoading = false; this.debitTableInvLoading = false;
      }, err => {
        console.log('Error', err);
        console.log('Error while fetching Current Month Debit Data');
        this.DEBIT_DATA_NOTEXP = []; this.DEBIT_DATA_EXP = [];
        this.debitTableNotExpLoading = false; this.debitTableExpLoading = false; this.debitTableInvLoading = false;
        this.debitNoDataNotExp = true; this.debitNoDataExp = true; this.debitNoDataExp = true;
        this.DEBIT_DATA = [];
      });
    }
  }

  async debitPayment() {
    this.showSpinner = true;
    const paymentPeriodList = this.paymentListSelectedStr.split(',');
    let amountArr = [];
    for (let i = 0; i < paymentPeriodList.length; i++) {
      this.paymentYear = paymentPeriodList[i].split('-')[1];
      this.paymentMonth = paymentPeriodList[i].split('-')[0];
    let amount = 0;
    if (this.paymentYear == 2023) {
      if (this.paymentMonth.toLowerCase() != 'nov' && this.paymentMonth.toLowerCase() != 'dec') {
        amount = 200.00
      }
      else {
        amount = 300.00
      }
    }
    else if (this.paymentYear < 2023) {
      amount = 200.00
    }
    else {
      amount = 300.00
    }
      amountArr.push(amount);
    }
    let body = {
      name: this.user.name,
      spinId: this.user.spinId,
      dealerCode: this.user.dealerCode,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      paymentPeriod: paymentPeriodList,
      reasonId: this.reasonId,
      otherReason: this.otherReason,
      amount: amountArr,
      createdBy: this.appService.loggedInUserName.toUpperCase()
    }
    console.log(amountArr,body)
    // if (validateResponse) {
    this.debitService.insertDebitPayment(body).subscribe((response: any) => {
      setTimeout(() => {
        this.closeCreateDebitModal();
        this.userPaymentHistory.forEach(x => x.checked = false);
        $('input[name="payCheckbox"]').attr('checked', false);
      }, 100);

      this.showSpinner = false;
      this.succesMessage = 'Debit payment for ' + this.user.name + ' for the payment period of ' + this.paymentListSelectedStr + ' has been created successfully.'
      this.Success = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(function () {
        this.Success = false;
        this.paymentListSelected = [];
        this.paymentListSelectedStr = '';
      }.bind(this), 9000);
      this.navigateBack();
    }, err => {
      setTimeout(() => {
        this.closeCreateDebitModal();
        this.userPaymentHistory.forEach(x => x.checked = false);
        $('input[name="payCheckbox"]').attr('checked', false);
      }, 100);
      this.paymentListSelected = [];
      this.paymentListSelectedStr = '';
      this.showSpinner = false;
      this.errorMessage = 'Unable to create debit payment. Please try after sometime.'
      this.Error = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    })
    // }
    // else {
    //   this.closeCreateDebitModal();
    //   this.showSpinner = false;
    //   this.errorMessage = 'Please enter all mandatory fields.'
    //   this.Error = true;
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  }

  async validateDebitDetails() {
    if (this.reasonId != '' && this.reasonId != null && this.reasonId != undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  exportBillingTemplate() {
    this.exportDebitErrMsg = '';
    this.showSpinner = true;
    let id = ''
    for (let i = 0; i < this.totalCheckedArrayforApproval.length; i++) {
      id += this.totalCheckedArrayforApproval[i].id;
      if (i != this.totalCheckedArrayforApproval.length - 1) {
        id += ','
      }
    }
    let body = {
      "debitPaymentId": id,
      "exportedBy": this.appService.loggedInUserName
    }
    
    this.debitService.exportDebitPayment(body).subscribe((response: any) => {
      let mailBody = {
        "fileName" : response.fileName
      }
      this.debitService.sendDebitPaymentMail(mailBody).subscribe((response: any) => {
        this.debitService.UpdateDebitPayment(body).subscribe((response: any) => {
          this.totalCheckedArrayforApproval = [];
          this.showSpinner = false;
          this.getDebitsForReviewData();
          this.holdTable = true;
          $("#exportDebitSuccess").modal('show');
          setTimeout(() => {
            $('input[name="exportDebitCheckbox"]').attr('checked', false);
          }, 100);
        }, err => {
          this.showSpinner = false;
          this.totalCheckedArrayforApproval = [];
          this.exportDebitErrMsg = 'Failure! Unable to update the details in DB';
          $("#exportDebitError").modal('show');
          setTimeout(() => {
            $('input[name="exportDebitCheckbox"]').attr('checked', false);
          }, 100);
        })
      }, err => {
        this.showSpinner = false;
        this.totalCheckedArrayforApproval = [];
        this.exportDebitErrMsg = 'Failure! Unable to send an email to the Billing team';
        $("#exportDebitError").modal('show');
        setTimeout(() => {
          $('input[name="exportDebitCheckbox"]').attr('checked', false);
        }, 100);
      })
    }, err => {
      this.showSpinner = false;
      this.totalCheckedArrayforApproval = [];
      this.exportDebitErrMsg = 'Failure! Unable to generate the billing template. Please try after sometime';
      $("#exportDebitError").modal('show');
      setTimeout(() => {
        $('input[name="exportDebitCheckbox"]').attr('checked', false);
      }, 100);
    })

  }

  // ==============================EXPORT EXCEL==================================

  exportSummary() {
    this.noExcelData = false;
    if (this.tabName == 'PENDING DEBITS') {
      if (!this.debitNoDataNotExp) {
        this.excelService.exportAsExcelFile(this.debitDataSourceNotExp.data, this.tabName);
      } else {
        this.showDownloadErrorModal();
        this.noExcelData = true;
      }
    }
    else if (this.tabName == 'PROCESSED DEBITS') {
      if (!this.debitNoDataExp) {
        this.excelService.exportAsExcelFile(this.debitDataSourceExp.data, this.tabName);
      } else {
        this.showDownloadErrorModal();
        this.noExcelData = true;
      }
    }
    else if (this.tabName == 'APPROVED DEBITS') {
      if (!this.debitNoDataExp) {
        this.excelService.exportAsExcelFile(this.debitDataSourceApprove.data, this.tabName);
      } else {
        this.showDownloadErrorModal();
        this.noExcelData = true;
      }
    }
    else if (this.tabName == 'APPROVE MODAL') {
      if (this.totalCheckedArrayforApproval.length != 0) {
        let excelApprove = [];
        this.totalCheckedArrayforApproval.forEach((data) => {
          let obj = {

          }
          excelApprove.push(obj);
        });
        this.excelService.exportAsExcelFile(excelApprove, 'Debit Details');
      } else {
        this.showDownloadErrorModal();
      }
    }
  }

  //to trigger email when "approve" is clicked
  async debitApprovalEmail() {
    let excelApprove = [];
    this.totalCheckedArrayforApproval.forEach((data) => {
      let obj = {

      }
      excelApprove.push(obj);
    });
    console.log(excelApprove);
    await this.debitEmailService(excelApprove);
  }
  async debitEmailService(excelApprove) {
    await this.debitService.approveDebit(excelApprove).subscribe((response: any) => {
      console.log(response);
    })
  }

  showDownloadErrorModal() {
    $("#errorExcelModal").removeClass('hide_block');
    $("#errorExcelModal").addClass('display_block');
    $("#errorExcelModal").show("slow");
  }

  closeDownloadErrorModal() {
    $("#errorExcelModal").removeClass('display_block');
    $("#errorExcelModal").addClass('hide_block');
    $("#errorExcelModal").show("slow");
  }
  closeCreateDebitModal() {
    // $("#createDebitModal").removeClass('display_block');
    // $("#createDebitModal").addClass('hide_block');
    $("#createDebitModal").modal('hide');
  }

  onNext() {
    $('html,body').animate({ scrollTop: 0 }, "smooth");
  }

  checkAll() {
    if (!this.checkAllDebitForReview) {
      this.checkAllDebitForReview = true;
      this.debitReviewNotExpChecked = 0;
      console.log('Search ', $('#approveSearch').val());
      console.log('check All Debits data', this.debitDataSourceNotExp.filteredData)
      if ($('#approveSearch').val() != '') {
        for (let i = 0; i < this.debitDataSourceNotExp.filteredData.length; i++) {
          this.debitDataSourceNotExp.filteredData[i].check = true;
          this.debitDataSourceNotExp.filteredData[i].toBeSaved = true;
          this.debitReviewNotExpChecked += 1;
        }
      } else {
        for (let i = 0; i < this.debitDataSourceNotExp.data.length; i++) {
          this.debitDataSourceNotExp.data[i].check = true;
          this.debitDataSourceNotExp.data[i].toBeSaved = true;
          this.debitReviewNotExpChecked += 1;
        }
      }
      this.totalChecked = 0;
      this.totalChecked = this.debitReviewNotExpChecked;
      if (this.totalChecked != 0) {
        // this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        // this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    else {
      this.checkAllDebitForReview = false;
      this.debitReviewNotExpChecked = 0;
      for (let i = 0; i < this.debitDataSourceNotExp.data.length; i++) {
        this.debitDataSourceNotExp.data[i].check = false;
        this.debitDataSourceNotExp.data[i].toBeSaved = true;
      }
      this.totalChecked = 0;
      this.totalChecked = this.debitReviewNotExpChecked;
      if (this.totalChecked != 0) {
        // this.appService.unsavedChanges = true;
        $("#approveModalId").attr('data-badge', this.totalChecked + '');
      } else {
        // this.appService.unsavedChanges = false;
        $("#approveModalId").removeAttr('data-badge');
      }
    }
    this.getAllDebitsFn();
  }

  getAllDebitsFn() {
    let j = 0;
    this.exportApproveTab = true;
    this.exportApproval = false;
    this.totalCheckedArrayforApproval = [];
    this.debitReviewNotExpChecked = 0;
    for (let i = 0; i < this.DEBIT_DATA_NOTEXP.length; i++) {
      if (this.DEBIT_DATA_NOTEXP[i].check) {
        this.totalCheckedArrayforApproval[j] = this.DEBIT_DATA_NOTEXP[i];
        this.totalCheckedArrayforApproval[j].name = this.DEBIT_DATA_NOTEXP[i].name;
        this.totalCheckedArrayforApproval[j].dealerCode = this.DEBIT_DATA_NOTEXP[i].dealerCode;
        this.totalCheckedArrayforApproval[j].spinId = this.DEBIT_DATA_NOTEXP[i].spinId;
        this.totalCheckedArrayforApproval[j].paymentPeriod = this.DEBIT_DATA_NOTEXP[i].paymentPeriod;
        this.totalCheckedArrayforApproval[j].debitDate = this.DEBIT_DATA_NOTEXP[i].debitDate;
        this.totalCheckedArrayforApproval[j].remarks = this.DEBIT_DATA_NOTEXP[i].remarks;
        this.totalCheckedArrayforApproval[j].amount = this.DEBIT_DATA_NOTEXP[i].amount;
        this.totalCheckedArrayforApproval[j].id = this.DEBIT_DATA_NOTEXP[i].debit_payment_id;
        j += 1;
        this.debitReviewNotExpChecked += 1;
      }
    }

    this.totalChecked = this.totalCheckedArrayforApproval.length;
    console.log('totalChecked=', this.totalChecked + ' debitReviewNotExpChecked=', this.debitReviewNotExpChecked);
    if (this.totalChecked != 0) {
      $("#approveModalId").attr('data-badge', this.totalChecked + '');
    } else {
      $("#approveModalId").removeAttr('data-badge');
    }
    // if (this.debitReviewNotExpChecked > 0) {
    //   // this.appService.unsavedChanges = true;
    // }
    // else {
    //   // this.appService.unsavedChanges = false;
    // }
  }

  async showApproveModal() {
    this.showSpinner = false;
    $('#ApproveModal').modal('show');
  }
  async showCreateDebitModal() {
    this.showSpinner = false;
    $('#createDebitModal').modal('show');
  }

  getCheckedCount(index, row) {
    this.checkedArray.push(row);
    this.DEBIT_DATA_NOTEXP.forEach(function (debitData) {
      if (debitData.paymentPeriod == row.paymentPeriod && debitData.spinId == row.spinId && debitData.debitDate == row.debitDate && debitData.dealerCode == row.dealerCode) {
        debitData.check = row.check;
        debitData.toBeSaved = !debitData.toBeSaved;
      }
    });
    this.getAllDebitsFn();
    this.totalCheckedSaved = $(":checkbox:checked").length;
    // // console.log('Index', index);
    // if (this.selection.indexOf(row.name) !== -1) {
    //   this.selection.splice(this.selection.indexOf(row.name), 1);
    // } else {
    //   this.selection.push(row.name);
    // }
  }
  checkAllCheckBox(ev: any) {
    this.userPaymentHistory.forEach(x => x.checked = ev.target.checked)
    if (ev.target.checked) {
      this.allCheckBoxChecked = true
      const history = this.userPaymentHistory.filter((element) => {
        return element.nocheck != true;
      })
      this.paymentListSelected = history;
    }
    else {
      this.allCheckBoxChecked = false;
      this.paymentListSelected = [];
    }
    this.paymentListSelected.forEach((element) => {
      if (this.paymentListSelectedStr.length > 0) {
        this.paymentListSelectedStr += ','
      }
      this.paymentListSelectedStr += element.payDueCd

    })
  }

  isAllCheckBoxChecked() {
    return this.userPaymentHistory.every(p => p.checked);
  }

  selectPayment(event, user, payCd) {
    this.paymentListSelectedStr = ''
    if (event.target.checked) {
      this.paymentListSelected.push(user);
    }
    else {
      const found = this.paymentListSelected.filter((element) => {
        return element.payDueCd != payCd
      })
      this.paymentListSelected = found
    }
    this.paymentListSelected.forEach((element) => {
      if (this.paymentListSelectedStr.length > 0) {
        this.paymentListSelectedStr += ','
      }
      this.paymentListSelectedStr += element.payDueCd

    })
  }
  cancelCreateDebit() {
    this.reasonId = '';
    this.otherReason = '';
    this.paymentListSelected = [];
    this.paymentListSelectedStr = '';
    $('input[name="payCheckbox"]').attr('checked', false);
    this.userPaymentHistory.forEach(x => x.checked = false);
  }
  closeErrorModal() {
    $("#exportDebitSuccess").modal('hide');
  }
}
