import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule } from "@angular/material";
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../service/common.service';
import { ExcelService } from '../service/excel.service';
import { BillingService } from 'src/app/billing/billing.service';
import { NotificationService } from '../notification/notification.service'
import { AppComponent } from '../app.component';
import * as  Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import HighMaps from 'highcharts/highmaps'
import * as more from 'highcharts/highcharts-more.src';
import { ReportService } from 'src/app/reports/reports.service';
Drilldown(Highcharts);
more(Highcharts);
import { PlatformLocation, Location } from '@angular/common';
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
declare var require: any;
import * as moment from 'moment';
import 'moment-timezone';
declare var $: any;


Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

function reportValidator(fg: FormGroup) {
  const spinId = fg.get('spinId').value;
  const month = fg.get('month').value;
  const period = fg.get('period').value;
  const areaOrDistrict = fg.get('areaOrDistrict').value;
  const course = fg.get('course').value;
  const invoiceNumber = fg.get('invoiceNumber').value;
  const dealerNumber = fg.get('dealerNumber').value;
  const attendance = fg.get('attendance').value;
  const facility = fg.get('facility').value;
  return spinId || month || period || areaOrDistrict || course || invoiceNumber || dealerNumber || attendance || facility
    ? null
    : { range: true };
}

function exceptionPayValidator(fg: FormGroup) {
  const paymentType = fg.get('paymentType').value;
  const dealerCode = fg.get('dealerCode').value;
  const course = fg.get('course').value;
  const courseShortName = fg.get('courseShortName').value;
  const startDate = fg.get('startDate').value;
  const facility = fg.get('facility').value;
  const status = fg.get('status').value;
  const credit = fg.get('credit').value;
  return paymentType && dealerCode && course && courseShortName && startDate && facility && status && credit
    ? null
    : { range: true };
}


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BillingComponent implements OnInit {

  dash: boolean = true
  loadSpinner: boolean;
  dropdown: boolean = false;
  filteredDealers: any[] = [];
  isLoading = false;
  noFirstData: boolean = false;

  //chart vars
  chart: any = {};
  FirstChart: any = {};
  firstChartLoading: boolean = true;
  firstChartData: any = [];
  uniqDlr: any = [];
  uniqAmt: any = [];
  veryUniqDlr: any = [];
  veryveryUniqDlr: any = [];
  months = [
    {
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "abbreviation": "May",
      "name": "May"
    },
    {
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "abbreviation": "Dec",
      "name": "December"
    }
  ]
  reportMonths = [
    {
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "abbreviation": "May",
      "name": "May"
    },
    {
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "abbreviation": "Dec",
      "name": "December"
    }
  ]
  XAx: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  XAxFiscal: any = [];
  XAxUniq: any = [];
  colors: any = [];
  firstChartGraph: any = {};
  l1; l2; l3; l4;
  firstDealerList: any = [];
  firstAmtList: any = [];
  firstDataMini: any = [];
  firstData: any = [];
  dlrs: any[];
  amts: any = [];
  firstMonthly: any = [];


  //second chart vars
  chart2: any = {};
  SecondChart: any = {};
  secondChartLoading: boolean = true;
  secondChartData: any = [];
  secondChartDataDrilldown: any = [];
  XAx2: any = [];
  secondChartGraph: any = {};
  monthly: any = [];
  noSecondData: boolean = false;
  secondDrilldown1: number = 0;
  secondDrilldown2: number = 0;
  secondDrilldown3: number = 0;
  secondDrilldown4: number = 0;
  secondDrilldown5: number = 0;
  secondDrilldown6: number = 0;
  monthlyDrill: any = [];
  secondChartDrilldownXAx = ['Weel 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  secondMainFinal: any = [];

  //third chart vars
  chart3: any = {};
  ThirdChart: any = {};
  thirdChartLoading: boolean = true;
  thirdChartData: any = [];
  XAx3: any = [];
  thirdChartGraph: any = {};
  courses: any = [];
  coursesUniq: any = [];
  thirdAmt: any = [];
  thirdAmtUniq: any = [];
  cr1; cr2; cr3; cr4;
  noThirdData: boolean = false;
  thirdVeryUniqDlr: any = [];
  thirdVVUniqCour: any = [];
  thirdDataMini: any = [];
  thirdUniqCour: any = [];
  thirdData: any = [];

  //fourth chart vars
  chart4: any = {};
  FourthChart: any = {};
  fourthChartLoading: boolean = true;
  fourthChartData: any = [];
  XAx4: any = [];
  fourthChartGraph: any = {};
  fourthDealers: any = [];
  fourthDealerCosts: any = [];
  fourthAmounts: any = [];
  fourthAmounts2: any = [];
  fourthAmounts2yt: any = [];
  noFourthData: boolean = false;

  //fifth chart vars
  chart5: any = {};
  FifthChart: any = {};
  fifthChartLoading: boolean = true;
  fifthChartData: any = [];
  XAx5: any = [];
  fifthChartGraph: any = {};
  fifthCourses: any = [];
  fifthCosts: any = [];
  fifthDealerCode: any = [];
  fifthDrilldownData: any[];
  fifthDrillDealers: any = [];
  fifthDrillCosts: any = [];
  fifthDrillDealersMini: any = [];
  fifthDrillCostsMini: any = [];
  noFifthData: boolean = false;
  sub51; sub52; sub53; sub54; sub55; sub56; sub57; sub58;
  fifthData: any = [];
  fifthDataDrill: any = [];
  fifthDataDrillMain: any = [];
  fifthMainCount: number;
  fifthDataDrill2: any = [];

  filterPeriod: any = 1;
  //sixth chart vars
  chart6: any = {};
  SixthChart: any = {};
  sixthChartLoading: boolean = true;
  sixthChartData: any = [];
  XAx6: any = [];
  sixthChartGraph: any = {};
  sixthPresent: any = [];
  sixthPercents: any = [];
  noSixthData: boolean = false;
  sixthDataDrillComp: any = [];
  sixthDataDrillNoshow: any = [];
  sixthNewDrill: any = [];
  sixthNewDrill2: any = [];

  //sevents chart vars
  chart7: any = {};
  SeventhChart: any = {};
  seventhChartLoading: boolean = true;
  seventhChartData: any = [];
  seventhChartGraph: any = {};
  seventhPresent: any = [];
  seventhPercents: any = [];
  noSeventhData: boolean = false;
  seventhDrillComp: any = [];
  seventhDrillNoShow: any = [];
  seventhDrillCoursesComp: any = [];
  seventhDrillCoursesNosho: any = [];
  seventhDrillCompNew: any = [];
  seventhDrillNoshoNew: any = [];
  seventhNewDrill: any = [];
  seventhDrill: any = [];
  seventhNewDrill2: any = [];

  //eighth chart vars
  chart8: any = {};
  EighthChart: any = {};
  eighthChartLoading: boolean = true;
  eighthChartData: any = [];
  eighthChartGraph: any = {};
  eighthArea: any = [];
  eighthAmount: any = [];
  noEightData: boolean = false;
  eighthDataDrill: any = [];
  eighthDataDrill2: any = [];

  //nineth chart
  chart9: any = {};
  NinethChart: any = {};
  ninethChartLoading: boolean = true;
  ninethChartData: any = [];
  ninethChartGraph: any = {};
  ninethDealers: any = [];
  ninethCompleted: any = [];
  ninethNoShow: any = [];
  ninethShowOnGraph = [];
  ninethShowOnGraphYt = [];
  noNinethData: boolean = false;

  //areaTrendYear
  areaTrendChartLoading: boolean = true;
  areaTrendChartTotalAmt;
  areaTrendChartGraph: any = {};
  areaTrendChartArea: any = [];
  areaTrendChartAmounts: any = [];
  areaTrendChartDrill: any = [];
  areaTrendChartDrill2: any = [];
  noAreaTrendChartData: boolean = false;

  //test chart vars
  lfsExpireChart: any = {};
  LfsExpiryDownloadLoading: boolean;
  showLfs: boolean = false;
  lfsFilterData: string = '';
  lfsExpiryResponse: any = '';
  lfsExpiryCategory: any = [];
  oneLfs_1: number = 0; oneLfs_2: number = 0; oneLfs_3: number = 0; oneLfs_4: number = 0;
  oneLfs_5: number = 0; oneLfs_6: number = 0;
  lfsExpiryData: any = [];
  downloadLfsExpiryResponse: any = [];
  LEJson: any = [];
  LfsExpiryChartLoading: boolean;
  noLfsData: boolean = false;
  noLfsDownloadData: boolean = false;
  @ViewChild("lfsExpChart", { read: ElementRef }) lfsExpChart: ElementRef;
  @ViewChild("expandLfsChart", { read: ElementRef }) expandLfsChart: ElementRef;

  @ViewChild("container", { read: ElementRef }) container: ElementRef;
  @ViewChild("fourth", { read: ElementRef }) fourth: ElementRef;
  @ViewChild("fifth", { read: ElementRef }) fifth: ElementRef;
  mon: any;
  mon3: any;
  mon2: any;

  trendFilterData: any = '';
  trendFilterSevenData: any = '';
  trendFilterNineData: any = '';
  chartMonthFilter = '';
  eightChartMonthFilter = '';
  fifthChartMonthFilter = '';
  fourthChartMonthFilter = '';
  sixthChartMonthFilter = '';
  sevenChartMonthFilter = '';
  courseTableFilter = '';
  ninethChartMonthFilter = '';
  trendFilterYearData = '2023'
  chartFiscalYearFilter = ''
  dummy5: any;
  coursesList: any = [];
  dealersList: any = [];
  searchForm: FormGroup;
  exceptionPayForm: FormGroup
  reportArray: any = [];
  coursesArr: any = [];
  reportDataSource: MatTableDataSource<any>;
  courseDataSource: MatTableDataSource<any>;
  reportDataColumns: string[] = ["course", "startDate", "completionDate", "name", "spinId", "status", "dealer", "classNo", "facility", "amount"];
  coursesColumns: string[] = ["title", "sessions", "attendees", "amount"]
  tableLoading = true;
  coursesTableLoading = true;
  showSpinner = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('ReportPaginator', { read: MatPaginator }) ReportPaginator: MatPaginator;
  @ViewChild('ExceptionPayPaginator', { read: MatPaginator }) ExceptionPayPaginator: MatPaginator;
  @ViewChild('CoursesPaginator', { read: MatPaginator }) CoursesPaginator: MatPaginator;
  exportData: any = [];
  filterSelected = false;
  currentMonth: any;
  currentMonthDay: any;
  currentYear: any;
  prevMonth = false;
  expandState = true;
  secondDrillDummy: any;
  showExceptionPay = false;
  createException = false;
  exceptionPayArr: any = [];
  exceptionPayDataSource: MatTableDataSource<any>;
  exceptionPayDataColumns: string[] = ["paymentType", "dealer", "course", "status", "startDate", "completionDate", "facility", "name", "spinId", "amount"];
  exceptionPayTableLoading = true;
  showExceptionPaySpinner = false;
  exceptionPayExportData = [];
  secondNonZero: number;
  eighthDrill: any[];
  eightTotalAmount = 0;
  exportChartName = '';
  filterType = 'month';
  chartAreaFilter = 'All';
  areaArr = [{ "name": "ALL", "value": "All" }, { "name": "SOUTHERN AREA", "value": "AREA SOUTH" },
  { "name": "EASTERN AREA", "value": "AREA EAST" },
  { "name": "WESTERN AREA", "value": "AREA WEST" },
  { "name": "CENTRAL AREA", "value": "CENTRAL AREA" }]
  bum = [];
  yearType = 'normal';
  monthlyFisc: number[];
  secondDrillFinal: any = [];
  fifthAmounts2: any = [];
  fifthAmounts: any;
  fifthAmounts2yt: any = [];
  fourthTotalAmnt = 0;
  fifthTotalAmnt = 0;
  ninethTotalCount = 0;
  seventhTotalCount = 0;
  sixthTotalCount = 0;
  secondChartTotalAmnt = 0;
  year = '2023';
  areaDealerTrendYearArr = [];
  areaDealerTrendMonArr = [];
  yearList = [{ "name": "2023", "value": "2023" }];
  fiscalYearList = [{ "name": "Apr 2023 - Mar 2024", "value": "2023-2024" }];
  showErrMsg = '';
  courseList = []
  Error: boolean;
  //  ================================ CONSTRUCTOR METHOD ==================================
  constructor(private fb: FormBuilder, private appService: CommonService, private excelService: ExcelService, private corpHoldService: BillingService,
    private appComponent: AppComponent, private location: PlatformLocation, private route: ActivatedRoute,
    private router: Router, private _location: Location, public el: ElementRef, public reportService: ReportService,
    private billingService: BillingService) {
      this.searchForm = this.fb.group({
        spinId: '',
        month: '',
        period: '',
        areaOrDistrict: '',
        course: '',
        invoiceNumber: '',
        dealerNumber: '',
        attendance: '',
        facility: 'Virtual',
        calendarYear: '',
        fiscalYear: ''
      }, { validator: reportValidator });
      this.exceptionPayForm = this.fb.group({
        paymentType: '',
        dealerCode: '',
        course: '',
        courseShortName: '',
        startDate: '',
        completionDate: '',
        credit: '',
        peopleAttended: '',
        firstName: '',
        lastName: '',
        spinId: '',
        facility: '',
      status: '',
        addedBy: JSON.parse(localStorage.getItem('currentUser')).Spin_ID
      }, { validator: exceptionPayValidator });
    this.reportsDropDownFetch();
    //* scroll top on click of this component on side nav bar
    $('html,body').animate({ scrollTop: -90 }, "fast");
    // $('.tabBut1').addClass('active');
    let date = new Date();
    let day = date.getDate();
    this.currentYear = date.getFullYear();
    this.trendFilterYearData = (date.getMonth() + 1) == 1 ? this.currentYear - 1 : this.currentYear
    if (this.currentYear != this.yearList[0].name) {
      let len = this.currentYear - Number(this.yearList[0].name)
      for (let i = 0; i < len; i++) {
        let prevYr = this.yearList[i].name;
        let obj = {
          "name": (Number(prevYr) + 1).toString(),
          "value": (Number(prevYr) + 1).toString()
        }
        this.yearList.push(obj)
      }
    }
    var fiscalyear = "";
    if ((date.getMonth() + 1) <= 3) {
      fiscalyear = (date.getFullYear() - 1) + "-" + date.getFullYear()
    } else {
      fiscalyear = date.getFullYear() + "-" + (date.getFullYear() + 1)
    }
    if (fiscalyear != this.fiscalYearList[0].value) {
      let len = Number(fiscalyear.split('-')[0]) - Number(this.fiscalYearList[0].value.split('-')[0])
      for (let i = 0; i < len; i++) {
        let prevYr = Number(this.fiscalYearList[i].value.split('-')[0]);
        let obj =
        {
          "name": "Apr "+ (Number(prevYr) + 1) + ' - Mar ' + (Number(prevYr) + 2),
          "value": (Number(prevYr) + 1) + '-' + (Number(prevYr) + 2)
        }
        this.fiscalYearList.push(obj)
      }
    }

    if (day > 5) {
      this.currentMonth = this.XAx[date.getMonth() - 1];
      this.currentMonthDay = '1';
      if (this.XAx[date.getMonth()] == 'January') {
        this.currentMonth = 'December';
        this.prevMonth = true;
      }
    }
    else {
      this.currentMonth = this.XAx[date.getMonth() - 2];
      this.currentMonthDay = '2';
      if (this.XAx[date.getMonth()] == 'January') {
        this.currentMonth = 'November';
        this.prevMonth = true;
      }
      else if (this.XAx[date.getMonth()] == 'February') {
        this.currentMonth = 'December';
        this.prevMonth = true;
      }
    }
    setTimeout(() => {
      this.trendFilterYearData = this.trendFilterYearData.toString();
    },100);
    console.log(this.currentMonth)
    this.chartMonthFilter = this.currentMonth;
    this.eightChartMonthFilter = this.currentMonth;
    this.fourthChartMonthFilter = this.currentMonth;
    this.fifthChartMonthFilter = this.currentMonth;
    this.sixthChartMonthFilter = this.currentMonth;
    this.sevenChartMonthFilter = this.currentMonth;
    this.ninethChartMonthFilter = this.currentMonth;
    this.courseTableFilter = this.currentMonth;
    this.secondChart(this.trendFilterYearData);
    this.areaTrendYear(this.trendFilterYearData);
    this.fourthChart();
    this.fifthChart();
    this.ninethChart(this.currentMonth);
    // this.changefilterPeriod(1);
    this.sixthChart(this.currentMonth);
    this.seventhChart(this.currentMonth)
    this.getCoursesTable(this.currentMonth);
    this.eighthChart();

  }

  // =========================================== ON INIT METHOD ============================================
  ngOnInit() {
    console.log('billing',this.appService.role);
    if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || 
    this.appService.role == 'Billing') {return}
    else {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    
  }

  dashCharts() {
    console.log('in click',this.trendFilterYearData,this.currentMonth)
    this.filterType = 'month'
    this.secondChart(this.trendFilterYearData);
    this.areaTrendYear(this.trendFilterYearData);
    this.fourthChart();
    this.fifthChart();
    this.ninethChart(this.currentMonth);
    this.sixthChart(this.currentMonth);
    this.seventhChart(this.currentMonth);
    this.getCoursesTable(this.currentMonth);
    this.eighthChart();
    localStorage.setItem('chartAreaDrillVal', '')
    localStorage.setItem('chartAreaMonDrillVal', '')
  }

  secondChart(val) {
    this.secondChartLoading = true; this.noSecondData = false;
    var XAxFY = [('April-' + val).toString(), ('May-' + val).toString(), ('June-' + val).toString(), ('July-' + val).toString(), ('August-' + val).toString(), ('September-' + val).toString(), ('October-' + val).toString(), ('November-' + val).toString(), ('December-' + val).toString(), ('January-' + (Number(val) + 1)).toString(), ('February-' + (Number(val) + 1)).toString(), ('March-' + (Number(val) + 1)).toString()];
    this.XAxFiscal = XAxFY;
    this.secondChartTotalAmnt = 0;
    if (this.yearType == 'normal') {
      this.monthly = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //initializing
      this.secondMainFinal = []; this.secondDrillFinal = [];
      this.secondChartTotalAmnt = 0;
      this.billingService.twelveMonthBilling('', val, 'normal').subscribe((response: any) => {
        this.secondChartData = response.BillingAmntPerYear.Result;
        // this.secondChartData = null; //null check
        if (this.secondChartData != undefined && this.secondChartData != null) {
          if (this.secondChartData.length != 0) {
            for (let i = 0; i < this.secondChartData.length; i++) {
              this.mon2 = this.XAx.indexOf(this.secondChartData[i].month);
              this.monthly[this.mon2] = this.secondChartData[i].amount;
            }
            this.secondNonZero = 0
            for (let p = 0; p < this.monthly.length; p++) {
              if (this.monthly[p] !== 0) {
                this.secondChartTotalAmnt += this.monthly[p]
                this.secondNonZero++
              }
            }
            for (let p = 0; p < this.XAx.length; p++) {
              this.secondMainFinal.push({ name: this.XAx[p], y: this.monthly[p], drilldown: this.XAx[p] })
            }
            console.log('2nd main fin norm', this.secondMainFinal);

            this.monthlyDrill = [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]; //initializing
            const secondDrillDummy = [];
            this.billingService.twelveMonthBilling('i', val, 'normal').subscribe((response: any) => {
              // response.BillingAmntPerMonth.Result = null; //null check drill
              if (response.BillingAmntPerMonth.Result != undefined && response.BillingAmntPerMonth.Result != null) {
                if (response.BillingAmntPerMonth.Result.length != 0) {
                  this.noSecondData = false;
                  this.secondChartDataDrilldown = response.BillingAmntPerMonth.Result;
                  for (let x = 0; x < this.XAx.length; x++) {
                    let dummy2nd = [];
                    for (let w = 0; w < this.secondChartDataDrilldown.length; w++) {
                      if (this.XAx[x] == this.secondChartDataDrilldown[w].month) {
                        dummy2nd.push(this.secondChartDataDrilldown[w]);
                      }
                    }
                    secondDrillDummy.push({ ser: this.XAx[x], drill: dummy2nd });
                    dummy2nd = [];
                  }
                  if (secondDrillDummy.length === this.XAx.length) {
                    for (let z = 0; z < secondDrillDummy.length; z++) {
                      const monty = this.XAx.indexOf(secondDrillDummy[z].ser);
                      for (let y = 0; y < secondDrillDummy[z].drill.length; y++) {
                        if (parseInt(secondDrillDummy[z].drill[y].date) > 0 && parseInt(secondDrillDummy[z].drill[y].date) <= 7) {
                          this.monthlyDrill[monty][0] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 7 && parseInt(secondDrillDummy[z].drill[y].date) <= 14) {
                          this.monthlyDrill[monty][1] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 14 && parseInt(secondDrillDummy[z].drill[y].date) <= 21) {
                          this.monthlyDrill[monty][2] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 21 && parseInt(secondDrillDummy[z].drill[y].date) <= 28) {
                          this.monthlyDrill[monty][3] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 28) {
                          this.monthlyDrill[monty][4] += parseInt(secondDrillDummy[z].drill[y].amount);
                        }
                      }
                    }
                    let data = [];
                    for (let z = 0; z < this.XAx.length; z++) {
                      for (let y = 0; y < this.secondChartDrilldownXAx.length; y++) {
                        data.push([this.secondChartDrilldownXAx[y], this.monthlyDrill[z][y]])
                      }
                      this.secondDrillFinal.push({ name: this.XAx[z], id: this.XAx[z], data: data });
                      data = [];
                    }
                    this.secondChartLoading = false;
                    this.secondChartGr(this.yearType);
                  }
                }
                else {
                  this.noSecondData = true;
                  this.secondChartLoading = false;
                  this.secondChartGr('');
                }
              } else {
                this.noSecondData = true;
                this.secondChartLoading = false;
                this.secondChartGr('');
              }
            }, err => {
              console.log('2nd drill normal err', err);
              this.noSecondData = true;
              this.secondChartLoading = false;
              this.secondChartGr('');
            })
          } else {
            this.noSecondData = true;
            this.secondChartLoading = false;
            this.secondChartGr('');
          }
        } else {
          this.noSecondData = true;
          this.secondChartLoading = false;
          this.secondChartGr('');
        }
      }, err => {
        console.log('2nd main normal err', err);
        this.noSecondData = true;
        this.secondChartLoading = false;
        this.secondChartGr('');
      })
    } else if (this.yearType == 'fiscal') {
      this.secondChartTotalAmnt = 0;
      this.monthlyFisc = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //initializing
      this.secondMainFinal = []; this.secondDrillFinal = [];
      this.billingService.twelveMonthBilling('', val, 'fiscal').subscribe((response: any) => {
        this.secondChartData = response.BillingAmntPerYear.Result;
        // this.secondChartData = null; //null check
        if (this.secondChartData != undefined && this.secondChartData != null) {
          if (this.secondChartData.length != 0) {
            for (let i = 0; i < this.secondChartData.length; i++) {
              if (this.secondChartData[i].month == 'April') { this.mon2 = 0 }
              else if (this.secondChartData[i].month == 'May') { this.mon2 = 1 }
              else if (this.secondChartData[i].month == 'June') { this.mon2 = 2 }
              else if (this.secondChartData[i].month == 'July') { this.mon2 = 3 }
              else if (this.secondChartData[i].month == 'August') { this.mon2 = 4 }
              else if (this.secondChartData[i].month == 'September') { this.mon2 = 5 }
              else if (this.secondChartData[i].month == 'October') { this.mon2 = 6 }
              else if (this.secondChartData[i].month == 'November') { this.mon2 = 7 }
              else if (this.secondChartData[i].month == 'December') { this.mon2 = 8 }
              else if (this.secondChartData[i].month == 'January') { this.mon2 = 9 }
              else if (this.secondChartData[i].month == 'February') { this.mon2 = 10 }
              else if (this.secondChartData[i].month == 'March') { this.mon2 = 11 }
              this.monthlyFisc[this.mon2] = this.secondChartData[i].amount;
            }
            this.secondNonZero = 0;
            this.secondChartTotalAmnt = 0;
            for (let p = 0; p < this.monthlyFisc.length; p++) {
              if (this.monthlyFisc[p] !== 0) {
                this.secondChartTotalAmnt += this.monthlyFisc[p];
                this.secondNonZero++;
              }
            }
            for (let p = 0; p < this.XAxFiscal.length; p++) {
              this.secondMainFinal.push({ name: this.XAxFiscal[p], y: this.monthlyFisc[p], drilldown: this.XAxFiscal[p] })
            }
            console.log('2nd main fin fisc', this.secondMainFinal);

            this.monthlyDrill = [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]; //initializing
            const secondDrillDummy = [];
            this.billingService.twelveMonthBilling('i', val, 'fiscal').subscribe((response: any) => {
              if (response.BillingAmntPerMonth.Result != undefined && response.BillingAmntPerMonth.Result != null) {
                if (response.BillingAmntPerMonth.Result.length != 0) {
                  this.noSecondData = false;
                  this.secondChartDataDrilldown = response.BillingAmntPerMonth.Result;
                  for (let x = 0; x < this.XAxFiscal.length; x++) {
                    let dummy2nd = [];
                    for (let w = 0; w < this.secondChartDataDrilldown.length; w++) {
                      if (this.XAxFiscal[x].slice(0, -5) == this.secondChartDataDrilldown[w].month) {
                        dummy2nd.push(this.secondChartDataDrilldown[w]);
                      }
                    }
                    secondDrillDummy.push({ ser: this.XAxFiscal[x], drill: dummy2nd });
                    dummy2nd = [];
                  }
                  if (secondDrillDummy.length === this.XAx.length) {
                    for (let z = 0; z < secondDrillDummy.length; z++) {
                      const monty = this.XAxFiscal.indexOf(secondDrillDummy[z].ser);
                      for (let y = 0; y < secondDrillDummy[z].drill.length; y++) {
                        if (parseInt(secondDrillDummy[z].drill[y].date) > 0 && parseInt(secondDrillDummy[z].drill[y].date) <= 7) {
                          this.monthlyDrill[monty][0] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 7 && parseInt(secondDrillDummy[z].drill[y].date) <= 14) {
                          this.monthlyDrill[monty][1] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 14 && parseInt(secondDrillDummy[z].drill[y].date) <= 21) {
                          this.monthlyDrill[monty][2] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 21 && parseInt(secondDrillDummy[z].drill[y].date) <= 28) {
                          this.monthlyDrill[monty][3] += parseInt(secondDrillDummy[z].drill[y].amount);
                        } else if (parseInt(secondDrillDummy[z].drill[y].date) > 28) {
                          this.monthlyDrill[monty][4] += parseInt(secondDrillDummy[z].drill[y].amount);
                        }
                      }
                    }
                    let data = [];
                    for (let z = 0; z < this.XAxFiscal.length; z++) {
                      for (let y = 0; y < this.secondChartDrilldownXAx.length; y++) {
                        data.push([this.secondChartDrilldownXAx[y], this.monthlyDrill[z][y]])
                      }
                      this.secondDrillFinal.push({ name: this.XAxFiscal[z], id: this.XAxFiscal[z], data: data });
                      data = [];
                    }
                    this.secondChartLoading = false;
                    this.secondChartGr(this.yearType);
                  }
                }
                else {
                  this.noSecondData = true;
                  this.secondChartLoading = false;
                  this.secondChartGr('');
                }
              } else {
                this.noSecondData = true;
                this.secondChartLoading = false;
                this.secondChartGr('');
              }
            }, err => {
              console.log('2nd drill fiscal err', err);
              this.noSecondData = true;
              this.secondChartLoading = false;
              this.secondChartGr('');
            })
          } else {
            this.noSecondData = true;
            this.secondChartLoading = false;
            this.secondChartGr('');
          }
        } else {
          this.noSecondData = true;
          this.secondChartLoading = false;
          this.secondChartGr('');
        }
      }, err => {
        console.log('2nd main fiscal err', err);
        this.noSecondData = true;
        this.secondChartLoading = false;
        this.secondChartGr('');
      })

    }
  }

  secondChartGr(type) {
    let title = this.trendFilterYearData == '' ? 'Fiscal Year ' + this.chartFiscalYearFilter : this.currentYear
    var secondDefaultTitle = 'Rolling 12 months billing per month'
    let XAx = this.XAx; let XAxFisc = this.XAxFiscal; let drillWeeks = this.secondChartDrilldownXAx;
    var secondMainFinal = this.secondMainFinal;
    let yt = this.yearType; console.log('year type in 2nd', this.yearType, yt);

    var secondChartGraph = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'secondChartGr',
        events: {
          load: function (e) {
            if (yt === 'normal') {
              this.addSeries({
                name: 'Monthly amount',
                data: [secondMainFinal[0], secondMainFinal[1], secondMainFinal[2], secondMainFinal[3], secondMainFinal[4], secondMainFinal[5],
                secondMainFinal[6], secondMainFinal[7], secondMainFinal[8], secondMainFinal[9], secondMainFinal[10], secondMainFinal[11]
                ]
              })
            } else if (yt === 'fiscal') {
              this.addSeries({
                name: 'Monthly amount',
                data: [secondMainFinal[0], secondMainFinal[1], secondMainFinal[2], secondMainFinal[3], secondMainFinal[4], secondMainFinal[5],
                secondMainFinal[6], secondMainFinal[7], secondMainFinal[8], secondMainFinal[9], secondMainFinal[10], secondMainFinal[11]
                ],
                color: '#f7a35c'
              })
            }
          },
          drilldown: function (e) {
            secondChartGraph.setTitle({ text: 'Rolling ' + e.point.name + ' month billing amounts per week' });
            secondChartGraph.xAxis[0].setCategories(drillWeeks);
          },
          drillup: function (e) {
            let cat = [];
            if (type === 'normal') { cat = XAx }
            else if (type === 'fiscal') { cat = XAxFisc }
            secondChartGraph.xAxis[0].setCategories(cat);
            secondChartGraph.setTitle({ text: secondDefaultTitle });
            secondChartGraph.xAxis[0].setExtremes(0, 4, true, false);
          }
        }
      },
      title: {
        align: 'center',
        text: secondDefaultTitle,
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: this.trendFilterYearData,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: 'Cost ($)'
        }

      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '$' + this.y;
            },
            style: {
              fontSize: '10px'
            }
          }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>$ {point.y}</b> <br/>'
      },

      series: [],
      drilldown: {
        animation: false,
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        },
        series: [
          this.secondDrillFinal[0], this.secondDrillFinal[1], this.secondDrillFinal[2], this.secondDrillFinal[3], this.secondDrillFinal[4], this.secondDrillFinal[5],
          this.secondDrillFinal[6], this.secondDrillFinal[7], this.secondDrillFinal[8], this.secondDrillFinal[9], this.secondDrillFinal[10], this.secondDrillFinal[11]
        ]
      }
    })
  }

  fourthChart() {
    this.fourthChartLoading = true; this.noFourthData = false;
    this.fourthDealers = [];
    this.fourthDealerCosts = [];
    this.fourthTotalAmnt = 0;
    this.billingService.billingDealersTrendMonth(this.fourthChartMonthFilter, this.trendFilterYearData).subscribe((response: any) => {
      this.fourthChartData = response.DealerTrendPerMonth.Result;
      this.fourthAmounts2 = []; this.fourthAmounts2yt = [];
      // console.log('4th res', this.fourthChartData);
      // this.fourthChartData = null; //null check
      if (this.fourthChartData != undefined && this.fourthChartData != null) {
        if (this.fourthChartData.length != 0) {
          this.noFourthData = false;
          let fourthDlrName = []; let fourthDlrDist = []; let fourthDlrArea = []; let fourthDlrAreaName = [];
          let dummy = [];
          for (let a = 0; a < this.fourthChartData.length; a++) {
            this.fourthDealers.push(this.fourthChartData[a].dealer);
            this.fourthDealerCosts.push(this.fourthChartData[a].amount);
            fourthDlrName.push(this.fourthChartData[a].dealerName);
            fourthDlrArea.push(this.fourthChartData[a].dealerArea);
            fourthDlrDist.push(this.fourthChartData[a].dealerDistrict);
            fourthDlrAreaName.push(this.fourthChartData[a].dealerAreaName);
            this.fourthTotalAmnt += this.fourthChartData[a].amount
          }
          this.fourthAmounts = { y: this.fourthDealerCosts, xtraInfo: [fourthDlrName, fourthDlrDist, fourthDlrArea, fourthDlrAreaName] };
          for (let a = 0; a < this.fourthDealers.length; a++) {
            dummy.push({ y: this.fourthDealerCosts[a], xtraInfo: [fourthDlrName[a], fourthDlrDist[a], fourthDlrArea[a], fourthDlrAreaName[a]] })
          }
          this.fourthAmounts2 = { name: 'Amounts', data: dummy, type: 'column' };
          this.fourthAmounts2yt = { name: 'Amounts', data: dummy, type: 'column', color: '#f7a35c' };
          this.fourthChartLoading = false;
          this.fourthChartGr();
        } else {
          this.fourthAmounts2 = []; this.fourthAmounts2yt = [];
          this.noFourthData = true;
          this.fourthChartLoading = false;
          this.fourthChartGr();
        }
      } else {
        this.fourthAmounts2 = []; this.fourthAmounts2yt = [];
        this.noFourthData = true;
        this.fourthChartLoading = false;
        this.fourthChartGr();
      }
    }, err => {
      this.fourthAmounts2 = []; this.fourthAmounts2yt = [];
      console.log('4th error', err);
      this.noFourthData = true;
      this.fourthChartLoading = false;
      this.fourthChartGr();
    })

  }
  fourthChartGr() {
    const yt = this.yearType;
    const fourthAmounts2 = this.fourthAmounts2; const fourthAmounts2yt = this.fourthAmounts2yt;
    this.fourthChartGraph = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'fourthChartGr',
        inverted: true,
        height: 575,
        events: {
          load: function () {
            if (yt === 'normal') {
              this.addSeries(fourthAmounts2)
            } else if (yt === 'fiscal') {
              this.addSeries(fourthAmounts2yt)
            }
          }
        }
      },
      title: {
        text: 'Billing trend across Dealers',
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: 'For ' + this.fourthChartMonthFilter + ' ' + this.trendFilterYearData + '',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.fourthDealers,
        title: {
          text: 'Dealers'
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormatter: function () {
          return 'Dealer Name: <b>' + this.xtraInfo[0] + '</b> <br> Area: <b>' + this.xtraInfo[3] + '</b> <br> District: <b>' + this.xtraInfo[1] + '</b> <br> Amount: <b> $' + this.y + '</b>';
        }
      },
      plotOptions: {
        // column: {
        //   dataLabels: {
        //     enabled: true,
        //     align: 'right',
        //     inside: false,
        //     formatter: function () {
        //       return '$ ' + this.y;
        //     },
        //     style: {
        //       fontSize: '8px',
        //       fontWeight: 'bold'
        //     },
        //     overflow: 'none'
        //   }
        // }
      },
      series: []
    });

  }

  fifthChart() {
    this.fifthChartLoading = true; this.noFifthData = false;
    this.fifthCourses = [];
    this.fifthCosts = [];
    this.fifthTotalAmnt = 0;
    this.billingService.coursesDealersTrend(this.fifthChartMonthFilter, '', this.trendFilterYearData).subscribe((response: any) => {
      this.fifthChartData = response.CourseTrendPerMonth.Result;
      this.fifthAmounts2 = []; this.fifthAmounts2yt = [];
      // this.fifthChartData = null; //null check
      if (this.fifthChartData != undefined && this.fifthChartData != null) {
        if (this.fifthChartData.length != 0) {
          this.noFifthData = false;
          let fifthDlrName = [];
          let dummy = [];
          for (let a = 0; a < this.fifthChartData.length; a++) {
            this.fifthCourses.push(this.fifthChartData[a].course);
            this.fifthCosts.push(this.fifthChartData[a].amount);
            fifthDlrName.push(this.fifthChartData[a].courseFullName);
            this.fifthTotalAmnt += this.fifthChartData[a].amount
          }
          this.fifthAmounts = { y: this.fifthCosts, xtraInfo: fifthDlrName };
          for (let a = 0; a < this.fifthCourses.length; a++) {
            dummy.push({ y: this.fifthCosts[a], xtraInfo: fifthDlrName[a] });
          }
          this.fifthAmounts2 = { name: 'Amounts', data: dummy, type: 'column' };
          this.fifthAmounts2yt = { name: 'Amounts', data: dummy, type: 'column', color: '#f7a35c' };
          this.fifthChartLoading = false;
          this.fifthChartGr();
        } else {
          this.fifthAmounts2 = []; this.fifthAmounts2yt = [];
          this.noFifthData = true;
          this.fifthChartLoading = false;
          this.fifthChartGr();
        }
      } else {
        this.fifthAmounts2 = []; this.fifthAmounts2yt = [];
        this.noFifthData = true;
        this.fifthChartLoading = false;
        this.fifthChartGr();
      }
    }, err => {
      this.fifthAmounts2 = []; this.fifthAmounts2yt = [];
      console.log('5th error', err);
      this.noFifthData = true;
      this.fifthChartLoading = false;
      this.fifthChartGr();
    })

  }
  fifthChartGr() {
    const yt = this.yearType;
    const fifthAmounts2 = this.fifthAmounts2; const fifthAmounts2yt = this.fifthAmounts2yt;
    this.fifthChartGraph = new Highcharts.Chart({
      chart: {
        type: 'column',
        renderTo: 'fifthChartGr',
        inverted: true,
        events: {
          load: function () {
            if (yt === 'normal') {
              this.addSeries(fifthAmounts2);
            } else if (yt === 'fiscal') {
              this.addSeries(fifthAmounts2yt);
            }
          }
        }
      },
      title: {
        text: 'Billing trend across Courses',
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: 'For ' + this.chartMonthFilter + ' ' + this.trendFilterYearData + '',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.fifthCourses,
        title: {
          text: 'Courses'
        },
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Cost ($)'
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        pointFormatter: function () {
          return 'Course Name: <b>' + this.xtraInfo + '</b> <br> Amount: <b> $' + this.y + '</b>';
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '$ ' + this.y;
            }
          }
        }
      },
      series: []
    });

  }

  ninethChart(filter) {
    this.ninethDealers = [];
    this.ninethCompleted = [];
    this.ninethNoShow = [];
    this.ninethChartLoading = true; this.noNinethData = false;
    this.ninethTotalCount = 0;
    this.ninethShowOnGraph = []; this.ninethShowOnGraphYt = [];
    this.billingService.dealersOverallAttendance(filter, this.filterType, this.chartAreaFilter, this.trendFilterYearData, '').subscribe((res: any) => {
      this.ninethChartData = res.DealersAttendanceOvrAllCnt.Result;
      // this.ninethChartData = null; //null check
      if (this.ninethChartData != undefined && this.ninethChartData != null) {
        if (this.ninethChartData.length != 0) {
          this.noNinethData = false;
          let ninethDlrName = []; let ninethDlrArea = []; let ninethDlrLoc = []; let ninethDlrDist = [];
          for (let a = 0; a < this.ninethChartData.length; a++) {
            this.ninethDealers.push(this.ninethChartData[a].dealer);
            ninethDlrName.push(this.ninethChartData[a].dealerName);
            ninethDlrArea.push(this.ninethChartData[a].dealerAreaName);
            ninethDlrDist.push(this.ninethChartData[a].dealerDistrict);
            if (this.ninethChartData[a].dealerCity != null && this.ninethChartData[a].dealerCity.length != 0) {
              ninethDlrLoc.push(this.ninethChartData[a].dealerCity.trim() + ', ' + this.ninethChartData[a].dealerState);
            } else { ninethDlrLoc.push('') }
            this.ninethCompleted.push(this.ninethChartData[a].completedCount);
            this.ninethNoShow.push(this.ninethChartData[a].noShowCount);
            this.ninethTotalCount += this.ninethChartData[a].completedCount;
            this.ninethTotalCount += this.ninethChartData[a].noShowCount
          }
          //console.log(ninethDlrName, ninethDlrArea, ninethDlrLoc)
          const dummy = []; const dummyYt = [];
          dummy.push({ name: 'Completed', data: this.ninethCompleted, color: '#7cb5ec', extraInfo: [this.ninethDealers, ninethDlrName, ninethDlrArea, ninethDlrDist, ninethDlrLoc] });
          dummy.push({ name: 'No Show', data: this.ninethNoShow, color: '#3f3f3f', extraInfo: [this.ninethDealers, ninethDlrName, ninethDlrArea, ninethDlrDist, ninethDlrLoc] });
          this.ninethShowOnGraph = dummy;
          dummyYt.push({ name: 'Completed', data: this.ninethCompleted, color: '#f7a35c', extraInfo: [this.ninethDealers, ninethDlrName, ninethDlrArea, ninethDlrDist, ninethDlrLoc] });
          dummyYt.push({ name: 'No Show', data: this.ninethNoShow, color: '#3f3f3f', extraInfo: [this.ninethDealers, ninethDlrName, ninethDlrArea, ninethDlrDist, ninethDlrLoc] });
          this.ninethShowOnGraphYt = dummyYt;

          this.ninethChartLoading = false;
          this.ninethChartGr();
        } else {
          this.ninethShowOnGraph = []; this.ninethShowOnGraphYt = [];
          this.noNinethData = true;
          this.ninethChartLoading = false;
          this.ninethChartGr();
        }
      } else {
        this.ninethShowOnGraph = []; this.ninethShowOnGraphYt = [];
        this.noNinethData = true;
        this.ninethChartLoading = false;
        this.ninethChartGr();
      }
    }, err => {
      console.log('9th err', err);
      this.ninethShowOnGraph = []; this.ninethShowOnGraphYt = [];
      this.noNinethData = true;
      this.ninethChartLoading = false;
      this.ninethChartGr();
    })
    if (this.filterPeriod == '') {
      this.filterPeriod = '';
    }
    else {
      this.filterPeriod = filter == '2' ? 'last 1' : 'last ' + filter;
    }
  }
  ninethChartGr() {
    const yt = this.yearType;
    const ninethShowOnGraph = this.ninethShowOnGraph; const ninethShowOnGraphYt = this.ninethShowOnGraphYt;
    let month = '';
    let date = new Date();
    let day = date.getDay()
    let test = '';
    let count = 0;
    let prevYear = date.getFullYear() - 1;
    // console.log('filterPeriod', this.filterPeriod)
    if (this.filterPeriod == 'last 1') {
      month = this.prevMonth ? this.currentMonth + ' ' + prevYear : this.currentMonth + ' ' + this.trendFilterYearData
    }
    else if (this.filterPeriod == 'last 3') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 3];
        count = Math.abs(date.getMonth() - 3)

      }
      else {
        test = this.XAx[date.getMonth() - 4];
        count = Math.abs(date.getMonth() - 4)

      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.trendFilterYearData : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else if (this.filterPeriod == 'last 6') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 6];
        count = Math.abs(date.getMonth() - 6)

      }
      else {
        test = this.XAx[date.getMonth() - 7];
        count = Math.abs(date.getMonth() - 7)
      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.currentYear : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else if (this.filterPeriod == 'last 12') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 12];
        count = Math.abs(date.getMonth() - 12)

      }
      else {
        test = this.XAx[date.getMonth() - 13];
        count = Math.abs(date.getMonth() - 13)
      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.currentYear : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else {
      this.filterPeriod = '';
    }
    let text = this.filterPeriod == '' ? this.ninethChartMonthFilter + ' ' + this.trendFilterYearData : this.filterPeriod + ' month(s)'
    var ninethChartGraph = Highcharts.chart({
      chart: {
        type: 'column',
        renderTo: 'ninethChartGr',
        inverted: true,
        height: 600,
        events: {
          load: function () {
            if (yt === 'normal') {
              this.addSeries(ninethShowOnGraph[0]); this.addSeries(ninethShowOnGraph[1]);
            } else if (yt === 'fiscal') {
              this.addSeries(ninethShowOnGraphYt[0]); this.addSeries(ninethShowOnGraphYt[1]);
            }
          }
        }
      },
      title: {
        text: 'Attendance count of each Dealer for ' + text,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: month,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: this.ninethDealers,
        title: {
          text: 'Dealers'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'No of People'
        },
        stackLabels: {
          enabled: true,
          style: { fontSize: '9px' }
        },
        allowDecimals: false
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        x: 25,
        y: 0,
        floating: false,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter: function (this) {
          const ind = this.series.userOptions.extraInfo[0].indexOf(this.x);
          return '<b>' + this.x + '</b> <br> Dealer Name: <b>' + this.series.userOptions.extraInfo[1][ind] + '</b> <br> Dealer Area: <b>' + this.series.userOptions.extraInfo[2][ind] + '</b> <br> Dealer District: <b>' + this.series.userOptions.extraInfo[3][ind] + '</b> <br> Dealer Location: <b>' + this.series.userOptions.extraInfo[4][ind] + '</b> <br>' + this.series.name + ': <b>' + this.y + '</b>';
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            formatter: function () {
              return this.y;
            },
            style: { fontSize: '7px' }
          }
        }
      },
      series: []
    });

  }

  areaTrendYear(val) {
    this.areaTrendChartArea = []; this.areaTrendChartAmounts = []; this.areaTrendChartTotalAmt = 0;
    this.areaDealerTrendYearArr = [];
    this.areaTrendChartLoading = true; this.noAreaTrendChartData = false;
    this.billingService.areaTrendYear(val, this.yearType).subscribe((res: any) => {
      let areaTrendYearRes = res.AreaTrendYear.Result;
      // areaTrendYearRes = null; //null check
      if (areaTrendYearRes != undefined && areaTrendYearRes != null) {
        if (areaTrendYearRes.length != 0) {
          this.areaTrendChartArea = []; this.areaTrendChartAmounts = [];
          for (let a = 0; a < areaTrendYearRes.length; a++) {
            this.areaTrendChartArea.push(areaTrendYearRes[a].area);
            this.areaTrendChartAmounts.push(areaTrendYearRes[a].amount);
            this.areaTrendChartTotalAmt += areaTrendYearRes[a].amount
          }
          let atyDealer = []; let atyDealerName = []; let atyDealerAmt = []; let atyDealerDist = []; let atyArea = [];
          this.areaTrendChartDrill = []; this.areaTrendChartDrill2 = [];
          const atyDrill = Array.from({ length: areaTrendYearRes.length }, (_v, _i) => []);
          this.billingService.areaDealerTrendYear(val, this.yearType).subscribe((res1: any) => {
            let areaTrendYearDrillRes = res1.AreaDealersTrendYear.Result;
            this.areaDealerTrendYearArr = res1.AreaDealersTrendYear.Result;
            // areaTrendYearDrillRes = null; //null check drill
            if (areaTrendYearDrillRes != undefined && areaTrendYearDrillRes != null) {
              if (areaTrendYearDrillRes.length != 0) {
                this.noAreaTrendChartData = false;
                for (let z = 0; z < areaTrendYearDrillRes.length; z++) {
                  for (let y = 0; y < this.areaTrendChartArea.length; y++) {
                    if (areaTrendYearDrillRes[z].area == this.areaTrendChartArea[y]) {
                      atyDrill[y].push(areaTrendYearDrillRes[z]);
                    }
                  }
                }
                for (let x = 0; x < atyDrill.length; x++) {
                  for (let w = 0; w < atyDrill[x].length; w++) {
                    atyDealer.push(atyDrill[x][w].organisation);
                    atyDealerName.push(atyDrill[x][w].dealerName);
                    atyDealerAmt.push(atyDrill[x][w].amount);
                    atyDealerDist.push(atyDrill[x][w].dealerDistrict);
                    atyArea.push(atyDrill[x][w].area);
                  }
                  let dumdum = []; let dum = [];
                  for (let d = 0; d < atyDealer.length; d++) {
                    dumdum.push([atyDealer[d], atyDealerAmt[d]]);
                    dum.push({ y: atyDealerAmt[d], extraInfo: [atyDealerName[d], atyArea[d], atyDealerDist[d]] })
                  }
                  this.areaTrendChartDrill.push({ name: this.areaTrendChartArea[x], id: this.areaTrendChartArea[x], data: dumdum, type: 'column' });
                  this.areaTrendChartDrill2.push({ name: this.areaTrendChartArea[x], id: this.areaTrendChartArea[x], data: dum, type: 'column' });
                  atyDealer = []; atyDealerName = []; atyDealerAmt = []; atyDealerDist = []; atyArea = [];
                  // console.log('area y eq or not', this.areaTrendChartArea.length, this.areaTrendChartDrill.length, this.areaTrendChartArea.length == this.areaTrendChartDrill.length)
                  if (this.areaTrendChartArea.length == this.areaTrendChartDrill.length) {
                    this.areaTrendChartLoading = false;
                    this.areaTrendYearGr(this.areaTrendChartDrill, this.areaTrendChartDrill2);
                  }
                }
              } else {
                this.noAreaTrendChartData = true;
                this.areaTrendChartLoading = false;
                this.areaTrendYearGr('', '');
              }
            } else {
              this.noAreaTrendChartData = true;
              this.areaTrendChartLoading = false;
              this.areaTrendYearGr('', '');
            }
          }, err => {
            console.log('area trend year drill err', err);
            this.noAreaTrendChartData = true;
            this.areaTrendChartLoading = false;
            this.areaTrendYearGr('', '');
          })
        } else {
          this.noAreaTrendChartData = true;
          this.areaTrendChartLoading = false;
          this.areaTrendYearGr('', '');
        }
      } else {
        this.noAreaTrendChartData = true;
        this.areaTrendChartLoading = false;
        this.areaTrendYearGr('', '');
      }
    }, err => {
      console.log('area trend year main err', err);
      this.noAreaTrendChartData = true;
      this.areaTrendChartLoading = false;
      this.areaTrendYearGr('', '');
    })
  }
  areaTrendYearGr(smth, smth2) {
    let title = this.trendFilterYearData == '' ? 'Fiscal Year ' + this.chartFiscalYearFilter : this.trendFilterYearData
    let mainTitle = 'Area billing trend for ' + title
    var UNDF;
    var newCat = [];
    let areaTrendChartArea = this.areaTrendChartArea; let areaTrendChartAmounts = this.areaTrendChartAmounts;
    let centD = []; let eastD = []; let westD = []; let southD = []; let otherD = [];
    for (let a = 0; a < smth2.length; a++) {
      if (smth2[a].id == 'CENTRAL AREA') { centD = smth2[a].data }
      else if (smth2[a].id == 'EASTERN AREA') { eastD = smth2[a].data }
      else if (smth2[a].id == 'WESTERN AREA') { westD = smth2[a].data }
      else if (smth2[a].id == 'SOUTHERN AREA') { southD = smth2[a].data }
      else if (smth2[a].id == 'OTHER') { otherD = smth2[a].data }
    }
    var areaTrendYearGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'areaTrendYrChartGr',
        events: {
          load: function () {
            if (smth.length == 4) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: areaTrendChartArea[0], y: areaTrendChartAmounts[0], drilldown: areaTrendChartArea[0] },
                { name: areaTrendChartArea[1], y: areaTrendChartAmounts[1], drilldown: areaTrendChartArea[1] },
                { name: areaTrendChartArea[2], y: areaTrendChartAmounts[2], drilldown: areaTrendChartArea[2] },
                { name: areaTrendChartArea[3], y: areaTrendChartAmounts[3], drilldown: areaTrendChartArea[3] }]
              })
            } else if (smth.length == 5) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: areaTrendChartArea[0], y: areaTrendChartAmounts[0], drilldown: areaTrendChartArea[0] },
                { name: areaTrendChartArea[1], y: areaTrendChartAmounts[1], drilldown: areaTrendChartArea[1] },
                { name: areaTrendChartArea[2], y: areaTrendChartAmounts[2], drilldown: areaTrendChartArea[2] },
                { name: areaTrendChartArea[3], y: areaTrendChartAmounts[3], drilldown: areaTrendChartArea[3] },
                { name: areaTrendChartArea[4], y: areaTrendChartAmounts[4], drilldown: areaTrendChartArea[4] }]
              })
            }
          },
          drilldown: function (e) {
            let drillName = e.point.name;
            localStorage.setItem('chartAreaDrillVal', e.point.name)
            newCat = [];
            for (let i = 0; i < smth.length; i++) {
              if (smth[i].name == e.point.name) {
                for (let j = 0; j < smth[i].data.length; j++) {
                  newCat.push(smth[i].data[j][0])
                }
              }
            }

            if (smth.length == 4) {
              var chart = this,
                drilldowns1 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                }
              let series = drilldowns1[e.point.name];
              areaTrendYearGraph.addSeriesAsDrilldown(e.point, series);

            } else if (smth.length == 5) {
              var chart = this,
                drilldowns2 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                  'OTHER': { name: 'OTHERS', id: 'OTHER', data: otherD, type: 'column' }
                }
              let series = drilldowns2[e.point.name];
              areaTrendYearGraph.addSeriesAsDrilldown(e.point, series);
            }
            areaTrendYearGraph.xAxis[0].setCategories(newCat);
            areaTrendYearGraph.xAxis[0].update({ title: { text: 'Dealers' } });
            areaTrendYearGraph.yAxis[0].update({ title: { text: 'Cost ($)' } });
            areaTrendYearGraph.xAxis[0].update({ lineWidth: 1 }, false);
            areaTrendYearGraph.setTitle({ text: 'Billing trend of ' + drillName + ' for ' + title });
            areaTrendYearGraph.redraw();
          },
          drillup: function (e) {
            localStorage.setItem('chartAreaDrillVal', '')
            areaTrendYearGraph.xAxis[0].update({ title: { text: '' } });
            areaTrendYearGraph.yAxis[0].update({ title: { text: '' } });
            areaTrendYearGraph.xAxis[0].update({ lineWidth: 0 });
            areaTrendYearGraph.setTitle({ text: mainTitle });
          }
        }
      },
      title: {
        text: 'Area billing trend for ' + title,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        //text: month,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        pointFormatter: function (this) {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Dealer Name: <b>' + this.extraInfo[0] + '</b> <br> Area: <b>' + this.extraInfo[1] + '</b> <br> District: <b>' + this.extraInfo[2] + '</b> <br> Amount: <b> $' + this.y + '</b>';
          } else {
            return 'Amount: <b> $' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: $ {point.y} '
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      series: [],
      drilldown: {
        animation: false,
        //series: [this.areaTrendChartDrill2[0], this.areaTrendChartDrill2[1], this.areaTrendChartDrill2[2], this.areaTrendChartDrill2[3], this.areaTrendChartDrill2[4]],
        series: [],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }

      }
    })
  }

  //---------------------------------------------------------------------------
  changefilterPeriod(period, chartName) {
    this.filterType = 'period'
    //this.vehicleLeaseData = period;
    //this.filterPeriod = '';
    var currentdate = new Date();
    if (period == '1') {
      //var Period = currentdate.setMonth(currentdate.getMonth() - 1);
      //this.filterPeriod = new Date(Period).toISOString();
      this.filterPeriod = period;
      if (chartName == 'six') {
        this.sixthChart(this.currentMonthDay);
        this.sixthChartMonthFilter = '';
      }
      else if (chartName == 'seven') {
        this.seventhChart(this.currentMonthDay);
        this.sevenChartMonthFilter = '';
      }
      else if (chartName == 'nine') {
        this.ninethChart(this.currentMonthDay);
        this.ninethChartMonthFilter = '';
      }
    } else {
      this.filterPeriod = period;
      if (chartName == 'six') {
        this.sixthChart(period);
        this.sixthChartMonthFilter = '';
      }
      else if (chartName == 'seven') {
        this.seventhChart(period);
        this.sevenChartMonthFilter = '';
      }
      else if (chartName == 'nine') {
        this.ninethChart(period);
        this.ninethChartMonthFilter = '';
      }
    }
  }
  changeYearPeriod(val) {
    this.chartFiscalYearFilter = ""
    this.yearType = 'normal'
    this.secondChart(val);
    this.areaTrendYear(val)
    // this.eighthChart();
    // this.fourthChart();
    // this.fifthChartGr();
    // this.sixthChartGr(this.sixthNewDrill);
    // this.seventhChartGr(this.seventhNewDrill);
    this.ninethChart(this.currentMonth);
    this.getCoursesTable(this.currentMonth);
    this.chartMonthChanges('eight');
    this.chartMonthChanges('four');
    this.chartMonthChanges('five');
    this.chartMonthChanges('six');
    this.chartMonthChanges('seven');
  }
  sixthChart(filter) {
    this.sixthChartLoading = true; this.noSixthData = false;
    this.sixthTotalCount = 0;
    this.billingService.dealersAttendance(filter, this.filterType, this.trendFilterYearData).subscribe((response: any) => {
      this.sixthChartData = response.AttendanceDealers.Result;
      // this.sixthChartData = null; //null check
      if (this.sixthChartData != undefined && this.sixthChartData != null) {
        if (this.sixthChartData.length != 0) {
          for (let i = 0; i < this.sixthChartData.length; i++) {
            // this.noSixthData = false;
            this.sixthTotalCount = this.sixthChartData[i].totalCount;
            this.sixthPresent = ['Completed Count', 'No Show Count'];
            this.sixthPercents = [this.sixthChartData[i].completedCount, this.sixthChartData[i].noShowCount];
            let sixthDrill = [[], []]; this.sixthNewDrill = []; this.sixthNewDrill2 = [];
            let counts = []; let dlrs = []; let dlrsName = []; let dlrsAreaName = []; let dlrAreaDist = [];
            this.billingService.dealersAttendanceCount(filter, this.filterType, this.trendFilterYearData).subscribe((response: any) => {
              let sixthCountRes = response.DealerAttendanceCount.Result;
              // sixthCountRes = null; //null check drill
              if (sixthCountRes != undefined && sixthCountRes != null) {
                if (sixthCountRes.length != 0) {
                  this.noSixthData = false;
                  for (let a = 0; a < sixthCountRes.length; a++) {
                    if (sixthCountRes[a].status == 'Completed') {
                      sixthDrill[0].push(sixthCountRes[a]);
                    } else if (sixthCountRes[a].status == 'No Show') {
                      sixthDrill[1].push(sixthCountRes[a]);
                    }
                  }
                  for (let i = 0; i < sixthDrill.length; i++) {
                    for (let j = 0; j < sixthDrill[i].length; j++) {
                      counts.push(sixthDrill[i][j].count);
                      dlrs.push(sixthDrill[i][j].dealer);
                      dlrsName.push(sixthDrill[i][j].dealerName);
                      dlrsAreaName.push(sixthDrill[i][j].dealerAreaName);
                      dlrAreaDist.push(sixthDrill[i][j].dealerAreaDistrict);
                    }

                    let dum = []; let dumdum = [];
                    for (let e = 0; e < counts.length; e++) {
                      dumdum.push([dlrs[e], counts[e]]);
                      dum.push({ y: counts[e], extraInfo: [dlrsName[e], dlrsAreaName[e], dlrAreaDist[e]] })
                    }
                    this.sixthNewDrill.push({ name: this.sixthPresent[i], id: this.sixthPresent[i], data: dumdum, type: 'column' })
                    this.sixthNewDrill2.push({ name: this.sixthPresent[i], id: this.sixthPresent[i], data: dum, type: 'column' })

                    counts = []; dlrs = []; dlrsName = []; dlrsAreaName = []; dlrAreaDist = [];
                    if (this.sixthPresent.length == this.sixthNewDrill.length) {
                      this.sixthChartLoading = false;
                      this.sixthChartGr(this.sixthNewDrill);
                    }
                  }
                } else {
                  this.noSixthData = true;
                  this.sixthChartLoading = false;
                  this.sixthChartGr('');
                }
              } else {
                this.noSixthData = true;
                this.sixthChartLoading = false;
                this.sixthChartGr('');
              }
            }, err => {
              console.log('6th drill err', err);
              this.noSixthData = true;
              this.sixthChartLoading = false;
              this.sixthChartGr('');
            })
          }
        } else {
          this.noSixthData = true;
          this.sixthChartLoading = false;
          this.sixthChartGr('');
        }
      } else {
        this.noSixthData = true;
        this.sixthChartLoading = false;
        this.sixthChartGr('');
      }
    }, err => {
      console.log('6th main err', err);
      this.noSixthData = true;
      this.sixthChartLoading = false;
      this.sixthChartGr('');
    })
    this.sixthChartLoading = true;
    this.filterPeriod = filter;
    if (this.filterPeriod == '') {
      this.filterPeriod = '';
    }
    else {
      this.filterPeriod = filter == '2' ? 'last 1' : 'last ' + filter;
    }

  }
  sixthChartGr(smth) {
    const yt = this.yearType;
    const sixthPresent = this.sixthPresent; const sixthPercents = this.sixthPercents;
    var UNDF;
    let month = '';
    let date = new Date();
    let day = date.getDay()
    let test = '';
    let count = 0;
    let prevYear = date.getFullYear() - 1;  
    if (this.filterPeriod == 'last 1') {
      month = this.prevMonth ? this.currentMonth + ' ' + prevYear : this.currentMonth + ' ' + this.currentYear
    }
    else if (this.filterPeriod == 'last 3') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 3];
        count = Math.abs(date.getMonth() - 3)

      }
      else {
        test = this.XAx[date.getMonth() - 4];
        count = Math.abs(date.getMonth() - 4)

      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.trendFilterYearData : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else if (this.filterPeriod == 'last 6') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 6];
        count = Math.abs(date.getMonth() - 6)

      }
      else {
        test = this.XAx[date.getMonth() - 7];
        count = Math.abs(date.getMonth() - 7)
      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.currentYear : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else if (this.filterPeriod == 'last 12') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 12];
        count = Math.abs(date.getMonth() - 12)

      }
      else {
        test = this.XAx[date.getMonth() - 13];
        count = Math.abs(date.getMonth() - 13)
      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.currentYear : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else {
      this.filterPeriod = '';
    }
    let text = this.filterPeriod == '' ? this.sixthChartMonthFilter + ' ' + this.trendFilterYearData : this.filterPeriod + ' month(s)'
    let maintitle = 'Attendance count across Dealers for ' + text;
    var sixthChartGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'sixthChartGr',
        events: {
          load: function () {
            if (yt === 'normal') {
              this.addSeries(
                {
                  name: 'Dealers Attendance',
                  data: [{ name: sixthPresent[0], y: sixthPercents[0], sliced: true, selected: true, drilldown: sixthPresent[0] },
                  { name: sixthPresent[1], y: sixthPercents[1], drilldown: sixthPresent[1] }]
                })
            } else if (yt === 'fiscal') {
              this.addSeries(
                {
                  name: 'Dealers Attendance',
                  data: [{ name: sixthPresent[0], y: sixthPercents[0], sliced: true, selected: true, drilldown: sixthPresent[0], color: '#f7a35c' },
                  { name: sixthPresent[1], y: sixthPercents[1], drilldown: sixthPresent[1] }],
                })
              this.redraw()
            }
          },
          drilldown: function (e) {
            let newCat = [];
            for (let a = 0; a < smth.length; a++) {
              if (e.seriesOptions.name == smth[a].name) {
                for (let j = 0; j < smth[a].data.length; j++) {
                  newCat.push(smth[a].data[j][0])
                }
              }
            }
            sixthChartGraph.xAxis[0].setCategories(newCat);
            sixthChartGraph.xAxis[0].update({ title: { text: 'Dealers' } });
            sixthChartGraph.yAxis[0].update({ title: { text: 'No. of People' } });
            sixthChartGraph.xAxis[0].update({ lineWidth: 1 });
            sixthChartGraph.setTitle({ text: e.seriesOptions.name + ' by Dealer for ' + text });
          },
          drillup: function () {
            sixthChartGraph.xAxis[0].update({ title: { text: '' } });
            sixthChartGraph.yAxis[0].update({ title: { text: '' } });
            sixthChartGraph.xAxis[0].update({ lineWidth: 0 });
            sixthChartGraph.setTitle({ text: maintitle });
          }
        }
      },
      title: {
        text: maintitle,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: month,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        pointFormatter: function () {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Dealer Name: <b>' + this.extraInfo[0] + '</b> <br> Area: <b>' + this.extraInfo[1] + '</b> <br> District: <b>' + this.extraInfo[2] + '</b> <br> Count: <b>' + this.y + '</b>';
          } else {
            return '<b>' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<b>' + this.point.name + '</b>: ' + this.y;
            }
          }
        },
        column: {
          // allowPointSelect: true,
          //cursor: 'pointer',
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return this.y;
            }
          }
        }
      },
      legend: { enabled: true },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: 'Dealers'
        },
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'No of People'
        },
        allowDecimals: false
      },
      series: [],
      drilldown: {
        animation: false,
        series: [this.sixthNewDrill2[0], this.sixthNewDrill2[1]],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }
      }
    });

  }

  seventhChart(filter) {
    this.seventhChartLoading = true; this.noSeventhData = false;
    this.seventhTotalCount = 0;
    this.billingService.usersAttendance(filter, this.filterType, this.trendFilterYearData).subscribe((response: any) => {
      this.seventhChartData = response.AttendanceCourses.Result;
      // this.seventhChartData = null; //null check
      if (this.seventhChartData != undefined && this.seventhChartData != null) {
        if (this.seventhChartData.length != 0) {
          for (let i = 0; i < this.seventhChartData.length; i++) {
            this.seventhPresent = ['Completed Count', 'No Show Count'];
            this.seventhTotalCount = this.seventhChartData[i].totalCount;
            this.seventhPercents = [this.seventhChartData[i].completedCount, this.seventhChartData[i].noShowCount];
          }
          this.seventhDrill = [[], []]; this.seventhNewDrill = []; this.seventhNewDrill2 = [];
          this.billingService.coursesAttendanceCount(filter, this.filterType, this.trendFilterYearData).subscribe((res: any) => {
            let seventhDrill = res.CourseAttendanceCount.Result;
            // seventhDrill = null; //null check drill
            let counts = []; let fn = []; let sn = [];
            if (seventhDrill != undefined && seventhDrill != null) {
              if (seventhDrill.length != 0) {
                this.noSeventhData = false;
                for (let a = 0; a < seventhDrill.length; a++) {
                  if (seventhDrill[a].status == 'Completed') {
                    this.seventhDrill[0].push(seventhDrill[a])
                  } else if (seventhDrill[a].status == 'No Show') {
                    this.seventhDrill[1].push(seventhDrill[a])
                  }
                }

                for (let c = 0; c < this.seventhDrill.length; c++) {
                  for (let d = 0; d < this.seventhDrill[c].length; d++) {
                    counts.push(this.seventhDrill[c][d].count);
                    fn.push(this.seventhDrill[c][d].courseTitle);
                    sn.push(this.seventhDrill[c][d].short_name);
                  }
                  let dum = []; let dumdum = [];
                  for (let e = 0; e < counts.length; e++) {
                    dumdum.push([sn[e], counts[e]]);
                    dum.push({ y: counts[e], extraInfo: [fn[e]] })
                  }
                  this.seventhNewDrill.push({ name: this.seventhPresent[c], id: this.seventhPresent[c], data: dumdum, type: 'column' })
                  this.seventhNewDrill2.push({ name: this.seventhPresent[c], id: this.seventhPresent[c], data: dum, type: 'column' })

                  counts = []; fn = []; sn = [];
                  if (this.seventhPresent.length == this.seventhNewDrill.length) {
                    this.seventhChartLoading = false;
                    this.seventhChartGr(this.seventhNewDrill);

                  }
                }
              } else {
                this.noSeventhData = true;
                this.seventhChartLoading = false;
                this.seventhChartGr('');
              }
            } else {
              this.noSeventhData = true;
              this.seventhChartLoading = false;
              this.seventhChartGr('');
            }
          }, err => {
            console.log('7th drill err', err);
            this.noSeventhData = true;
            this.seventhChartLoading = false;
            this.seventhChartGr('');
          })
        }
        else {
          this.noSeventhData = true;
          this.seventhChartLoading = false;
          this.seventhChartGr('');
        }
      } else {
        this.noSeventhData = true;
        this.seventhChartLoading = false;
        this.seventhChartGr('');
      }
    }, err => {
      console.log('7th main error', err);
      this.noSeventhData = true;
      this.seventhChartLoading = false;
      this.seventhChartGr('');
    })
    this.seventhChartLoading = true;
    if (this.filterPeriod == '') {
      this.filterPeriod = '';
    }
    else {
      this.filterPeriod = filter == '2' ? 'last 1' : 'last ' + filter;
    }
  }
  seventhChartGr(smth) {
    const yt = this.yearType;
    const seventhPresent = this.seventhPresent; const seventhPercents = this.seventhPercents;
    var UNDF;
    let month = '';
    let date = new Date();
    let day = date.getDay()
    let test = '';
    let count = 0;
    let prevYear = date.getFullYear() - 1;
    if (this.filterPeriod == 'last 1') {
      month = this.prevMonth ? this.currentMonth + ' ' + prevYear : this.currentMonth + ' ' + this.trendFilterYearData
    }
    else if (this.filterPeriod == 'last 3') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 3];
        count = Math.abs(date.getMonth() - 3)

      }
      else {
        test = this.XAx[date.getMonth() - 4];
        count = Math.abs(date.getMonth() - 4)

      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.trendFilterYearData :
       test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else if (this.filterPeriod == 'last 6') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 6];
        count = Math.abs(date.getMonth() - 6)

      }
      else {
        test = this.XAx[date.getMonth() - 7];
        count = Math.abs(date.getMonth() - 7)
      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.currentYear : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else if (this.filterPeriod == 'last 12') {
      if (day > 5) {
        test = this.XAx[date.getMonth() - 12];
        count = Math.abs(date.getMonth() - 12)

      }
      else {
        test = this.XAx[date.getMonth() - 13];
        count = Math.abs(date.getMonth() - 13)
      }
      month = test == undefined ? this.XAx[12 - count] + ' ' + prevYear + ' - ' + this.currentMonth + ' ' + this.currentYear : test + ' ' + this.currentYear + ' - ' + this.currentMonth + ' ' + this.currentYear;
    }
    else {
      this.filterPeriod = '';
    }
    let text = this.filterPeriod == '' ? this.sevenChartMonthFilter + ' ' + this.trendFilterYearData : this.filterPeriod + ' month(s)'
    let maintitle = 'Attendance count across Courses for ' + text;
    var seventhChartGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'seventhChartGr',
        events: {
          load: function () {
            if (yt === 'normal') {
              this.addSeries({
                name: 'Courses Attendance',
                data: [{ name: seventhPresent[0], y: seventhPercents[0], sliced: true, selected: true, drilldown: seventhPresent[0] },
                { name: seventhPresent[1], y: seventhPercents[1], drilldown: seventhPresent[1] }]
              })
            } else if (yt === 'fiscal') {
              this.addSeries({
                name: 'Courses Attendance',
                data: [{ name: seventhPresent[0], y: seventhPercents[0], sliced: true, selected: true, drilldown: seventhPresent[0], color: '#f7a35c' },
                { name: seventhPresent[1], y: seventhPercents[1], drilldown: seventhPresent[1] }]
              })
            }
          },
          drilldown: function (e) {
            let newCat = [];
            for (let a = 0; a < smth.length; a++) {
              if (e.seriesOptions.name == smth[a].name) {
                for (let j = 0; j < smth[a].data.length; j++) {
                  newCat.push(smth[a].data[j][0])
                }
              }
            }
            seventhChartGraph.xAxis[0].setCategories(newCat);
            seventhChartGraph.xAxis[0].update({ title: { text: 'Courses' } });
            seventhChartGraph.yAxis[0].update({ title: { text: 'No. of People' } });
            seventhChartGraph.xAxis[0].update({ lineWidth: 1 });
            seventhChartGraph.setTitle({ text: e.seriesOptions.name + ' by course for ' + text });
          },
          drillup: function (e) {
            seventhChartGraph.xAxis[0].update({ title: { text: '' } });
            seventhChartGraph.yAxis[0].update({ title: { text: '' } });
            seventhChartGraph.xAxis[0].update({ lineWidth: 0 }, false);
            seventhChartGraph.setTitle({ text: maintitle });
          }
        }
      },
      title: {
        text: maintitle,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        text: month,
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        //pointFormat: '<b>{point.y:.1f}</b>',
        pointFormatter: function () {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Course Title: <b>' + this.extraInfo[0] + ' </b> <br> Count: <b>' + this.y + '</b>';
          } else {
            return '<b>' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<b>' + this.point.name + '</b>: ' + this.y;
            }
          }
        },
        column: {
          // allowPointSelect: true,
          // cursor: 'pointer',
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '9px',
              fontWeight: 'bold'
            },
            formatter: function () {
              return this.y;
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        title: {
          text: 'Courses'
        },
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'No of People'
        },
        allowDecimals: false
      },
      series: [],
      drilldown: {
        animation: false,
        series: [this.seventhNewDrill2[0], this.seventhNewDrill2[1]],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }

      }
    });

  }

  eighthChart() {
    this.eighthChartLoading = true; this.noEightData = false;
    this.eighthArea = [];
    this.eighthAmount = [];
    this.eighthDrill = [];
    this.eightTotalAmount = 0;
    this.areaDealerTrendMonArr = [];
    this.billingService.areaTrend(this.eightChartMonthFilter, this.trendFilterYearData).subscribe((res: any) => {
      this.eighthChartData = res.AreaTrend.Result;
      // this.eighthChartData = null; //null check
      if (this.eighthChartData != undefined && this.eighthChartData != null) {
        if (this.eighthChartData.length != 0) {
          for (let a = 0; a < this.eighthChartData.length; a++) {
            this.eighthArea.push(this.eighthChartData[a].area);
            this.eighthAmount.push(this.eighthChartData[a].amount);
            this.eightTotalAmount += this.eighthChartData[a].amount
          }
          this.eighthDataDrill = []; this.eighthDataDrill2 = [];
          let eighthDrillDealers = []; let eighthDrillAmounts = []; let eighthDrillArea = []; let eighthDrillDist = []; let eighthDrillDlrName = [];
          const eighthNewDrill = Array.from({ length: this.eighthChartData.length }, (_v, _i) => []);
          this.billingService.areaDealersTrend(this.eightChartMonthFilter, 'areaChartDrill', this.trendFilterYearData).subscribe((res: any) => {
            let eighthDrillData = res.AreaDealersTrend.Result;
            this.areaDealerTrendMonArr = res.AreaDealersTrend.Result;
            // eighthDrillData = null; //null check drill
            if (eighthDrillData != undefined && eighthDrillData != null) {
              if (eighthDrillData.length != 0) {
                this.noEightData = false;
                for (let c = 0; c < eighthDrillData.length; c++) {
                  for (let d = 0; d < this.eighthArea.length; d++) {
                    if (eighthDrillData[c].area == this.eighthArea[d]) {
                      eighthNewDrill[d].push(eighthDrillData[c]);
                    }
                  }
                }
                for (let b = 0; b < eighthNewDrill.length; b++) {
                  for (let z = 0; z < eighthNewDrill[b].length; z++) {
                    eighthDrillDealers.push(eighthNewDrill[b][z].organisation);
                    eighthDrillAmounts.push(eighthNewDrill[b][z].amount);
                    eighthDrillArea.push(eighthNewDrill[b][z].area);
                    eighthDrillDist.push(eighthNewDrill[b][z].dealerDistrict);
                    eighthDrillDlrName.push(eighthNewDrill[b][z].dealerName);
                  }
                  let dumdum = []; let dum = [];
                  for (let d = 0; d < eighthDrillDealers.length; d++) {
                    dumdum.push([eighthDrillDealers[d], eighthDrillAmounts[d]]);
                    dum.push({ y: eighthDrillAmounts[d], extraInfo: [eighthDrillDlrName[d], eighthDrillArea[d], eighthDrillDist[d]] })
                  }
                  this.eighthDataDrill.push({ name: this.eighthArea[b], id: this.eighthArea[b], data: dumdum, type: 'column' });
                  this.eighthDataDrill2.push({ name: this.eighthArea[b], id: this.eighthArea[b], data: dum, type: 'column' });
                  eighthDrillDealers = []; eighthDrillAmounts = []; eighthDrillArea = []; eighthDrillDist = []; eighthDrillDlrName = [];
                  if (this.eighthChartData.length == this.eighthDataDrill.length) {
                    this.eighthChartLoading = false;
                    this.eighthChartGr(this.eighthDataDrill, this.eighthDataDrill2);
                  }
                }

              }
              else {
                this.noEightData = true;
                this.eighthChartLoading = false;
                this.eighthChartGr('', '');
              }
            } else {
              this.noEightData = true;
              this.eighthChartLoading = false;
              this.eighthChartGr('', '');
            }
          }, err => {
            console.log('8th drill err', err);
            this.noEightData = true;
            this.eighthChartLoading = false;
            this.eighthChartGr('', '');
          })
        } else {
          this.noEightData = true;
          this.eighthChartLoading = false;
          this.eighthChartGr('', '');
        }
      } else {
        this.noEightData = true;
        this.eighthChartLoading = false;
        this.eighthChartGr('', '');
      }
    }, err => {
      console.log('8th main err', err);
      this.noEightData = true;
      this.eighthChartLoading = false;
      this.eighthChartGr('', '');
    })

  }
  eighthChartGr(smth, smth2) {
    let mainTitle = 'Area billing trend for ' + this.eightChartMonthFilter + ' ' + this.trendFilterYearData;
    let drillTitle = this.eightChartMonthFilter + ' ' + this.trendFilterYearData;
    var eighthArea = this.eighthArea; var eighthAmount = this.eighthAmount;
    let centD = []; let eastD = []; let westD = []; let southD = []; let otherD = [];
    for (let a = 0; a < smth2.length; a++) {
      if (smth2[a].id == 'CENTRAL AREA') { centD = smth2[a].data }
      else if (smth2[a].id == 'EASTERN AREA') { eastD = smth2[a].data }
      else if (smth2[a].id == 'WESTERN AREA') { westD = smth2[a].data }
      else if (smth2[a].id == 'SOUTHERN AREA') { southD = smth2[a].data }
      else if (smth2[a].id == 'OTHERS') { otherD = smth2[a].data }
    }
    var UNDF;
    var newCat = [];
    var eighthChartGraph = new Highcharts.Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        renderTo: 'eighthChartGr',
        events: {
          load: function () {
            if (smth.length == 4) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: eighthArea[0], y: eighthAmount[0], drilldown: eighthArea[0] },
                { name: eighthArea[1], y: eighthAmount[1], drilldown: eighthArea[1] },
                { name: eighthArea[2], y: eighthAmount[2], drilldown: eighthArea[2] },
                { name: eighthArea[3], y: eighthAmount[3], drilldown: eighthArea[3] }]
              })
            } else if (smth.length == 5) {
              this.addSeries({
                name: 'Area Trend', data: [{ name: eighthArea[0], y: eighthAmount[0], drilldown: eighthArea[0] },
                { name: eighthArea[1], y: eighthAmount[1], drilldown: eighthArea[1] },
                { name: eighthArea[2], y: eighthAmount[2], drilldown: eighthArea[2] },
                { name: eighthArea[3], y: eighthAmount[3], drilldown: eighthArea[3] },
                { name: eighthArea[4], y: eighthAmount[4], drilldown: eighthArea[4] }]
              })
            }
          },
          drilldown: function (e) {
            let drillName = e.point.name;
            newCat = [];
            localStorage.setItem('chartAreaMonDrillVal', e.point.name)
            for (let i = 0; i < smth.length; i++) {
              if (smth[i].name == e.point.name) {
                for (let j = 0; j < smth[i].data.length; j++) {
                  newCat.push(smth[i].data[j][0])
                }
              }
            }
            if (smth.length == 4) {
              var chart = this,
                drilldowns1 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                }
              let series = drilldowns1[e.point.name];
              eighthChartGraph.addSeriesAsDrilldown(e.point, series);
            } else if (smth.length == 5) {
              var chart = this,
                drilldowns2 = {
                  'CENTRAL AREA': { name: 'CENTRAL AREA', id: 'CENTRAL AREA', data: centD, type: 'column' },
                  'EASTERN AREA': { name: 'EASTERN AREA', id: 'EASTERN AREA', data: eastD, type: 'column' },
                  'SOUTHERN AREA': { name: 'SOUTHERN AREA', id: 'SOUTHERN AREA', data: southD, type: 'column' },
                  'WESTERN AREA': { name: 'WESTERN AREA', id: 'WESTERN AREA', data: westD, type: 'column' },
                  'OTHERS': { name: 'OTHERS', id: 'OTHERS', data: otherD, type: 'column' }
                }
              let series = drilldowns2[e.point.name];
              eighthChartGraph.addSeriesAsDrilldown(e.point, series);
            }
            eighthChartGraph.xAxis[0].setCategories(newCat);
            eighthChartGraph.xAxis[0].update({ title: { text: 'Dealers' } });
            eighthChartGraph.yAxis[0].update({ title: { text: 'Cost ($)' } });
            eighthChartGraph.xAxis[0].update({ lineWidth: 1 });
            eighthChartGraph.setTitle({ text: 'Billing trend of ' + drillName + ' for ' + drillTitle });
            eighthChartGraph.redraw();
          },
          drillup: function (e) {
            localStorage.setItem('chartAreaMonDrillVal', '')
            eighthChartGraph.xAxis[0].update({ title: { text: '' } });
            eighthChartGraph.yAxis[0].update({ title: { text: '' } });
            eighthChartGraph.xAxis[0].update({ lineWidth: 0 });
            eighthChartGraph.setTitle({ text: mainTitle })
          }
        },
      },
      title: {
        text: mainTitle,
        align: 'center',
        style: {
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      subtitle: {
        align: 'center',
        style: {
          color: 'grey',
          fontSize: '10px'
        }
      },
      tooltip: {
        pointFormatter: function (this) {
          if (this.series.chart.drilldownLevels !== UNDF && this.series.chart.drilldownLevels.length > 0) {
            return 'Dealer Name: <b>' + this.extraInfo[0] + '</b> <br> Area: <b>' + this.extraInfo[1] + '</b> <br> District: <b>' + this.extraInfo[2] + '</b> <br> Amount: <b> $' + this.y + '</b>';
          } else {
            return 'Amount: <b> $' + this.y + '</b>';
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '<b>' + this.point.name + '</b>: $ ' + this.y;
            }
          }
        },
        column: {
          // allowPointSelect: true,
          // cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return '$ ' + this.y;
            },
            style: {
              fontSize: '8px',
              fontWeight: 'bold'
            },
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'category',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Cost ($)'
        },
        labels: {
          formatter: function () {
            if (this.value > 999) {
              return Highcharts.numberFormat(this.value / 1000, 1) + "K"
            } else if (this.value <= 999) {
              return this.value
            }
          }
        }
      },
      series: [],
      drilldown: {
        animation: false,
        //series: [this.eighthDataDrill2[0], this.eighthDataDrill2[1], this.eighthDataDrill2[2], this.eighthDataDrill2[3]],//, this.eighthDataDrill2[4]],
        series: [],
        drillUpButton: {
          relativeTo: 'plotBox',
          position: {
            y: 0,
            x: -20,
            align: 'right'
          },
          theme: {
            fill: 'white',
            'stroke-width': 1,
            stroke: 'silver',
            r: 0,
            states: {
              hover: {
                fill: '#a4edba'
              },
              select: {
                stroke: '#039',
                fill: '#a4edba'
              }
            }
          }
        }

      }
    })
  }
  reportsDropDownFetch() {
    this.billingService.coursesList().subscribe((response: any) => {
      this.coursesList = response.CoursesList.Result;
    })
    this.billingService.dealersList().subscribe((response: any) => {
      this.dealersList = response.DealersList.Result;
    })
  }
  search() {
    this.tableLoading = true;
    this.showSpinner = true;
    this.reportArray = [];
    this.exportData = [];
    this.billingService.getReports(this.searchForm.value).subscribe((response: any) => {
      let responseArr = response.Reports.Result;
      for (let i = 0; i < responseArr.length; i++) {
        this.reportArray.push(responseArr[i]);
        this.exportData.push(responseArr[i])
      }
      this.reportArray.forEach(element => {
        if (element.startDate != '' && element.startDate != null && element.startDate != undefined && element.startDate != '1900-01-01T00:00:00.000Z') {
          element.startDate = moment(element.startDate).format('MM/DD/YYYY');
        }
        else {
          element.startDate = ''
        }
        if (element.completionDate != '' && element.completionDate != null && element.completionDate != undefined && element.completionDate != '1900-01-01T00:00:00.000Z') {
          element.completionDate = moment(element.completionDate).format('MM/DD/YYYY');
        }
        else {
          element.completionDate = ''
        }
      });
      this.tableLoading = false;
      this.showSpinner = false;
      this.reportDataSource = new MatTableDataSource(this.reportArray);
      this.expandState = false;
      setTimeout(() => {
        this.reportDataSource.sort = this.sort;
        this.reportDataSource.paginator = this.ReportPaginator;
      });
    })
  }
  reset() {
    this.searchForm.reset({
      spinId: '',
      month: '',
      period: '',
      areaOrDistrict: '',
      course: '',
      invoiceNumber: '',
      dealerNumber: '',
      attendance: '',
      facility: 'Virtual',
      calendarYear: '',
      fiscalYear: ''
    });
    this.reportArray = [];
    this.exportData = []
    this.tableLoading = true;
    this.showSpinner = false;
    this.reportDataSource.sort = this.sort;
    this.reportDataSource = new MatTableDataSource(this.reportArray);
    this.reportDataSource.paginator = this.ReportPaginator;
    this.expandState = true;
  }
  download() {
    let reportExportData = [];
    this.exportData.forEach(data => {
      let obj = {
        "NAME": data.name,
        "SPIN ID": data.spinId,
        "COURSE": data.course,
        "DEALER CODE": data.dealer,
        "DEALER NAME": data.dealerName,
        "DEALER AREA": data.dealerArea,
        "DEALER DISTRICT": data.dealerDistrict,
        "DEALER ADDRESS": data.dealerAddr1,
        "DEALER CITY": data.dealerCity,
        "DEALER STATE": data.dealerState,
        "LOCATION": data.region,
        "START DATE": data.startDate,
        "COMPLETION DATE": data.completionDate,
        "STATUS": data.status,
        "LOCATOR ID": data.classNo,
        "FACILITY": data.facility,
        "AMOUNT": data.amount
      }
      reportExportData.push(obj);
    });
    this.excelService.exportAsExcelFile(reportExportData, 'Billing_Reports');
  }
  downloadCourseTable() {
    let reportExportData = [];
    this.coursesArr.forEach(data => {
      let obj = {
        "COURSE ID": data.shortName,
        "COURSE NAME": data.title,
        "SESSIONS": data.sessions,
        "ATTENDEES": data.attendees,
        "RECOVERIES": data.amount
      }
      reportExportData.push(obj);
    });
    this.excelService.exportAsExcelFile(reportExportData, this.courseTableFilter + '_Month_Course_Summary');
  }
  //  ================================= TABLE SEARCH FILTER METHOD ======================================
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.reportDataSource.filter = filterValue.trim().toLowerCase();
    this.reportDataSource.filteredData.forEach(element => {
      this.exportData.push(element);
    })
    // if (filterValue != '' || this.reportDataSource.filteredData.length < 10) {
    //   $("#holdHistorTable").removeClass('tableOverflow');
    // } else {
    //   $("#holdHistorTable").addClass('tableOverflow');
    // }
  }
  exportModelClicked(chartName) {
    this.exportChartName = chartName;
    $("#excelReportModal").show("slow");
    $("#excelReportModal").removeClass('hide_block');
    $("#excelReportModal").addClass('display_block');
  }
  async exportFile() {
    this.closeErrorModal('downloadPopup');
    let exportChartData = [];
    if (this.exportChartName == 'ninethChart') {
      let mon = '';
      if (this.trendFilterNineData != '') {
        if (this.trendFilterNineData == '1') {
          let date = new Date();
          let day = date.getDate();
          if (this.trendFilterNineData == '1') {
            if (day > 5) {
              mon = '1'
            }
            else {
              mon = '2'
            }
          }
        }
        else {
          mon = this.trendFilterNineData;
        }
      }
      else {
        mon = this.ninethChartMonthFilter
      }
      var nineExportData = [];
      let data = await new Promise<boolean>(resolve => this.billingService.dealersOverallAttendance(mon, this.filterType, this.chartAreaFilter, this.trendFilterYearData, 'download').subscribe((res: any) => {
        nineExportData = res.DealersAttendanceOvrAllCnt.Result;
        resolve(true);
      })
      )
      if (data == true) {
        nineExportData.forEach(element => {
          let obj = {
            "DEALER CODE": element.dealer,
            "DEALER NAME": element.dealerName,
            "DEALER AREA": element.dealerAreaName,
            "DEALER DISTRICT": element.dealerDistrict,
            "Completed Count": element.completedCount,
            "No Show Count": element.noShowCount,
            "Dealer Address": element.dealerAddr1,
            "Dealer City": element.dealerCity,
            "Dealer State": element.dealerState,
            "Month": element.month
          }
          exportChartData.push(obj);
        })
        this.excelService.exportAsExcelFile(exportChartData, 'Dealer_Attendance')
      }
    }
    else if (this.exportChartName == 'fourthChart') {
      this.fourthChartData.forEach(element => {
        let obj = {
          "DEALER CODE": element.dealer,
          "DEALER NAME": element.dealerName,
          "DEALER AREA": element.dealerAreaName,
          "DEALER DISTRICT": element.dealerDistrict,
          "AMOUNT": element.amount,
          "MONTH": this.fourthChartMonthFilter
        }
        exportChartData.push(obj);
      })
      this.excelService.exportAsExcelFile(exportChartData, 'Dealer_' + this.fourthChartMonthFilter)
    }
    else if (this.exportChartName == 'areaYearChart') {
      this.areaDealerTrendYearArr.forEach(element => {
        if (localStorage.getItem('chartAreaDrillVal') != '' && localStorage.getItem('chartAreaDrillVal') != undefined && localStorage.getItem('chartAreaDrillVal') != null) {
          if (element.area != 'OTHER' && element.area == localStorage.getItem('chartAreaDrillVal')) {
            let obj = {
              "DEALER CODE": element.organisation,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.area,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
          }
        }
        else {
          if (element.area != 'OTHER') {
            let obj = {
              "DEALER CODE": element.organisation,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.area,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
          }
        }
      })

      this.excelService.exportAsExcelFile(exportChartData, 'Area_Billing_Trend_Dealers')
    }
    else if (this.exportChartName == 'areaMonthChart') {
      this.areaDealerTrendMonArr.forEach(element => {
        if (localStorage.getItem('chartAreaMonDrillVal') != '' && localStorage.getItem('chartAreaMonDrillVal') != undefined && localStorage.getItem('chartAreaMonDrillVal') != null) {
          if (element.area != 'OTHER' && element.area == localStorage.getItem('chartAreaMonDrillVal')) {
            let obj = {
              "DEALER CODE": element.organisation,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.area,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
          }
        }
        else {
          if (element.area != 'OTHER') {
            let obj = {
              "DEALER CODE": element.organisation,
              "DEALER NAME": element.dealerName,
              "DEALER AREA": element.area,
              "DEALER DISTRICT": element.dealerDistrict,
              "AMOUNT": element.amount,
            }
            exportChartData.push(obj);
          }
        }
      })

      this.excelService.exportAsExcelFile(exportChartData, 'Area_Billing_Trend_Dealers')
    }
  }
  exceptionPayClicked() {
    this.showExceptionPay = true;
    this.showExceptionPaySpinner = true;
    this.exceptionPayArr = [];
    this.exceptionPayExportData = [];
    this.getExceptionPayTable();
  }
  getExceptionPayTable() {
    this.billingService.getExceptionPay().subscribe((response: any) => {
      let responseArr = response.ExceptionPay.Result;
      this.courseList = response.courseList.Result1;
      for (let i = 0; i < responseArr.length; i++) {
        this.exceptionPayArr.push(responseArr[i]);
        this.exceptionPayExportData.push(responseArr[i])
      }
      this.exceptionPayArr.forEach(element => {
        if (element.startDate != '' && element.startDate != null && element.startDate != undefined && element.startDate != '1900-01-01T00:00:00.000Z') {
          element.startDate = moment(element.startDate).format('MM/DD/YYYY');
        }
        else {
          element.startDate = ''
        }
        if (element.completionDate != '' && element.completionDate != null && element.completionDate != undefined && element.completionDate != '1900-01-01T00:00:00.000Z') {
          element.completionDate = moment(element.completionDate).format('MM/DD/YYYY');
        }
        else {
          element.completionDate = ''
        }
      });
      this.exceptionPayTableLoading = false;
      this.showExceptionPaySpinner = false;
      this.exceptionPayDataSource = new MatTableDataSource(this.exceptionPayArr);
      setTimeout(() => {
        this.exceptionPayDataSource.sort = this.sort;
        this.exceptionPayDataSource.paginator = this.ExceptionPayPaginator;
      });
    })
  }
  async createExceptionPayment() {
    this.showErrMsg = '';
    let dealerCheck = false;
    this.showExceptionPaySpinner = true;
    const dealerBody = {
      "isDealerCheck": this.exceptionPayForm.get('dealerCode').value
    }
    await new Promise<boolean>(resolve => {
      this.billingService.insertExceptionPay(dealerBody).subscribe((response: any) => {
        console.log(response)
        const dealer = response.dealer.Result;
        dealerCheck = dealer.length > 0 ? true : false
        resolve(true);
      })
    })
    this.Error = false;
    if (!dealerCheck) {
      this.showErrMsg += 'Please enter valid dealer.'
    }
    if (this.exceptionPayForm.get('status').value == 'Completed') {
      this.showErrMsg += this.exceptionPayForm.get('completionDate').value == '' || this.exceptionPayForm.get('completionDate').value == null ? "Please enter completion date which is mandatory for completed session." : "";
    }
    if (this.exceptionPayForm.get('paymentType').value == 'Individual') {
      this.showErrMsg += this.exceptionPayForm.get('spinId').value == '' || this.exceptionPayForm.get('firstName').value == '' || this.exceptionPayForm.get('lastName').value == '' ? "Please enter Spin/First Name/Last Name which is mandatory for Individual payment type." : "";
    }
    if (this.showErrMsg.length == 0) {
    this.createException = true;
    this.showExceptionPay = true;
    this.exceptionPayTableLoading = true;
    this.showExceptionPaySpinner = true;
    this.exceptionPayArr = [];
    this.exceptionPayExportData = [];
    if (this.exceptionPayForm.get('startDate').value != '' && this.exceptionPayForm.get('startDate').value != null && this.exceptionPayForm.get('startDate').value != undefined && this.exceptionPayForm.get('startDate').value != '1900-01-01T00:00:00.000Z') {
      this.exceptionPayForm.get('startDate').setValue(moment(this.exceptionPayForm.get('startDate').value).format('MM/DD/YYYY'))
    }
    else {
      this.exceptionPayForm.get('startDate').setValue('')
    }
    if (this.exceptionPayForm.get('completionDate').value != '' && this.exceptionPayForm.get('completionDate').value != null && this.exceptionPayForm.get('completionDate').value != undefined && this.exceptionPayForm.get('completionDate').value != '1900-01-01T00:00:00.000Z') {
      this.exceptionPayForm.get('completionDate').setValue(moment(this.exceptionPayForm.get('completionDate').value).format('MM/DD/YYYY'))
    }
    else {
      this.exceptionPayForm.get('completionDate').setValue('')
    }
    this.billingService.insertExceptionPay(this.exceptionPayForm.value).subscribe((response: any) => {
      console.log(response);
      this.createException = false;
      this.showErrMsg = '';
      this.resetExceptionPay();
      this.exceptionPayClicked();
      $("#exceptionPaymentSuccess").show("slow");
      $("#exceptionPaymentSuccess").removeClass('hide_block');
      $("#exceptionPaymentSuccess").addClass('display_block');
    }, err => {
      this.createException = false;
      this.showErrMsg = '';
    })
    }
    else {
      this.Error = true;
      this.showExceptionPaySpinner = false;
      this.createException = false;
    }
  }
  closeErrorModal(type) {
    if (type == 'successPopup') {
      $("#exceptionPaymentSuccess").removeClass('display_block');
      $("#exceptionPaymentSuccess").addClass('hide_block');
      $("#exceptionPaymentSuccess").show("slow");
    }
    else if (type == 'downloadPopup') {
      $("#excelReportModal").removeClass('display_block');
      $("#excelReportModal").addClass('hide_block');
      $("#excelReportModal").show("slow");
    }
  }

  resetExceptionPay() {
    this.exceptionPayForm.reset({
      paymentType: '',
      dealerCode: '',
      course: '',
      courseShortName: '',
      startDate: '',
      completionDate: '',
      credit: '',
      peopleAttended: '',
      firstName: '',
      lastName: '',
      spinId: '',
      facility: '',
      status: '',
      addedBy: JSON.parse(localStorage.getItem('currentUser')).Spin_ID
    });
    this.createException = false;
    // this.exceptionPayArr = [];
    // this.exceptionPayExportData = []
    // this.exceptionPayTableLoading = true;
    // this.showExceptionPaySpinner = false;
    // this.exceptionPayDataSource.sort = this.sort;
    // this.exceptionPayDataSource = new MatTableDataSource(this.exceptionPayArr);
    // this.exceptionPayDataSource.paginator = this.ExceptionPayPaginator;
  }
  applyExceptionPayFilter(filterValue: string) {
    this.exceptionPayExportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.exceptionPayDataSource.filter = filterValue.trim().toLowerCase();
    this.exceptionPayDataSource.filteredData.forEach(element => {
      this.exceptionPayExportData.push(element);
    })
  }
  exceptionPayDownload() {
    let reportExportData = [];
    this.exceptionPayExportData.forEach(data => {
      let obj = {
        "PAYMENT TYPE": data.paymentType,
        "DEALER": data.dealer,
        "COURSE": data.course,
        "STATUS": data.status,
        "START DATE": data.startDate,
        "COMPLETION DATE": data.completionDate,
        "FACILITY": data.facility,
        "NAME": data.name,
        "SPIN ID": data.spinId,
        "AMOUNT": data.amount
      }
      reportExportData.push(obj);
    });
    this.excelService.exportAsExcelFile(reportExportData, 'Exception_Pay');
  }
  chartMonthChanges(chartType) {
    this.filterType = 'month';
    if (chartType == 'eight') {
      if (this.yearType == 'fiscal') {
        if (this.eightChartMonthFilter == 'January' || this.eightChartMonthFilter == 'February' || this.eightChartMonthFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.eighthChart();
    }
    else if (chartType == 'four') {
      if (this.yearType == 'fiscal') {
        if (this.fourthChartMonthFilter == 'January' || this.fourthChartMonthFilter == 'February' || this.fourthChartMonthFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.fourthChart();
    }
    else if (chartType == 'five') {
      if (this.yearType == 'fiscal') {
        if (this.fifthChartMonthFilter == 'January' || this.fifthChartMonthFilter == 'February' || this.fifthChartMonthFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.fifthChart();
    }
    else if (chartType == 'six') {
      this.trendFilterData = '';
      if (this.yearType == 'fiscal') {
        if (this.sixthChartMonthFilter == 'January' || this.sixthChartMonthFilter == 'February' || this.sixthChartMonthFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.sixthChart(this.sixthChartMonthFilter);
    }
    else if (chartType == 'seven') {
      this.trendFilterSevenData = '';
      if (this.yearType == 'fiscal') {
        if (this.sevenChartMonthFilter == 'January' || this.sevenChartMonthFilter == 'February' || this.sevenChartMonthFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.seventhChart(this.sevenChartMonthFilter);
    }
    else if (chartType == 'nine') {
      this.trendFilterNineData = '';
      if (this.yearType == 'fiscal') {
        if (this.ninethChartMonthFilter == 'January' || this.ninethChartMonthFilter == 'February' || this.ninethChartMonthFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.ninethChart(this.ninethChartMonthFilter);
    }
    else if (chartType == 'courseTable') {
      if (this.yearType == 'fiscal') {
        if (this.courseTableFilter == 'January' || this.courseTableFilter == 'February' || this.courseTableFilter == 'March') {
          this.trendFilterYearData = '2024'
        }
        else {
          this.trendFilterYearData = '2023'
        }
      }
      this.getCoursesTable(this.courseTableFilter);
    }
    // this.filterPeriod = this.chartMonthFilter;
  }
  areaFilterChanges(chartName) {
    // this.filterPeriod = this.chartMonthFilter;
    let mon = '';
    if (this.trendFilterNineData != '') {
      if (this.trendFilterNineData == '1') {
        let date = new Date();
        let day = date.getDate();
        if (this.trendFilterNineData == '1') {
          if (day > 5) {
            mon = '1'
          }
          else {
            mon = '2'
          }
        }
      }
      else {
        mon = this.trendFilterNineData;
      }
    }
    else {
      mon = this.ninethChartMonthFilter
    }
    this.filterType = (this.ninethChartMonthFilter == '' || this.ninethChartMonthFilter == null) ? 'period' : 'month'
    if (chartName == 'nine') {
      this.ninethChart(mon)
    }
  }
  changeFiscalYear(year) {
    this.yearType = 'fiscal';
    this.chartFiscalYearFilter = year
    this.trendFilterYearData = "";
    this.secondChart(year.split('-')[0]);
    this.areaTrendYear(year.split('-')[0]);
    this.fourthChartGr();
    this.fifthChartGr();
    this.sixthChartGr(this.sixthNewDrill);
    this.seventhChartGr(this.seventhNewDrill);
    this.ninethChartGr();
    this.months = [
      {
        "abbreviation": "Apr",
        "name": "April"
      },
      {
        "abbreviation": "May",
        "name": "May"
      },
      {
        "abbreviation": "Jun",
        "name": "June"
      },
      {
        "abbreviation": "Jul",
        "name": "July"
      },
      {
        "abbreviation": "Aug",
        "name": "August"
      },
      {
        "abbreviation": "Sep",
        "name": "September"
      },
      {
        "abbreviation": "Oct",
        "name": "October"
      },
      {
        "abbreviation": "Nov",
        "name": "November"
      },
      {
        "abbreviation": "Dec",
        "name": "December"
      },
      {
        "abbreviation": "Jan",
        "name": "January"
      },
      {
        "abbreviation": "Feb",
        "name": "February"
      },
      {
        "abbreviation": "Mar",
        "name": "March"
      },
    ]
  }
  reportCalendarYearChanged() {
    this.searchForm.get('fiscalYear').setValue('')
  }
  reportFiscalYearChanged() {
    this.searchForm.get('calendarYear').setValue('');
    if (this.searchForm.get('month').value != '') {
      if (this.searchForm.get('month').value == 'January' || this.searchForm.get('month').value == 'February' || this.searchForm.get('month').value == 'March') {
        this.searchForm.get('fiscalYear').setValue('2024')
      }
      else {
        this.searchForm.get('fiscalYear').setValue('2023')
      }
    }
    else {
      this.searchForm.get('fiscalYear').setValue('2024')
    }

    this.reportMonths = [
      {
        "abbreviation": "Apr",
        "name": "April"
      },
      {
        "abbreviation": "May",
        "name": "May"
      },
      {
        "abbreviation": "Jun",
        "name": "June"
      },
      {
        "abbreviation": "Jul",
        "name": "July"
      },
      {
        "abbreviation": "Aug",
        "name": "August"
      },
      {
        "abbreviation": "Sep",
        "name": "September"
      },
      {
        "abbreviation": "Oct",
        "name": "October"
      },
      {
        "abbreviation": "Nov",
        "name": "November"
      },
      {
        "abbreviation": "Dec",
        "name": "December"
      },
      {
        "abbreviation": "Jan",
        "name": "January"
      },
      {
        "abbreviation": "Feb",
        "name": "February"
      },
      {
        "abbreviation": "Mar",
        "name": "March"
      },
    ]
  }
  getCoursesTable(filter) {
    this.showSpinner = true;
    this.coursesTableLoading = true;
    this.billingService.coursesTable(filter, this.filterType, this.trendFilterYearData).subscribe((response: any) => {
      this.coursesArr = response.AttendanceCourses.Result;
      let amount = 0;
      let sessions = 0;
      let attendees = 0;
      this.coursesArr.forEach(element => {
        amount += element.amount;
        sessions += element.sessions;
        attendees += element.attendees;
      });
      const obj = {
        "shortName": "TOTAL",
        "title": "TOTAL",
        "sessions": sessions,
        "amount": amount,
        "attendees": attendees
      }
      this.coursesArr.push(obj);
      this.courseDataSource = new MatTableDataSource(this.coursesArr);
      this.coursesTableLoading = false;
      this.showSpinner = false;
      setTimeout(() => {
        this.courseDataSource.sort = this.sort;
        this.courseDataSource.paginator = this.CoursesPaginator;
      });
    })
  }
  setCourseTitle(){
    const title = this.courseList.filter(element=>{
      return element.shortName == this.exceptionPayForm.get('courseShortName').value
    })
    console.log(this.exceptionPayForm.get('courseShortName').value,title,title[0].courseTitle);
    this.exceptionPayForm.get('course').setValue(title[0].courseTitle);
  }
}
