import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, MatIconModule, MatExpansionModule, matTabsAnimations } from "@angular/material";
import { Router } from '@angular/router';
import { WatchlistService } from '../watchlist/watchlist.service';
import { PaymentHistoryService } from '../payment-history/payment-history.service';
import { CorpHoldResumeService } from '../corp-hold-resume/corp-hold-resume.service';
import { CommonService } from '../service/common.service'
import { ExcelService } from '../service/excel.service';
import { AppComponent } from '../app.component'
declare var $: any;
import { ReportService } from '../reports/reports.service';
import { LoginService } from '../login/login.service';
import { PlatformLocation, Location } from '@angular/common';

export interface PaymentData {
  payduecode: string;
  lfspaycode: string;
  paymentPeriod: string;
  paymentDate: string;
  invoice: string;
}

export interface DebitData {
  payduecode: string;
  lfspaycode: string;
  paymentPeriod: string;
  paymentDate: string;
  debitClicked: boolean;
  Comments: string;
  status: string;
}

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class watchlistComponent implements OnInit {

  // checkIfPcChecked() {
  //   if(this.dealerCode.length > 0 && this.disableRoleArray[3]) {
  //     return  'You must select a value';
  //   } else {return ''}

  // }
  activeWatchDataColumns: string[] = ["Name", "SpinId", "DealerCode", "Addedby", "Addedon", "Reason", "Remove", "AllComm"];
  pastWatchDataColumns: string[] = ["Name", "SpinId", "DealerCode", "Removedby", "Removedon", "Reason", "Add", "AllComm"];
  paymentColumns: string[] = ['dealerCode', 'lfspaycode', 'paymentPeriod', 'paymentDate', 'invoice'];
  debitColumns = ['dealerCode', 'lfspaycode', 'paymentPeriod', 'paymentDate', 'debit', 'status', 'remarks'];

  //Search Variables
  debitStatus: boolean = false;
  loadSpinner: boolean = false;
  filteredUsers: any[] = [];
  PayHistoryForm: FormGroup;
  isLoading = false;
  spinId: any = "";
  spinIdClicked: Boolean = false;

  //Table Variables
  tableDisplay: boolean = false;
  paymentTableLoading: boolean = false;
  paymentNoData: boolean = false;
  paymentHistoryResponse: any = '';
  PAYMAINDATA: any = [];
  PAYMENTDATA: PaymentData[] = [];
  paymentTotalData: number = 0;
  //Info Variable
  dealername: string;
  spinnos: string;
  paymentDate: string;
  dealercode: string;
  nameAssocaite: string = '';

  noExcelData: boolean;
  tabName: string = '';

  //Debit Variables
  
  Comments: string = "";
  debitBtnDisabled: boolean = true;
  user: any = [];
  fullName: string = '';
  panelOpenState = false;
  debitTable: boolean = true;
  debitTableLoading: boolean = false;
  debitNoData: boolean = false;
  debitHistoryResponse: any = '';
  DEBIT_DATA: any = [];
  DEBITDATA: DebitData[] = [];
  debitTotalData: number = 0;

  infoLoading: boolean = false;

  //watchlist vars
  activeWatchlist: any = [];
  pastWatchlist: any = [];
  watchlistTotalData: number = 0;
  pastWatchlistTotalData: number = 0;
  activeWatchlistTotalData: number = 0;
  noWatchlistData: boolean = false;
  noActiveWatchlistData: boolean = false;
  noPastWatchlistData: boolean = false;
  watchlistDataSource: MatTableDataSource<any>;
  activeWatchlistDataSource: MatTableDataSource<any>;
  pastWatchlistDataSource: MatTableDataSource<any>;
  AddedDate: string;
  watchlistDataLoading: boolean;
  pastWatchlistDataLoading: boolean;
  activeWatchlistDataLoading: boolean;
  waitNavTabName: string = 'Active Watchlist';
  activeOnly: boolean = true;
  watchTableLoading: boolean;
  isOnWatch: boolean;
  watchAddStatus: boolean = false;
  watchRemoveStatus: boolean = false;
  showDeets: boolean;
  watchComments: string;
  addBtnDisabled: boolean = true;
  selRemoveSpin;
  selAddSpin;
  selAdddc;
  selAddName;
  selAddComm;
  watchArray1 = [];
  watchArray2 = [];
  showPayDebt: boolean = false;
  removeComments: string;
  showWatchTables: boolean = true;
  commErr: boolean = false;
  errMsg1: boolean = false; //remove
  errMsg2: boolean = false; //add
  errMsg3: boolean = false; //addl

  showAssocName: string;
  exportData: any = [];

  activeExcel: any = [];
  pastExcel: any = [];

  selectedRegion = '';

  allCommPopup: boolean = false;
  allCommAdd: boolean = false;
  AllComments: string = "";
  watchlistComments: any = [];
  commentsSplit: any = [];
  notes: string;
  allCommAddBtnDis: boolean = true;
  allCommSpin;
  addlCommAdded: boolean = false;
  addlLoading: boolean = false;
  addlPop: boolean = false;

  //select var
  selectedView: number = 1;
  filterSelected: boolean;
  tableOverflow: boolean;

  paymentDataSource: MatTableDataSource<any>;
  debitDataSource: MatTableDataSource<DebitData>;

  //--------------------------------------------------------------------------------
  @ViewChild('paymentHistorySort', { read: MatSort }) paymentHistorySort: MatSort;
  @ViewChild('debitHistorySort', { read: MatSort }) debitHistorySort: MatSort;
  @ViewChild('paymentHistoryPaginator', { read: MatPaginator }) paymentHistoryPaginator: MatPaginator;
  @ViewChild('debitHistoryPaginator', { read: MatPaginator }) debitHistoryPaginator: MatPaginator;
  @ViewChild('pastWatchlistPaginator', { read: MatPaginator }) pastWatchlistPaginator: MatPaginator;
  @ViewChild('activeWatchlistPaginator', { read: MatPaginator }) activeWatchlistPaginator: MatPaginator;
  @ViewChild('pastWatchlistSort', { read: MatSort }) pastWatchlistSort: MatSort;
  @ViewChild('activeWatchlistSort', { read: MatSort }) activeWatchlistSort: MatSort;

  //-------------------------------------------------------------------

  constructor(private fb: FormBuilder, private router: Router, private appService: CommonService,
    private WatchlistService: WatchlistService,
    private appComponent: AppComponent, private payHistoryService: PaymentHistoryService,
    private _location: Location, private excelService: ExcelService) {

    $('html,body').animate({ scrollTop: -90 }, "fast");
    this.watchTableLoading = true;
  }

  ngOnInit() {
    console.log('clg admin reports',this.appService.role);
    if (this.appService.role != 'Lexus College' && this.appService.role != 'Lexus Associate' && this.appService.role != 'Area Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    this.getActiveWatchlist();
    this.WatchlistService.getWatchlistRecords(this.selectedRegion, '0').subscribe((response: any) => {
      this.pastWatchlistTotalData = response.Watchlist.Result.length;
    })
    if (this.appService.isAreaAdmin == true) {
      this.selectedRegion = this.appService.selectedRegion;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  allCommFn(row) {
    this.allCommAddBtnDis = true;
    this.addlCommAdded = false
    this.commentsSplit = [];
    this.notes = '';
    this.allCommPopup = true;
    this.allCommAdd = true;
    this.allCommSpin = row.spinId;
    this.isOnWatch = row.isOnWatch;
    this.WatchlistService.getWatchlistComments(row.spinId).subscribe((response: any) => {
      this.watchlistComments = response.WatchlistComments.Result
      if (this.watchlistComments.length != 0) {
        this.allCommAdd = false;
      for(let j = 0; j < this.watchlistComments.length; j++ ) {
        let obj = {
          "comment": this.watchlistComments[j].comments,
          "addedBy": this.watchlistComments[j].AddedBy,
          "addedOn": new Date(this.watchlistComments[j].AddedOn).toLocaleString("en-US"),
          "spinId": this.watchlistComments[j].spinId
        }
        this.commentsSplit.push(obj);
      }
    }
    else {
      this.allCommAdd = false;
      let obj = {
        "comment": "No previous Watchlist Comments found",
        "addedBy": " ",
        "addedOn": " "
      }
      this.commentsSplit.push(obj);
    }
    })
  }

  additionalComment() {
    //this.addlPop = true;
    this.addlCommAdded = false;
    this.addlLoading = true;
    let watch, del;
    if (this.isOnWatch == true) { watch = '1'; del = '0' }
    else { watch = '0', del = '1'}
    this.WatchlistService.updateWatchlistRecord(this.allCommSpin,this.notes,watch,del,this.appService.loggedInUserName,'additional').subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.addlCommAdded = true;
        this.addlLoading = false;
        this.notes = "";
        if (this.isOnWatch == true) { this.getActiveWatchlist(); }
        else if (this.isOnWatch == false) { this.getPastWatchlist(); }
      }
    })
  }

  async select(index: number) {
    this.showWatchTables = true;
    this.selectedView = index;
    this.filterSelected = false;
    this.tableOverflow = false;
    // index = 1 is for selecting the active hold tab
    if (index == 1) {
      await this.getActiveWatchlist();
      // change the breadcrumb name
      this.waitNavTabName = 'Active Watchlist';
      // index = 2 is for selecting the past hold tab
    } else if (index == 2) {
      await this.getPastWatchlist();
    }
  }
  async getActiveWatchlist() {
    this.activeWatchlist = [];
    this.activeWatchlistDataSource = new MatTableDataSource(this.activeWatchlist);
    this.showWatchTables = true;
    this.watchTableLoading = true;
    this.activeOnly = true;
    this.WatchlistService.getWatchlistRecords(this.selectedRegion, '1').subscribe((response: any) => {
      this.activeWatchlist = [];
      [].push.apply(this.activeWatchlist, response.Watchlist.Result);
      if (this.activeWatchlist.length != 0) {
        for (let i = 0; i < this.activeWatchlist.length; i++) {
          this.activeWatchlist[i].SpinId = this.activeWatchlist[i].spinId;
          this.activeWatchlist[i].Name = this.activeWatchlist[i].name;
          this.activeWatchlist[i].DealerCode = this.activeWatchlist[i].dealerCode;
          this.activeWatchlist[i].Addedby = this.activeWatchlist[i].AddedBy;
          const addOn = this.activeWatchlist[i].AddedOn.substring(0,10).replace(/-/g, '\/');
          this.activeWatchlist[i].Addedon = new Date (addOn).toLocaleDateString();
          this.activeWatchlist[i].Reason = this.activeWatchlist[i].reason;
        }
        this.noActiveWatchlistData = false;
      }
      else {
        this.watchTableLoading = false;
        this.activeWatchlistTotalData = 0;
        this.activeWatchlist = [];
        this.noActiveWatchlistData = true;
      }
      this.activeWatchlistTotalData = this.activeWatchlist.length;
      this.watchTableLoading = false;
      this.activeWatchlistDataSource = new MatTableDataSource(this.activeWatchlist);
      this.activeExcel = [];
      for (let k=0; k<this.activeWatchlist.length; k++) {
        var ob = {
          SpinId: this.activeWatchlist[k].SpinId,
          Name: this.activeWatchlist[k].Name,
          DealerCode: this.activeWatchlist[k].DealerCode,
          Addedby: this.activeWatchlist[k].Addedby,
          Addedon: this.activeWatchlist[k].Addedon,
          Reason: this.activeWatchlist[k].reason
        }
        this.activeExcel.push(ob);
      }
      this.activeWatchlistDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'AddedDate': return new Date(item.Addedon);
          default: return item[property];
        }
      };
      this.activeWatchlistDataSource.sort = this.activeWatchlistSort;
      this.activeWatchlistDataSource.paginator = this.activeWatchlistPaginator;
      //this.activeWatchlistDataLoading = false;
      this.watchTableLoading = false;
    }, err => {
      this.watchTableLoading = false;
      this.activeWatchlistTotalData = 0;
      this.activeWatchlist = [];
      this.noActiveWatchlistData = true;
    });
    if (this.activeWatchlistTotalData > 10) {
      this.tableOverflow = true;
    } else {
      this.tableOverflow = false;
    }
    this.activeWatchlistTotalData == 0 ? this.noActiveWatchlistData = true : this.noActiveWatchlistData = false;

  }
  async getPastWatchlist() {
    this.showWatchTables = true;
    this.watchTableLoading = true;
    this.activeOnly = false;
    this.pastWatchlist = [];
    this.pastWatchlistDataSource = new MatTableDataSource(this.pastWatchlist);
    this.WatchlistService.getWatchlistRecords(this.selectedRegion, '0').subscribe((response: any) => {
      this.pastWatchlist = [];
      [].push.apply(this.pastWatchlist, response.Watchlist.Result);
      if (this.pastWatchlist.length != 0) {
        for (let i = 0; i < this.pastWatchlist.length; i++) {
          this.pastWatchlist[i].SpinId = this.pastWatchlist[i].spinId;
          this.pastWatchlist[i].Name = this.pastWatchlist[i].name;
          this.pastWatchlist[i].DealerCode = this.pastWatchlist[i].dealerCode;
          if (this.pastWatchlist[i].DeletedBy != null) {
          this.pastWatchlist[i].Deletedby = this.pastWatchlist[i].DeletedBy; }
          else {this.pastWatchlist[i].Deletedby = '' ;}
          if (this.pastWatchlist[i].DeletedOn != null) {
          const delOn = this.pastWatchlist[i].DeletedOn.substring(0,10).replace(/-/g, '\/');
          this.pastWatchlist[i].Deletedon = new Date (delOn).toLocaleDateString(); }
          else {this.pastWatchlist[i].Deletedon = '' ;}
          this.pastWatchlist[i].Reason = this.pastWatchlist[i].reason;
        }
        this.noPastWatchlistData = false;
      }
      else {
        this.watchTableLoading = false;
        this.pastWatchlistTotalData = 0;
        this.pastWatchlist = [];
        this.noPastWatchlistData = true;
      }
      this.pastWatchlistTotalData = this.pastWatchlist.length;
      this.watchTableLoading = false;
      this.pastWatchlistDataSource = new MatTableDataSource(this.pastWatchlist);
      this.pastExcel = [];
      for (let k=0; k<this.pastWatchlist.length; k++) {
        var ob = {
          SpinId: this.pastWatchlist[k].SpinId,
          Name: this.pastWatchlist[k].Name,
          DealerCode: this.pastWatchlist[k].DealerCode,
          Removedby: this.pastWatchlist[k].Deletedby,
          Removedon: this.pastWatchlist[k].Deletedon,
          Reason: this.pastWatchlist[k].reason
        }
        this.pastExcel.push(ob);
      }
     this.pastWatchlistDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'AddedDate': return new Date(item.Deletedon);
          default: return item[property];
        }
      };
      this.pastWatchlistDataSource.sort = this.pastWatchlistSort;
      this.pastWatchlistDataSource.paginator = this.pastWatchlistPaginator;
      this.watchTableLoading = false;
    }, err => {
      this.watchTableLoading = false;
      this.pastWatchlistTotalData = 0;
      this.pastWatchlist = [];
      this.noPastWatchlistData = true;
    });
    if (this.pastWatchlistTotalData > 10) {
      this.tableOverflow = true;
    } else {
      this.tableOverflow = false;
    }
    this.pastWatchlistTotalData == 0 ? this.noPastWatchlistData = true : this.noPastWatchlistData = false;
    // this.pastWatchlistDataSource.sort = this.pastWatchlistSort;
    // this.pastWatchlistDataSource.paginator = this.pastWatchlistPaginator;
    this.pastWatchlistDataLoading = false;
  }
  applyFilter(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.activeWatchlistDataSource.filter = filterValue.trim().toLowerCase();
    this.exportData.push(this.activeWatchlistDataSource.filteredData);
    if (filterValue != '' || this.activeWatchlistDataSource.filteredData.length < 10) {
      $("#holdHistorTable").removeClass('tableOverflow');
    } else {
      $("#holdHistorTable").addClass('tableOverflow');
    }
  }
  applyFilterPast(filterValue: string) {
    this.exportData = [];
    if (filterValue == "") {
      this.filterSelected = false;
    } else {
      this.filterSelected = true;
    }
    this.pastWatchlistDataSource.filter = filterValue.trim().toLowerCase();
    this.exportData.push(this.pastWatchlistDataSource.filteredData);
    if (filterValue != '' || this.pastWatchlistDataSource.filteredData.length < 10) {
      $("#holdHistorTable2").removeClass('tableOverflow');
    } else {
      $("#holdHistorTable2").addClass('tableOverflow');
    }
  }

  omit_characters(event, type) {
    if (event.key == ';' || event.key == '#' || event.key == '=' || event.key == '<' || event.key == '>' ||
      event.key == '!') {
      if (type == 'remove') {
        this.errMsg1 = true;
        setTimeout(() => {
          this.errMsg1 = false;
        }, 4000)
      }
      else if (type == 'add') {
        this.errMsg2 = true;
        setTimeout(() => {
          this.errMsg2 = false;
        }, 4000)
      }
      else if (type == 'addl') {
        this.errMsg3 = true;
        setTimeout(() => {
          this.errMsg3 = false;
        }, 4000)
      }
    }
  }
  async preRemove(index, row) {
    this.commErr = false;
    this.watchRemoveStatus = false;
    this.loadSpinner = false; // Dont remove - highly important 
    this.watchArray1 = [];
    this.selRemoveSpin = row.SpinId;
    let watchAddArray = [];
    this.activeWatchlist.forEach(function (watchData) {
      if (watchData.Name == row.Name && watchData.SpinId == row.SpinId && watchData.DealerCode == row.DealerCode && watchData.Reason == row.Reason) {
        let obj = {
          'Name': row.Name.toUpperCase(),
          'SpinId': row.SpinId,
          'DealerCode': row.DealerCode,
          'Reason': row.Reason.toUpperCase()
        }
        watchAddArray.push(obj);
      }
    });
    this.watchArray1 = watchAddArray;
    console.log("watchArray1 for Modal :", this.watchArray1);
  }

  preAdd(index, row) {
    this.commErr = false;
    this.watchAddStatus = false; //important
    this.loadSpinner = false; // Dont remove - highly important 
    this.watchArray2 = [];
    this.selAddSpin = row.SpinId;
    this.selAdddc = row.DealerCode;
    this.selAddName = row.Name;
    let watchAddArray = [];
    this.pastWatchlist.forEach(function (watchData) {
      if (watchData.Name == row.Name && watchData.SpinId == row.SpinId && watchData.DealerCode == row.DealerCode && watchData.Reason == row.Reason) {
        let obj = {
          'Name': row.Name.toUpperCase(),
          'SpinId': row.SpinId,
          'DealerCode': row.DealerCode,
          'Reason': row.Reason.toUpperCase()
        }
        watchAddArray.push(obj);
      }
    });
    this.watchArray2 = watchAddArray;
    console.log("watchArray2 for Modal :", this.watchArray2);
  }

  ReAddToWatchlist() {
    this.commErr = false;
    this.loadSpinner = true;
    this.watchAddStatus = false;
    var spinid = this.selAddSpin;
    var name = this.selAddName;
    var dealerno = this.selAdddc;
    var comment = this.watchComments;
    var addedBy = this.appService.loggedInUserName;
    const type = 'readd';
    this.WatchlistService.updateWatchlistRecord(spinid, comment, '1', '0', addedBy, type).subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.watchAddStatus = true;
        this.loadSpinner = false;
        this.watchComments = "";
        this.getPastWatchlist();
        this.WatchlistService.getWatchlistRecords(this.selectedRegion, '1').subscribe((response: any) => {
          this.activeWatchlistTotalData = response.Watchlist.Result.length;
        })
      }
    }, err => {
      this.commErr = true;
      console.log("Error in ReAdding to watchlist")
      this.loadSpinner = false;
      this.watchAddStatus = false;
    })
    this.watchAddStatus = false;
  }

  GetPayDebtHistory(row) {
    this.showWatchTables = false; //to hide past and active watchlist tables
    this.showPayDebt = true; //to show pay debt history table
    this.tableDisplay = true;
    this.showAssocName = row.Name;
    this.getPaymentHistoryDetail(row.SpinId, row.DealerCode);
    this.getDebitHistoryDetail(row.SpinId);
  }

  removeWatchlist() {
    this.commErr = false;
    this.loadSpinner = true;
    this.watchRemoveStatus = false;
    var spin = this.selRemoveSpin;
    var comment = this.removeComments;
    var deletedBy = this.appService.loggedInUserName;

    const type = 'removal';
    this.WatchlistService.updateWatchlistRecord(spin, comment, '0', '1', deletedBy, type).subscribe((response: any) => {
      if (response.RowUpdated.rowCount >= 0) {
        this.loadSpinner = false;
        this.watchRemoveStatus = true;
        this.removeComments = "";
        this.getActiveWatchlist();
        this.WatchlistService.getWatchlistRecords(this.selectedRegion, '0').subscribe((response: any) => {
          this.pastWatchlistTotalData = response.Watchlist.Result.length;
        })
      }
    }, err => {
      this.commErr = true;
      console.log("Error in Removing watchlist record")
      this.loadSpinner = false;
      this.watchRemoveStatus = false;
    })
    //this.loadSpinner = false;
    this.watchRemoveStatus = false;
  }

  validateCommentAdd() {
    if (this.watchComments.trim() == '') {
      this.debitBtnDisabled = true;
      $('#watchComments').addClass('warningDiv');
      $('#watchComments').focus();
    } else {
      this.debitBtnDisabled = false;
      $('#watchComments').removeClass('warningDiv');
    }
  }
  validateCommentRemove() {
    if (this.removeComments.trim() == '') {
      this.debitBtnDisabled = true;
      $('#watchComments').addClass('warningDiv');
      $('#watchComments').focus();
    } else {
      this.debitBtnDisabled = false;
      $('#watchComments').removeClass('warningDiv');
    }
  }
  validateCommentNotes() {
    if (this.notes.trim() == '') {
      this.allCommAddBtnDis = true;
      $('#notes').addClass('warningDiv');
      $('#notes').focus();
    } else {
      this.allCommAddBtnDis = false;
      $('#notes').removeClass('warningDiv');
    }
  }

  getPaymentHistoryDetail(spinId, DealerCode) { // Payment history details 
    this.spinnos = '';
    this.dealercode = '';
    this.paymentTableLoading = true;
    this.PAYMAINDATA = [];
    this.paymentHistoryResponse = '';
    this.payHistoryService.getPayHistoryDataSpinId(spinId).subscribe((response: any) => {
      this.paymentHistoryResponse = response.PaymentHistory.Result;
      this.PAYMAINDATA = [];
      [].push.apply(this.PAYMAINDATA, this.paymentHistoryResponse);
      if (this.PAYMAINDATA.length != 0) {
        // this.PAYMENTDATA = [];
        this.spinnos = '104' + this.PAYMAINDATA[0].uid;
        this.dealercode = this.PAYMAINDATA[0].dealerCode;
        // let obj, month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        for (let i = 0; i < this.PAYMAINDATA.length; i++) {
          // let paydueDate = this.PAYMAINDATA[i].payDueCd;
          this.PAYMAINDATA[i].dealerCodeNo = this.PAYMAINDATA[i].dealerCode;
          this.PAYMAINDATA[i].lfspaycode = this.PAYMAINDATA[i].lfsPayCd;
          this.PAYMAINDATA[i].paymentPeriod = this.PAYMAINDATA[i].payDueCd;
          this.PAYMAINDATA[i].paymentDate = this.PAYMAINDATA[i].lfsRunDtp;
          this.PAYMAINDATA[i].invoiceNo = this.PAYMAINDATA[i].invoice;
        }
        this.paymentTotalData = this.PAYMAINDATA.length;
      } else {
        this.PAYMAINDATA = [];
        this.paymentNoData = true;
        this.paymentTotalData = 0;
      };
      this.paymentDataSource = new MatTableDataSource(this.PAYMAINDATA);
      this.paymentDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'paymentDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.paymentDataSource.sort = this.paymentHistorySort;
      this.paymentDataSource.paginator = this.paymentHistoryPaginator;
      this.paymentTableLoading = false;
      this.spinId = this.nameAssocaite;
    }, error => {
      this.paymentNoData = true;
      this.paymentTotalData = 0;
      this.paymentTableLoading = false;
      console.log('Error', error);
      this.spinId = this.nameAssocaite;
    });
  }

  getDebitHistoryDetail(spinId) { // Debit History table
    this.DEBITDATA = [];
    this.DEBIT_DATA = [];
    this.debitHistoryResponse = '';
    this.debitTableLoading = true;
    this.payHistoryService.getDebitHistoryDataSpinId(spinId).subscribe((response: any) => {
      this.debitHistoryResponse = response.DebitHistory.Result;
      this.DEBIT_DATA = [];
      [].push.apply(this.DEBIT_DATA, this.debitHistoryResponse);
      if (this.DEBIT_DATA.length != 0) {
        this.DEBITDATA = [];
        this.debitNoData = false;
        // this.spinnos = '104' + this.DEBIT_DATA[0].uid;
        let obj, month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        for (let i = 0; i < this.DEBIT_DATA.length; i++) {
          let paydueDate = this.DEBIT_DATA[i].payDueCd;
          obj = {
            // 'payduecode': this.PAYMAINDATA[i].payDueCd ,
            'dealerCode': this.DEBIT_DATA[i].dealerCode,
            'lfspaycode': this.DEBIT_DATA[i].lfsPayCd,
            'paymentPeriod': this.DEBIT_DATA[i].payDueCd,
            'paymentDate': this.DEBIT_DATA[i].lfsRunDtp,
            'debitDate': this.DEBIT_DATA[i].debitDate,
            'comment': this.DEBIT_DATA[i].comment,
            'status': this.DEBIT_DATA[i].status
          }
          this.DEBITDATA.push(obj);
        }
        this.debitTotalData = this.DEBITDATA.length;
      } else {
        this.debitNoData = true;
        this.DEBIT_DATA = [];
        this.debitTotalData = 0;
      }
      this.debitDataSource = new MatTableDataSource(this.DEBITDATA);
      console.log(this.debitDataSource);
      this.debitDataSource.sort = this.debitHistorySort;
      this.debitDataSource.paginator = this.debitHistoryPaginator;
      this.debitDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'paymentDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.debitDataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'debitDate': return new Date(item.paymentDate);
          default: return item[property];
        }
      };
      this.debitTableLoading = false;
    }, err => {
      console.log('Error', err);
      this.debitTableLoading = false;
      this.debitNoData = true;
      this.debitTotalData = 0;
      this.DEBIT_DATA = [];
    });
  }

  navigateBack() {
    this.showWatchTables = true;
    this.showPayDebt = false;
    this.getActiveWatchlist();
    this.WatchlistService.getWatchlistRecords(this.selectedRegion, '0').subscribe((response: any) => {
      this.pastWatchlistTotalData = response.Watchlist.Result.length;
    })
    if (window.location.href.indexOf("localhost") != -1) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '#/watchlist';
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      this._location.go('watchlist');
    }
    this.tableDisplay = false;
  }

  fadeDiv() { // make the table section is opacity to 0.5
    this.spinIdClicked = true;
  }

  fadeOut() { // make the table section is opacity to 1
    this.spinIdClicked = false;
  }

  closeSpinSearch() { // make the table section is opacity to 0.5 and close the search
    this.spinId = "";
    this.spinIdClicked = true;
  }

  paymentapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.paymentDataSource.filter = filterValue;
    this.paymentDataSource.sort = this.paymentHistorySort;
    this.paymentDataSource.paginator = this.paymentHistoryPaginator;
  }

  debitapplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.debitDataSource.filter = filterValue;
    this.debitDataSource.sort = this.debitHistorySort;
    this.debitDataSource.paginator = this.debitHistoryPaginator;
  }

  exportSummary() {
    this.noExcelData = false;
    if (this.tabName == 'Active Watchlist') {
      if (!this.noActiveWatchlistData) {
        this.excelService.exportAsExcelFile(this.activeExcel, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Past Watchlist') {
      if (!this.noPastWatchlistData) {
        this.excelService.exportAsExcelFile(this.pastExcel, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Payment History') {
      if (!this.paymentNoData) {
        this.excelService.exportAsExcelFile(this.paymentDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    } else if (this.tabName == 'Debit History') {
      if (!this.debitNoData) {
        this.excelService.exportAsExcelFile(this.debitDataSource.data, this.tabName);
      } else {
        this.noExcelData = true;
      }
    }
  }
}
