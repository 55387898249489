import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UnenrollService } from '../unenroll-assosiate/unenroll.service';
import { CommonService } from '../service/common.service';
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AppComponent } from '../app.component';
import { ExcelService } from '../service/excel.service';
import { PaymentReportsService } from './payment-reports.service';
declare var $: any;
import { Router } from '@angular/router';

export interface PaymentReportData {
  associate_name: string;
  spin_id: string;
  dealer_code: string;
  lfspaycd: string;
  payduecd: string;
  amount: string;
  status: string;
  usermail: string;
  paymentcreatedon: string;
  status_pp: string;
  hold_start_date: string;
  hold_end_date: string;
}

@Component({
  selector: 'app-payment-reports',
  templateUrl: './payment-reports.component.html',
  styleUrls: ['./payment-reports.component.css']
})
export class PaymentReportsComponent implements OnInit {

  //Material Table
  ctPaymentReportDataSource: MatTableDataSource<PaymentReportData>;
  ctPaymentReportColumns = ['name', 'spin_id', 'lfspaycode', 'payduecode', 'month', 'amount'];
  notPaidPaymentReportDataSource: MatTableDataSource<PaymentReportData>;
  notPaidPaymentReportColumns = ['name', 'spin_id', 'month', 'reason'];
  holdPaymentReportDataSource: MatTableDataSource<PaymentReportData>;
  holdPaymentReportColumns = ['name', 'spin_id', 'month', 'startperiod', 'endperiod'];

  //------------- Paginator and Sort @ViewChild-----------------
  @ViewChild('ctPayReportSort', { read: MatSort }) ctPayReportSort: MatSort;
  @ViewChild('ctPayReportPaginator', { read: MatPaginator }) ctPayReportPaginator: MatPaginator;
  @ViewChild('notPaidPayReportSort', { read: MatSort }) notPaidPayReportSort: MatSort;
  @ViewChild('notPaidPayReportPaginator', { read: MatPaginator }) notPaidPayReportPaginator: MatPaginator;
  @ViewChild('holdPayReportSort', { read: MatSort }) holdPayReportSort: MatSort;
  @ViewChild('holdPayReportPaginator', { read: MatPaginator }) holdPayReportPaginator: MatPaginator;

  //BreadCrumbs Variables
  dasboardTab: boolean = false;

  //Table Variables
  panelOpenState: boolean;
  tableDisplay: boolean = true;
  paymentReportTableLoading: boolean = false;
  ctPaymentReportNoData: boolean = false;
  notPaidPaymentReportNoData: boolean = false;
  holdPaymentReportNoData: boolean = false;
  paymentReportResponse: any = '';
  PAYMENTREPORTDATA: PaymentReportData[] = [];
  CTPAYREPORTDATA: any = [];
  NOTPAYREPORTDATA: any = [];
  HOLDPAYREPORTDATA: any = [];
  ctPaymentReportTotalData: number = 0;
  notPaidPaymentReportTotalData: number = 0;
  holdPaymentReportTotalData: number = 0;
  noExcelData: boolean = false;

  //Info Variable
  months: any = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  monthInNum: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  years: any = [];
  startMonthList: any = [];
  endMonthList: any = [];
  startYrList: any = [];
  endYrList: any = [];
  d: any = new Date();
  dateString: any = new Date().toDateString();
  startMon: string = this.months[this.d.getMonth() - 1];
  startYr: string = this.d.getFullYear();
  endMon: string = this.months[this.d.getMonth()];
  endYr: string = this.d.getFullYear();
  dateValidationError: boolean = false;
  startgreaterThanPresentDate: boolean = false;
  endgreaterThanPresentDate: boolean = false;
  spinnos: string;
  nameAssociate: string;
  startdate: string;
  enddate: string;
  tableName: string;
  generateReport: boolean = true;

  //Pdf Variables
  checkChrome: boolean;
  pdfLoading: boolean;
  fileUrl: any;
  fileBlob: any;
  dealername: string = this.appService.dealerName;
  dealercode: string = this.appService.dc;
  // paidData: string;
  // notPaidData: string;
  // holdData: string;
  pdfResponse: any = [];

  constructor(private fb: FormBuilder, private appService: CommonService, private payReportService: PaymentReportsService,
    private appComponent: AppComponent, private excelService: ExcelService, private router: Router) {

  }

  ngOnInit() {
    console.log('pc report',this.appService.role);
    if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || 
    this.appService.role == 'Area Admin' || this.appService.role == 'Billing' || this.appService.role == 'Admin') {
      this.appService.unauthorizedErrorMsg = "Sorry, you are not authorized to view this page. To request access, please contact Lexus Support at pcsupport@jacksondawson.com / LexusMasterLeaseSupport@lexus.com";
      this.router.navigate(['unauthorized']);
    }
    var ua = window.navigator.userAgent;
    var isIE = /MSIE|Trident/.test(ua);

    if (isIE) { // IE Browser
      this.checkChrome = false;
    }
    else {
      this.checkChrome = true;
    }
    if (this.endMon == 'JAN') { this.startYr = this.d.getFullYear() + (-1); this.startMon = this.months[11]; }
    this.startMonthList = [];
    this.endMonthList = [];
    this.startYrList = [];
    this.endYrList = [];
    for (let i = -5; i <= 0; i++) {
      this.startMonthList.push(this.months[this.addMonths(this.dateString, i).getMonth()]);
      this.startYrList.push(this.addMonths(this.dateString, i).getFullYear());
    }
    this.startYrList = this.startYrList.filter(function (elem, index, self) { return index === self.indexOf(elem); })
    for (let i = -5; i <= 0; i++) {
      this.endMonthList.push(this.months[this.addMonths(this.dateString, i).getMonth()]);
      this.endYrList.push(this.addMonths(this.dateString, i).getFullYear());
    }
    this.endYrList = this.endYrList.filter(function (elem, index, self) { return index === self.indexOf(elem); })
    // this.getPaymentReportDetail();
  }

  // =========================================PERIOD SELECTION & VALIDATION=============================================

  getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  addMonths(input, months) {
    var date = new Date(input);
    date.setDate(1);
    date.setMonth(date.getMonth() + months);
    date.setDate(Math.min(date.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth() + 1)));
    return date
  }

  monthDiff(d1, d2) {
    var months;
    months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    months -= new Date(d1).getMonth();
    months += new Date(d2).getMonth();
    return months;
  }

  // method to validate the input field in the period section
  validateHoldDetails() {
    this.generateReport = true;
    var endDate = '';
    var validateStart = Date.parse("01 " + this.startMon + " " + this.startYr); // selected start date in the form of Date
    var dates = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31']; // dates array with end date of all the months in the year
    if (this.endMon == 'FEB') { // this is to determine the last date of the feb month
      var yr = parseInt(this.endYr);
      if (yr % 4 == 0) { // leap year
        endDate = '29 ' + this.endMon + ' ' + this.endYr; // selected end date of leap year Feb Month
      } else {
        endDate = '28 ' + this.endMon + ' ' + this.endYr; // selected end date of non-leap year Feb Month
      }
    } else {
      var index = this.months.indexOf(this.endMon);
      endDate = dates[index] + ' ' + this.endMon + ' ' + this.endYr; // selected end date 
    }
    var validateEnd = Date.parse(endDate); // selected end date in the form of Date

    if (validateStart > validateEnd) { // if the start date is greater than the end date then its an error
      this.dateValidationError = true;
    } else {
      this.dateValidationError = false;
    }

    var todayDate = Date.parse('01 ' + this.months[this.d.getMonth()] + ' ' + this.d.getFullYear()); // present day date in the form of Date

    if ((validateStart > todayDate) || (this.monthDiff(validateStart, todayDate) > 6)) { // if the start date is greater than the today date then its an error
      this.startgreaterThanPresentDate = true;
    } else {
      this.startgreaterThanPresentDate = false;
    }

    var validateEnd = Date.parse("01 " + this.endMon + " " + this.endYr); // selected end date in the form of Date

    if ((validateEnd > todayDate) || (this.monthDiff(validateEnd, todayDate) > 6)) { // if the start date is greater than the today date then its an error
      this.endgreaterThanPresentDate = true;
    } else {
      this.endgreaterThanPresentDate = false;
    }

    // console.log(todayDate);
    // console.log(validateStart);
    // console.log(validateEnd);
    // console.log(this.monthDiff(validateStart, todayDate));
    // console.log(this.monthDiff(validateEnd, todayDate));

  }

  // =========================================TABLE & TABLE FILTER=============================================
  getPaymentReportDetail() {
    this.tableDisplay = false;
    this.ctPaymentReportNoData = false;
    this.notPaidPaymentReportNoData = false;
    this.holdPaymentReportNoData = false;
    this.paymentReportTableLoading = true;
    this.PAYMENTREPORTDATA = [];
    this.CTPAYREPORTDATA = [];
    this.NOTPAYREPORTDATA = [];
    this.HOLDPAYREPORTDATA = [];
    // this.ctPaymentReportDataSource = new MatTableDataSource(this.CTPAYREPORTDATA);
    // this.notPaidPaymentReportDataSource = new MatTableDataSource(this.NOTPAYREPORTDATA);
    // this.holdPaymentReportDataSource = new MatTableDataSource(this.HOLDPAYREPORTDATA);
    this.paymentReportResponse = '';
    this.startdate = this.startYr + '-' + this.monthInNum[this.months.indexOf(this.startMon)];
    this.enddate = this.endYr + '-' + this.monthInNum[this.months.indexOf(this.endMon)];
    // console.log("Start and End : ", this.startdate, "|", this.enddate);
    this.payReportService.getPayReportData(this.appService.dc, this.startdate, this.enddate).subscribe((response: any) => {
      this.paymentReportResponse = response.PaymentSummaryReport.Result;
      console.log("Payment Report Data : ", this.paymentReportResponse);
      [].push.apply(this.PAYMENTREPORTDATA, this.paymentReportResponse);
      this.generateReport = false;
      if (this.PAYMENTREPORTDATA) {
        for (let i = 0; i < this.PAYMENTREPORTDATA.length; i++) {
          if (this.PAYMENTREPORTDATA[i].status.toUpperCase() == "NOTPAID") {
            this.NOTPAYREPORTDATA.push(this.PAYMENTREPORTDATA[i]);
          }
          if (this.PAYMENTREPORTDATA[i].status.toUpperCase() == "PAID") {
            this.CTPAYREPORTDATA.push(this.PAYMENTREPORTDATA[i]);
          }
          if (this.PAYMENTREPORTDATA[i].status.toUpperCase() == "HOLD") {
            this.HOLDPAYREPORTDATA.push(this.PAYMENTREPORTDATA[i]);
          }
        }
        if (this.CTPAYREPORTDATA.length != 0) {
          this.ctPaymentReportTotalData = this.CTPAYREPORTDATA.length;
        } else {
          this.ctPaymentReportNoData = true;
          this.ctPaymentReportTotalData = 0;
        };
        if (this.NOTPAYREPORTDATA.length != 0) {
          this.notPaidPaymentReportTotalData = this.NOTPAYREPORTDATA.length;
        } else {
          this.notPaidPaymentReportNoData = true;
          this.notPaidPaymentReportTotalData = 0;
        };
        if (this.HOLDPAYREPORTDATA.length != 0) {
          this.holdPaymentReportTotalData = this.HOLDPAYREPORTDATA.length;
        } else {
          this.holdPaymentReportNoData = true;
          this.holdPaymentReportTotalData = 0;
        };
        this.ctPaymentReportDataSource = new MatTableDataSource(this.CTPAYREPORTDATA);
        this.ctPaymentReportDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'paymentcreatedon': return new Date(item.paymentcreatedon);
            default: return item[property];
          }
        };
        this.notPaidPaymentReportDataSource = new MatTableDataSource(this.NOTPAYREPORTDATA);
        this.notPaidPaymentReportDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'paymentcreatedon': return new Date(item.paymentcreatedon);
            default: return item[property];
          }
        };
        this.holdPaymentReportDataSource = new MatTableDataSource(this.HOLDPAYREPORTDATA);
        this.holdPaymentReportDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'paymentcreatedon': return new Date(item.paymentcreatedon);
            default: return item[property];
          }
        };
        this.holdPaymentReportDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'hold_start_data': return new Date(item.hold_start_date);
            default: return item[property];
          }
        };
        this.holdPaymentReportDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'hold_end_date': return new Date(item.hold_end_date);
            default: return item[property];
          }
        };
        this.ctPaymentReportDataSource.sort = this.ctPayReportSort;
        this.notPaidPaymentReportDataSource.sort = this.notPaidPayReportSort;
        this.holdPaymentReportDataSource.sort = this.holdPayReportSort;
        this.ctPaymentReportDataSource.paginator = this.ctPayReportPaginator;
        this.notPaidPaymentReportDataSource.paginator = this.notPaidPayReportPaginator;
        this.holdPaymentReportDataSource.paginator = this.holdPayReportPaginator;
        this.paymentReportTableLoading = false;
      }
    }, error => {
      this.ctPaymentReportNoData = true;
      this.ctPaymentReportTotalData = 0;
      this.notPaidPaymentReportNoData = true;
      this.notPaidPaymentReportTotalData = 0;
      this.holdPaymentReportNoData = true;
      this.holdPaymentReportTotalData = 0;
      this.paymentReportTableLoading = false;
      console.log('Error', error);
      console.log('Error While getting data for the Payment Summary Table');
    });
  };

  ctPaymentReportFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.ctPaymentReportDataSource.filter = filterValue;
    this.ctPaymentReportDataSource.paginator = this.ctPayReportPaginator;
    this.ctPaymentReportDataSource.sort = this.ctPayReportSort;
  }

  notPaidPaymentReportFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.notPaidPaymentReportDataSource.filter = filterValue;
    this.notPaidPaymentReportDataSource.paginator = this.notPaidPayReportPaginator;
    this.notPaidPaymentReportDataSource.sort = this.notPaidPayReportSort;
  }

  holdPaymentReportFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.holdPaymentReportDataSource.filter = filterValue;
    this.holdPaymentReportDataSource.paginator = this.holdPayReportPaginator;
    this.holdPaymentReportDataSource.sort = this.holdPayReportSort;
  }

  // ===========================================DOWNLOAD FILE================================================

  pdfDownload() {
    this.pdfLoading = true;
    this.pdfResponse = [];
    this.fileBlob = '';
    this.fileUrl = '';
    this.startdate = this.startYr + '-' + this.monthInNum[this.months.indexOf(this.startMon)];
    this.enddate = this.endYr + '-' + this.monthInNum[this.months.indexOf(this.endMon)];
    console.log("Start and End : ", this.startdate, "|", this.enddate);
    this.payReportService.createPaymentReportPdf(this.dealercode, this.dealername, this.startdate, this.enddate).toPromise().then((response: any) => {
      // this.pdfResponse = response.PaymentReportPdf.ResultPdf;
      var byteArray = new Uint8Array(atob(response.PaymentReportPdf.ResultPdf).split('').map(char => char.charCodeAt(0)));
      // var byteArray = encodeURIComponent(response.PaymentReportPdf.ResultPdf);
      var file = new Blob([byteArray], {type: 'application/pdf'});
      var fileURL = window.URL.createObjectURL(file);
      this.pdfLoading = false;
      this.fileUrl = fileURL;
      this.fileBlob = file;
      if (this.checkChrome) {
        var downloadUrl = window.URL.createObjectURL(this.fileBlob);
        console.log(downloadUrl);
        var a = document.createElement('a');
        a.href = downloadUrl;
        a.download = "PaymentSummaryReport - " + this.startMon + this.startYr + " to " + this.endMon + this.endYr;
        document.body.appendChild(a);
        a.click();
      } else {
        let type = this.fileBlob.type.split('/');
        var name = "PaymentSummaryReport - " + this.startMon + this.startYr + " to " + this.endMon + this.endYr;
        window.navigator.msSaveOrOpenBlob(this.fileBlob, name);
      }
    }, err => {
      this.pdfLoading = false;
      this.fileBlob = '';
      this.fileUrl = '';
      console.log("Error in Pdf Creation and download");
    });
    
  }

  // ===========================================EXPORT EXCEL FILE================================================
  exportFile() {
    this.noExcelData = false;
    console.log(this.noExcelData, '- Excel download check -', this.tableName);
    if (this.tableName == "paid" && !this.ctPaymentReportNoData) {
      let holdData = [];
      this.ctPaymentReportDataSource.data.forEach(data => {
        let obj = {
          "Associate_Name": data.associate_name,
          "Spin_Id": data.spin_id,
          "Period": data.paymentcreatedon,
          "Lfspaycd": data.lfspaycd,
          "Payduecd": data.payduecd,
          "Amount": data.amount
        }
        holdData.push(obj);
      });
      this.excelService.exportAsExcelFile(holdData, 'Paid_Payment_Report');
      this.noExcelData = false;
    }
    else if (this.tableName == "notpaid" && !this.notPaidPaymentReportNoData) {
      let holdData = [];
      this.notPaidPaymentReportDataSource.data.forEach(data => {
        let obj = {
          "Associate_Name": data.associate_name,
          "Spin_Id": data.spin_id,
          "Period": data.paymentcreatedon,
          "Reason": data.status_pp
        }
        holdData.push(obj);
      });
      this.excelService.exportAsExcelFile(holdData, 'NotPaid_Payment_Report');
      this.noExcelData = false;
    }
    else if (this.tableName == "hold" && !this.holdPaymentReportNoData) {
      let holdData = [];
      this.holdPaymentReportDataSource.data.forEach(data => {
        let obj = {
          "Associate_Name": data.associate_name,
          "Spin_Id": data.spin_id,
          "Period": data.paymentcreatedon,
          "Hold_start_date": data.hold_start_date,
          "Hold_end_date": data.hold_end_date
        }
        holdData.push(obj);
      });
      this.excelService.exportAsExcelFile(holdData, 'Hold_Payment_Report');
      this.noExcelData = false;
    }
    else {
      this.noExcelData = true;
    }
    console.log(this.noExcelData);
  }

}

