import { Router } from '@angular/router';
import { Injectable, ElementRef, ViewChild } from '@angular/core';
import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { authConfig } from './auth-config';
import { CommonService } from '../service/common.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { LoginService } from '../login/login.service';
import { Idle } from '@ng-idle/core';
import { EnrollService } from '../enroll-assosiate/enroll-assosiate.service';
import { Location } from '@angular/common';
import { JoyrideService } from 'ngx-joyride';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  your_date: Date;
  hideNotificationDiv: boolean = true;
  emptyArray: boolean = false;
  clearAllNotifications: boolean = true;
  displayArray: any[];
  profilecard: boolean = true;
  dasboardTab: boolean = false;
  batchJob: boolean = false;
  Report: boolean = false;
  accessControl: boolean = false;
  enrollTab: boolean = false;
  unenrollTab: boolean = false;
  watchlistTab: boolean = false;
  billing: boolean = false;
  payHistoryTab: boolean = false;
  payReportsTab: boolean = false;
  searchAssociateTab: boolean = false;
  switchRole: string;
  pcOrPrincipal: string;
  switchToPc = 'SWITCH TO 60403 - PC';
  switchToPrincipal = 'SWITCH TO 60403 - PRINCIPAL';
  adminRole: string;
  unsaved = false;
  navigateTopage = '';
  newMessages: number;
  displayMessageCount: string;
  localI = 0

  //Login Component
  loginDetailResponse: any;
  exp: any;
  userName: string;
  currentUser: any;
  loggedIn: string;
  invalidUser: Boolean = false;

  @ViewChild('side_full', { read: ElementRef }) side_full: ElementRef;
  @ViewChild('Corp_sidefull', { read: ElementRef }) Corp_sidefull: ElementRef;
  @ViewChild('Admin_sidefull', { read: ElementRef }) Admin_sidefull: ElementRef;

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  constructor(private oauthService: OAuthService, private router: Router, private appService: CommonService,
    private dashboardService: DashboardService, private loginService: LoginService, private idle: Idle,
    public enrollService: EnrollService, private _location: Location, private joyrideService: JoyrideService) {
    console.log("Auth Service Started");
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else {
        console.warn(event);
      }
    });

    window.addEventListener('storage', (event) => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });
    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());


    // this.oauthService.events
    //   .pipe(filter(e => ['token_expires'].includes(e.type)))
    //   .subscribe(e => {
    //     this.oauthService.silentRefresh();
    //   });

    this.oauthService.events
      .pipe(filter((e: any) => e.type === 'invalid_nonce_in_state'))
      .subscribe(() => {
        console.log('invalid_nonce_in_state');
        this.oauthService.initImplicitFlow();
      });

    console.log("end of constructor");
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private navigateToLoginPage() {
    console.log("navigate to login page");
    this.oauthService.initLoginFlow();
  }

  public runInitialSequence2() {
    return this.oauthService.initCodeFlow();
  }

  public runInitialLoginSequence(): Promise<void> {
    console.log(`Env : ${environment.envName}`);
    console.log(`Issuer: ${environment.issuer}`);
    console.log(`Client Id: ${environment.clientId}`);
    console.log(`Scope: ${environment.scope}`);
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is currently configured:
    console.log("starting load discover document and login")
    this.oauthService.configure(authConfig);
    return this.oauthService.loadDiscoveryDocumentAndLogin({
      disableOAuth2StateCheck: true
    })
//       .then(() => {
//         new Promise<void>(resolve => setTimeout(() => resolve(), 1000))
//       })
    .then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))
      // 1. HASH LOGIN:
      .then(() => {
        console.log("login");
        this.oauthService.tryLogin({
          onTokenReceived: (info) => {
            console.log(info);
            console.debug('state', info.state);
          }
        }).catch(err => console.log(err));
      })
      .then(async () => {
        console.log("checking access token")
        localStorage.clear();
        if (this.oauthService.hasValidAccessToken()) {
          var access_token = sessionStorage.getItem('id_token');
          localStorage.setItem('accessToken', access_token);
          let decoded = this.oauthService.getIdentityClaims();
          localStorage.setItem('currentUser', JSON.stringify(decoded));
          if (access_token != undefined) {
            localStorage.setItem('accessToken', access_token);
            localStorage.setItem('loginSuccess', 'true');
            this.appService.authToken = access_token;
          }
          else {
            console.log('Access token undefined');
          }
          if (localStorage.getItem('loginSuccess') === null) {
            this.loggedIn = 'false';
          } else {
            this.loggedIn = localStorage.getItem('loginSuccess');
            this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
            // console.log("Current User ::", this.currentUser);
            this.userName = this.currentUser['preferred_name'];
            this.appService.workdayId = this.currentUser['UserId'];
            this.appService.spinId = this.currentUser['Spin_ID'];
            this.appService.userType = this.currentUser['user_type'];
            this.appService.dealerCode = this.currentUser['dealerCode'];
            this.exp = this.currentUser['exp'];
            if (this.appService.userType.indexOf('Dealer') != -1) {
              this.appService.loginId = this.appService.spinId;
              this.appService.dc = this.appService.dealerCode;
            } else if (this.appService.userType.indexOf('Dealer') == -1) {
              this.appService.loginId = this.appService.workdayId;
            }
            // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          }

          const token = localStorage.getItem('accessToken');
          this.dashboardService.saveToken(token).subscribe((res: any) => {
            console.log('SAVED THE TOKEN')
          });
          if (!localStorage.getItem('currentUser')) { //unde // false 
            if (this.currentUser['UserId'] != 'TESTUMT1') {
              this.navigationBasedOnRole(this.currentUser, false);
              localStorage.setItem('isTestUser', 'false');
            }
            if (this.currentUser['UserId'] == 'TESTUMT1') {
              this.loginService.getTestUser().toPromise().then((response: any) => {
                this.appService.loginId = response.UserDetails.Result[0].spin_id;
                this.appService.userType = 'Dealers';
                this.appService.dealerCode = response.UserDetails.Result[0].dealer_code;
                this.appService.spinId = this.appService.loginId;
                this.appService.dc = this.appService.dealerCode;
                this.navigationBasedOnRole(this.currentUser, true, this.appService.loginId, this.appService.dealerCode);
                localStorage.setItem('isTestUser', 'true');
              }, err => {
                console.log('Error in getting the test user');
                console.log(err);
                if (err == '403') {
                  localStorage.clear();
                  window.location.href = environment.redirectUri;
                }
              })
            }
          } else {
            if (this.currentUser != undefined && this.currentUser['UserId'] != 'TESTUMT1') {
              this.navigationBasedOnRole(this.currentUser, false);
              localStorage.setItem('isTestUser', 'false');
            }
            if (this.currentUser != undefined && this.currentUser['UserId'] == 'TESTUMT1') {
              this.loginService.getTestUser().toPromise().then((response: any) => {
                this.appService.loginId = response.UserDetails.Result[0].spin_id;
                this.appService.userType = 'Dealers';
                this.appService.dealerCode = response.UserDetails.Result[0].dealer_code;
                this.appService.spinId = this.appService.loginId;
                this.appService.dc = this.appService.dealerCode;
                this.navigationBasedOnRole(this.currentUser, true, this.appService.loginId, this.appService.dealerCode);
                localStorage.setItem('isTestUser', 'true');
              }, err => {
                console.log('Error in getting the test user');
                if (err == '403') {
                  localStorage.clear();
                  window.location.href = environment.redirectUri;
                }
              })
            }
          }
          return Promise.resolve();
        }
        else {
          console.log("non valid token");
          let x = this.oauthService.getAccessToken();
          console.log("access token", x);
        }
        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent needing to redirect the user:
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              // Enable this to ALWAYS force a user to login.
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            return Promise.reject(result);
          });
      })
      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }
  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  // pilot check
  async loginFunction() {
    this.loginDetailResponse = '';
    if (environment.pilotCheck) {
      this.loginService.pilotUserCheck(this.appService.loginId).toPromise().then(async (pilotResponse: any) => {
        if (pilotResponse.CheckPilotUser.Result.length == 0) {
          console.log('NOT IN Pilot Table')
          this.invalidUser = true;
          this.router.navigate(['unauthorized']);
          this.appService.hideNavbar = true;
          this.appService.unauthorizedUser = true;
          this.appService.unauthorizedErrorMsg = `Sorry! It seems like you did not undergo the pilot training.`
          await this.getLoginDetails(false); // false = pilot table failed
        }
        else {
          console.log('IN Pilot Table')
          await this.getLoginDetails(true);
        }
      })
    }
    else {
      await this.getLoginDetails(true);
    }

    console.log('Has changed URL');
    this.fitContentDiv();
  }

  // getting details from staffmaster/accessctrl
  async getLoginDetails(isPilotTrained) {
    this.loginDetailResponse = '';
    if (this.appService.userType.indexOf('Dealer') == -1) {
      console.log('Not Dealer');
      await this.loginService.getUserLoginDetails(this.appService.loginId).toPromise()
        .then(async (response: any) => {
          this.loginDetailResponse = response.LoginDetails.Result[0];
          console.log(this.loginDetailResponse);
          if (response.LoginDetails.Result.length == 0 && isPilotTrained) {
            // Invalid -> not in Access Ctrl table but present in pilot table
            this.invalidUser = true;
            this.router.navigate(['unauthorized']);
            this.appService.hideNavbar = true;
            this.appService.unauthorizedUser = true;
            this.appService.unauthorizedErrorMsg = `Sorry! The workday id you provided is not valid.`
            this.appService.showSpinnerAuth = false;
          }
          else if (response.LoginDetails.Result.length == 0 && !isPilotTrained) {
            // Invalid -> not in Access Ctrl table and not present in pilot table
            this.invalidUser = true;
            this.router.navigate(['unauthorized']);
            this.appService.hideNavbar = true;
            this.appService.unauthorizedUser = true;
            this.appService.unauthorizedErrorMsg = `Sorry! The workday id you provided is not valid.`
            this.appService.showSpinnerAuth = false;
          } else {
            this.appService.loginId = this.loginDetailResponse.workdayId;
            this.userName = this.capitalize(this.loginDetailResponse.firstName) + ' ' + (this.loginDetailResponse.lastName[0].toUpperCase() + this.loginDetailResponse.lastName.substr(1).toLowerCase());
            this.appService.loggedInUserName = this.userName;
            console.log('User', this.userName);
            if (this.loginDetailResponse.isBilling == 'Y') {
              this.appService.role = 'Billing';
              console.log(this.appService.role);
              this.appService.billing = true;
            }
            if (this.loginDetailResponse.isASE == 'Y') {
              this.appService.role = 'ASE';
              console.log(this.appService.role);
              this.appService.ASE = true;
            }
            if (this.loginDetailResponse.isJobUser == 'Y') {
              this.appService.role = 'Lexus Job User';
              console.log(this.appService.role);
              this.appService.JobUser = true;
            }
            if (this.loginDetailResponse.isLexusCollegeAdmin == 'Y') {
              this.appService.role = 'Lexus College';
              this.appService.selectedRegion = '';
              this.appService.isAreaAdmin = false;
            }
            if (this.loginDetailResponse.isLexusUser == 'Y') {
              this.appService.role = 'Lexus Associate';
            }
            if (this.loginDetailResponse.isAreaAdmin == 'Y') {
              this.appService.role = 'Area Admin';
              this.appService.regionList = [];
              for (let i = 0; i < this.loginDetailResponse.region.length; i++) {
                if (this.loginDetailResponse.region[i] == '310') {
                  this.appService.regionList.push({ name: 'AREA WEST (310)', code: '310' });
                }
                else if (this.loginDetailResponse.region[i] == '320') {
                  this.appService.regionList.push({ name: 'CENTRAL AREA (320)', code: '320' });
                }
                else if (this.loginDetailResponse.region[i] == '330') {
                  this.appService.regionList.push({ name: 'AREA EAST (330)', code: '330' });
                }
                else if (this.loginDetailResponse.region[i] == '340') {
                  this.appService.regionList.push({ name: 'AREA SOUTH (340)', code: '340' });
                }
                else {
                  this.appService.regionList.push({ name: 'NATIONAL LEVEL', code: '000' });
                }
              }
              if (this.appService.selectedRegion == '' || this.appService.selectedRegion == null) {
                this.appService.selectedRegion = this.appService.regionList[0].code;
              }
              console.log("Regions Allocated :: ",this.appService.regionList);
            }
            if (this.loginDetailResponse.isAdmin == 'Y') {
              this.appService.role = 'Admin';
            }
            if (this.loginDetailResponse.isDebitAllowed == 'Y') {
              this.appService.isDebitAllowed = true;
            }
            
            if (this.appService.userType.indexOf('Dealer') == -1 && !localStorage.getItem('updatedLastLogin')) {
              this.appService.lastLoginTime = this.loginDetailResponse.lastLogin;
              console.log('lastLogin before update', this.appService.lastLoginTime);
              localStorage.setItem('updatedLastLogin', 'true');
              this.loginService.updateLastLoginAC(this.appService.loginId).subscribe((res: any) => {
                console.log('access control updated');
              });
            } else {
              this.appService.lastLoginTime = this.loginDetailResponse.lastLogin;
            }
            if (this.appService.lastLoginTime != null && this.appService.lastLoginTime != '' && this.appService.dealershipEnrollment == true) {
              this.appService.dealershipEnrollment = false;
            }
            this.appService.showHelp = this.loginDetailResponse.showHelp;
            this.appService.userRole = this.appService.role;
            this.routingFunction();
          }
          localStorage.setItem('role', this.appService.role);
          console.log('loginCheck', this.appService.lastLoginTime, 'helpCheck:', this.appService.showHelp);
          if (this.appService.showHelp == '1') {
            setTimeout(
              function () {
                this.startTour();
              }.bind(this),
              1000
            );
          }
        }, err => {
          this.userName = '';
          console.log('Error in Login using Workday Id : ', err);
          if (err == '403') {
            localStorage.clear();
            window.location.href = environment.redirectUri;
          }
        });
      this.appService.dealerCode = '';
    }
    else if (this.appService.userType.indexOf('Dealer') != -1) {
      this.userName = '';
      this.loginDetailResponse = '';
      this.loginService.getUserDetailUsingSpinId(this.appService.loginId, this.appService.dc).toPromise()
        .then(async (response: any) => {
          this.loginDetailResponse = response.LoginDetails.Result[0];
          if (response.LoginDetails.Result.length == 0 && isPilotTrained) {
            this.invalidUser = true;
            this.router.navigate(['unauthorized']);
            this.appService.hideNavbar = true;
            this.appService.unauthorizedUser = true;
            this.appService.unauthorizedErrorMsg = 'Sorry! The workday id you provided is not valid.'
            this.appService.showSpinnerAuth = false;
          }
          else if (response.LoginDetails.Result.length == 0 && !isPilotTrained) {
            // Invalid -> not in SM table and not present in pilot table
            this.invalidUser = true;
            this.router.navigate(['unauthorized']);
            this.appService.hideNavbar = true;
            this.appService.unauthorizedUser = true;
            this.appService.unauthorizedErrorMsg = `Sorry! The workday id you provided is not valid.`
            this.appService.showSpinnerAuth = false;
          } else {
            this.appService.loggedInPrincipalEmail = this.loginDetailResponse.userEmail;
            this.appService.loggedInPrincipalFullName = this.loginDetailResponse.firstName + ' ' + this.loginDetailResponse.lastName;
            this.appService.loggedInPrincipalspinId = this.loginDetailResponse.spinId;
            this.appService.userEmail = this.loginDetailResponse.userEmail;
            this.userName = this.loginDetailResponse.firstName + ' ' + this.loginDetailResponse.lastName;
            this.appService.loggedInUserName = this.loginDetailResponse.firstName + ' ' + this.loginDetailResponse.lastName;
            //this.appService.dealerCode = this.loginDetailResponse.dealerCode;
            this.appService.dealerName = this.loginDetailResponse.dealerName;
            // debugger;
            if (isPilotTrained) {
              if (this.loginDetailResponse.isPc.toUpperCase() == 'TRUE' || this.loginDetailResponse.jobDescription.toUpperCase() == 'DEALER PRINCIPAL'
                || this.loginDetailResponse.jobDescription.toUpperCase() == 'GENERAL MANAGER' || this.loginDetailResponse.jobDescription.toUpperCase() == 'CONTROLLER') {
                if (this.loginDetailResponse.jobDescription == 'DEALER PRINCIPAL') {
                  console.log('Principal');
                  this.appService.isPrincipal = true;
                } else {
                  console.log('Not Principal');
                  this.appService.isPrincipal = false;
                }
                this.appService.lastLoginTime = this.loginDetailResponse.lastLogin;
                if (this.appService.userType.indexOf('Dealer') != -1 && !localStorage.getItem('updatedLastLogin')) {
                  localStorage.setItem('updatedLastLogin', 'true');
                  this.loginService.updateLastLoginSM(this.appService.loginId).subscribe((res: any) => {
                    console.log('staff master updated');
                  });
                }

                this.appService.showHelp = this.loginDetailResponse.showHelp;
                // console.log('login details', this.loginDetailResponse);
                // console.log('lastloginTime', this.lastLoginTime, 'showHelp:', this.appService.showHelp);
                this.appService.loggedInDetails = this.loginDetailResponse;

                if (this.appService.lastLoginTime != null && this.appService.lastLoginTime != '' && this.appService.dealershipEnrollment == true) {
                  this.appService.dealershipEnrollment = false;
                }
                this.invalidUser = false;
                this.appService.hideNavbar = false;
                this.appService.unauthorizedUser = false;
              } else {
                this.invalidUser = true;
                this.router.navigate(['unauthorized']);
                this.appService.hideNavbar = true;
                this.appService.unauthorizedUser = true;
                this.appService.unauthorizedErrorMsg = `Sorry! Your role '${this.appService.role}' is not authorized to view the application.`
              }
            }
            this.appService.role = this.capitalize(this.loginDetailResponse.jobDescription);
          }
          localStorage.setItem('invalidUser', this.invalidUser + '')
          localStorage.setItem('role', this.appService.role);
          this.routingFunction();
          setTimeout(
            function () {
              if (this.appService.showHelp == '1' && !this.appService.dealershipEnrollment) {
                this.startTour();
              }
            }.bind(this),
            2500
          );
          // this.optionLoading = false;
        }, err => {
          // this.error = true;
          console.log('Error in Login using SpinId : ', err);
          if (err == '403') {
            localStorage.clear();
            window.location.href = environment.redirectUri;
          }
        });
    }
  }

  routingFunction() {
    const shouldroute = localStorage.getItem('nowLoggedIn');
    const savedRoute = localStorage.getItem('currentPage');
    if (!shouldroute) {
      if (this.appService.role == 'Lexus College') {
        this.appService.setLexusCollegeAdminUserFromLogin();
      }
      if (this.appService.role == 'Area Admin') {
        this.appService.setAreaAdminUserFromLogin();
      }
      if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || this.appService.role == 'Area Admin') {
        this.appService.corpUser = true;
        this.appService.adminAccount = false;
        if (savedRoute) {
          this.router.navigate([savedRoute]);
        }
        else {
          this.router.navigate(['/corpDashboard']);
        }
      } else if (this.appService.role == 'ASE') {
        this.appService.ASE = true;
        if (savedRoute) {
          this.router.navigate([savedRoute]);
        }
        else {
          this.router.navigate(['/ase-lexus']);
        }
      } else if (this.appService.role == 'Lexus Job User') {
        this.appService.JobUser = true;
        if (savedRoute) {
          this.router.navigate([savedRoute]);
        }
        else {
          this.router.navigate(['/lexus-job']);
        }
      } else if (this.appService.role == 'Dealer Principal' || this.appService.LoggedInUser.PcUser == true || this.appService.role.toUpperCase() == 'GENERAL MANAGER' || this.appService.role.toUpperCase() == 'CONTROLLER') {
        this.appService.corpUser = false;
        this.appService.adminAccount = false;
        this.appService.dc = this.appService.dealerCode;
        if (savedRoute) {
          this.router.navigate([savedRoute]);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else if (this.appService.role == 'Admin') {
        this.appService.adminAccount = true;
        if (savedRoute) {
          this.router.navigate([savedRoute]);
        }
        else {
          this.router.navigate(['/adminReport']);
        }
      } else if (this.appService.role == 'Billing') {
        this.appService.billing = true;
        if (savedRoute) {
          this.router.navigate([savedRoute]);
        }
        else {
          this.router.navigate(['/billing']);
        }
      }
      if (this.appService.LoggedInUser.isPrincipal == true) {
        this.appService.isPrincipal = true;
      }
    }
    else if (shouldroute) {
      if (this.appService.role == 'Lexus College') {
        this.appService.setLexusCollegeAdminUserFromLogin();
      }
      if (this.appService.role == 'Area Admin') {
        this.appService.setAreaAdminUserFromLogin();
      }
      if (this.appService.role == 'Lexus College' || this.appService.role == 'Lexus Associate' || this.appService.role == 'Area Admin') {
        this.appService.corpUser = true;
        this.appService.adminAccount = false;
      } else if (this.appService.role == 'Dealer Principal' || this.appService.LoggedInUser.PcUser == true || this.appService.role.toUpperCase() == 'GENERAL MANAGER' || this.appService.role.toUpperCase() == 'CONTROLLER') {
        this.appService.corpUser = false;
        this.appService.adminAccount = false;
        this.appService.dc = this.appService.dealerCode;
      } else if (this.appService.role == 'Admin') {
        this.appService.adminAccount = true;
      }
      if (this.appService.LoggedInUser.isPrincipal == true) {
        this.appService.isPrincipal = true;
      }
    }
    localStorage.setItem('nowLoggedIn', 'true');
    this.appService.showSpinnerAuth = false;
    this.validateRefreshToken();
    // this.getUnreadNotifications(); (Need to check)
    // setInterval(
    //   function () {
    //     this.getUnreadNotifications();
    //   }.bind(this),
    //   300000
    // )
    this.getCategory();
  }

  async navigationBasedOnRole(result, isTestUser, optionalSpinid = '', optionalDC = '') {
    // console.log(result);
    this.appService.mainContent = false;
    this.appService.LoggedInUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userName = this.appService.LoggedInUser['preferred_name'];
    this.exp = this.appService.LoggedInUser['exp'];
    if (!isTestUser) {
      this.appService.workdayId = this.appService.LoggedInUser['UserId'];
      this.appService.userType = this.appService.LoggedInUser['user_type'];
      this.appService.spinId = this.appService.LoggedInUser['Spin_ID'];
      this.appService.userType = this.appService.LoggedInUser['user_type'];
      this.appService.dealerCode = this.appService.LoggedInUser['dealerCode'];
    }
    else {
      this.appService.loginId = optionalSpinid;
      this.appService.userType = 'Dealers';
      this.appService.dealerCode = optionalDC;
      this.appService.spinId = this.appService.loginId;
      this.appService.dc = this.appService.dealerCode;
    }
    if (this.appService.userType.indexOf('Dealer') != -1) {
      this.appService.loginId = this.appService.spinId;
      this.appService.dc = this.appService.dealerCode;
    } else if (this.appService.userType.indexOf('Dealer') == -1) {
      this.appService.loginId = this.appService.workdayId;
    }
    this.currentUser = result;
    // --------------------
    await this.loginFunction();
    // --------------------
  }

  async validateRefreshToken() {
    let currentUrl = this.router.url;
    if (currentUrl.indexOf('corpDashboard') != -1) {
      currentUrl = '/corpDashboard';
    } else if (currentUrl.indexOf('corpHoldResume') != -1) {
      currentUrl = '/corpHoldResume';
    } else if (currentUrl.indexOf('holdResumePayments') != -1) {
      currentUrl = '/holdResumePayments'
    } else if (currentUrl.indexOf('switchAccount') != -1) {
      currentUrl = '/switchAccount'
    }
    this._location.go(currentUrl);
    localStorage.setItem('currentPage', currentUrl);
  }

  reset() {
    this.idle.watch();
    this.appService.idleState = 'Started';
    this.appService.timedOut = false;
    this.appService.idleStart = false;
  }

  closeModalIdle() {
    $("#idleModal").removeClass('display_block');
    $("#idleModal").addClass('hide_block');
    $("#idleModal").show("slow");
  }

  getCategory() {
    // for separating different categories for notification.
    this.appService.category3 = [];
    this.appService.category4 = [];
    this.appService.category5 = [];
    this.appService.isCategoryFunctionCalled = true;
    this.loginService.getUserDetailList().subscribe((result: any) => {
      this.appService.accessControlDetails = result.UserDetails.Result;
      for (let i = 0; i < this.appService.accessControlDetails.length; i++) {
        if (this.appService.accessControlDetails[i].isAdmin == "Y") {
          this.appService.category5.push(this.appService.accessControlDetails[i]);
        }
        if (this.appService.accessControlDetails[i].isLexusCollegeAdmin == "Y") {
          this.appService.category4.push(this.appService.accessControlDetails[i]);
        }
        if (this.appService.accessControlDetails[i].isLexusUser == "Y") {
          this.appService.category3.push(this.appService.accessControlDetails[i]);
        }
        if (this.appService.accessControlDetails[i].isAreaAdmin == "Y") {
          this.appService.category4.push(this.appService.accessControlDetails[i]);
        }
      }
    });
  }

  startTour() {
    // update last login time here only if dealer is enrolled. if dealer is un enrolled and clicks on help, should not update.
    if (this.appService.userType.indexOf('Dealer') != -1) {
      if (this.appService.showHelp == '1' && !this.appService.dealershipEnrollment) {
        this.loginService.updateShowFeatureWTSM(this.appService.loginId, '0').subscribe((res: any) => {
          console.log('updated show feature to false staff master')
        })
      }
    }
    else if (this.appService.userType.indexOf('Dealer') == -1) {
      if (this.appService.showHelp == '1') {
        this.loginService.updateShowFeatureWTAC(this.appService.loginId, '0').subscribe((res: any) => {
          console.log('updated show feature to false access ctrl')
        })
      }
    }

    $('html,body').animate({ scrollTop: 0 }, "smooth");
    if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpDashboard') != -1) {
      this.joyrideService.startTour(
        {
          steps: ['title', 'firstStep', 'secondStep', 'thirdStep', 'fourthStep', 'fifthStep', 'sixthStep@corpDashboard',
            'seventhStep@corpDashboard', 'eighthStep@corpDashboard', 'ninethStep@corpDashboard', 'tenthStep@corpDashboard', 'thirteenthStep']
        }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('dashboard') != -1 && !this.appService.dealershipEnrollment) {
      this.joyrideService.startTour(
        {
          steps: ['title', 'secondStep', 'thirdStep', 'fourthStep', 'fifthStep', 'sixthStep',
            'seventhStep@dashboard', 'eighthStep@dashboard', 'ninethStep@dashboard', 'twelvethStep@dashboard', 'tenthStep@dashboard',
            'eleventhStep@dashboard', 'thirteenthStep']
        }
      )
    }

    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('dashboard') != -1 && this.appService.dealershipEnrollment) {
      this.joyrideService.startTour(
        {
          steps: ['dealerUnenrolledStep']
        }
      )
    }

    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('holdResumePayments') != -1 && this.appService.pcHoldPage == '' && !this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['firstHoldStep@holdResumePayments', 'secondHoldStep@holdResumePayments', 'thirdHoldStep@holdResumePayments'] }
      )
    }
    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpHoldResume') != -1 && this.appService.corpHoldPage == '' && !this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['firstHoldStep@corpHoldResume', 'secondHoldStep@corpHoldResume', 'thirdHoldStep@corpHoldResume'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('holdResumePayments') != -1 && this.appService.pcHoldPage != '' && !this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['fourthHoldStep@holdResumePayments', 'fifthHoldStep@holdResumePayments'] }
      )
    }
    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpHoldResume') != -1 && this.appService.corpHoldPage != '' && !this.appService.isCorpHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['fourthHoldStep@corpHoldResume', 'fifthHoldStep@corpHoldResume'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('holdResumePayments') != -1 && this.appService.pcHoldPage != '' && this.appService.isPcHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['sixthStep@holdResumePayments', 'seventhStep@holdResumePayments', 'eighthStep@holdResumePayments'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('corpHoldResume') != -1 && this.appService.corpHoldPage != '' && this.appService.isCorpHoldCreate) {
      this.joyrideService.startTour(
        { steps: ['sixthStep@corpHoldResume', 'seventhStep@corpHoldResume', 'eighthStep@corpHoldResume'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && this.appService.enrollmentCurrentStatus == undefined) {
      this.joyrideService.startTour(
        { steps: ['firstEnrollStep@enroll'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && this.appService.enrollmentCurrentStatus == 'REVIEW INFO') {
      this.appService.statusDetails = 'Review the details of the associate before creating an enrollment.';
      this.joyrideService.startTour(
        { steps: ['secondEnrollStep@enroll', 'thirdEnrollStep@enroll'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && (this.appService.enrollmentCurrentStatus == 'PENDING WITH ASSOCIATE' || this.appService.enrollmentCurrentStatus == 'PENDING WITH PRINCIPAL' || this.appService.enrollmentCurrentStatus == 'ENROLLED')) {
      if (this.appService.enrollmentCurrentStatus == 'ENROLLED') {
        this.joyrideService.startTour(
          { steps: ['secondEnrollStep@enroll', 'fourthEnrollStep@enroll', 'seventhStep@enroll'] }
        )
      }
      else {
        this.joyrideService.startTour(
          { steps: ['secondEnrollStep@enroll', 'fourthEnrollStep@enroll', 'fifthEnrollStep@enroll'] }
        )
      }
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('enroll') != -1 && this.appService.enrollmentCurrentStatus == 'DRAFT') {
      this.appService.statusDetails = 'Draft';
      this.joyrideService.startTour(
        { steps: ['secondEnrollStep@enroll', 'fourthEnrollStep@enroll', 'fifthEnrollStep@enroll', 'sixthStep@enroll'] }
      )
    }
    else if (!this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('paymentHistory') != -1) {
      this.joyrideService.startTour(
        { steps: ['stepOne@paymentHistory'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('reviewPayment') != -1) {
      this.joyrideService.startTour(
        { steps: ['firstReviewStep@reviewPayment', 'secondReviewStep@reviewPayment', 'thirdReviewStep@reviewPayment', 'fourthReviewStep@reviewPayment', 'fifthReviewStep@reviewPayment'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('sendNotification') != -1) {
      this.joyrideService.startTour(
        { steps: ['sendNotifOne@sendNotification', 'sendNotifTwo@sendNotification'] }
      )
    }

    else if (this.appService.corpUser && !this.appService.adminAccount && this.router.url.indexOf('reports') != -1) {
      this.joyrideService.startTour(
        { steps: ['filterData@reports', 'download@reports'] }
      )
    }

  }

  fitContentDiv() {
    if (window.innerWidth >= 768) {
      this.appService.hideSideFull = true;
      if (!this.appService.corpUser && !this.appService.adminAccount) {
        //this.side_full != undefined ? this.side_full.nativeElement.style.minHeight = (window.innerHeight - 580) + 'px' : '';
        if (this.side_full != undefined) {
          this.side_full.nativeElement.style.minHeight = (window.innerHeight - 580) + 'px';
        }
      } else if (this.appService.corpUser && !this.appService.adminAccount) {
        //this.Corp_sidefull != undefined ? this.Corp_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.Corp_sidefull != undefined) {
          this.Corp_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      } else if (this.appService.adminAccount) {
        //this.Admin_sidefull != undefined ? this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px' : '';
        if (this.Admin_sidefull != undefined) {
          this.Admin_sidefull.nativeElement.style.minHeight = (window.innerHeight - 680) + 'px';
        }
      }
    } else {
      this.appService.hideSideFull = false;
    }
  }

  capitalize(s) {
    return s
      .toLowerCase()
      .split(' ')
      .map(function (word) {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(' ');
  }

  public logout() {
    this.oauthService.logOut();
  }

  public refresh() {
    this.oauthService.silentRefresh();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
